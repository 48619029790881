import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import CustomText from '../../CustomComponents/CustomText';
import DeleteIcon from '@mui/icons-material/Delete';
import { FiChevronRight } from "react-icons/fi";
import { connect } from 'react-redux';
import { iconSize, labelColor, textFontSize } from '../../../styles/styles';
import IconLetter from '../../CustomComponents/IconLetter';
import { IconButton, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

class UsersItem extends React.Component {
    render() {
        const { user, displayDetailForUser, openDeleteDialog, editUser } = this.props;
        return (
            <View key={this.props.id} style={{ paddingHorizontal: 15, paddingVertical: 3, height: 70 }}>
                <Paper elevation={3} style={{ width: '100%', height: '100%', borderRadius: 10 }}>
                    <TouchableOpacity
                        style={{ flex: 1, width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', paddingHorizontal: 12, paddingVertical: 12, flexDirection: 'row', }}
                        onPress={() => displayDetailForUser(user.id, user.name)}>

                        <View style={{ flex: 5, alignItems: 'center', flexDirection: 'row' }}>
                            <View style={{ flex: 1, }}>
                                <IconLetter
                                    letters={user.identifiant.substring(0, 1)}
                                />
                            </View>
                            <View style={{ flex: 4, paddingLeft: 7 }}>
                                <CustomText textType='bold' numberOfLines={1}>{user.name ? user.name : user.identifiant}</CustomText>
                                <CustomText style={{ color: labelColor, fontSize: textFontSize - 1 }} numberOfLines={1}>{user.role}</CustomText>
                            </View>
                        </View>
                        <View style={{ flex: 1, alignItems: 'flex-end', paddingLeft: 30 }}>
                            {/* {this.props.currentUser.identifiant === user.identifiant ? <CustomText textType='bold'>Vous</CustomText> : <></>} */}
                            {this.props.currentUser.identifiant === user.identifiant ? <></> :
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <IconButton
                                        color='primary'
                                        title="Modifier"
                                        onClick={(event) => {
                                            editUser(user)
                                            event.stopPropagation()
                                        }}
                                        size='large'
                                    >
                                        <EditIcon
                                            fontSize={'medium'}
                                        />
                                    </IconButton>
                                    <IconButton
                                        color='primary'
                                        title="Supprimer"
                                        onClick={(event) => {
                                            openDeleteDialog(user)
                                            event.stopPropagation()
                                        }}
                                        size='large'
                                    >
                                        <DeleteIcon
                                            fontSize={'medium'}
                                        />
                                    </IconButton>
                                </div>
                            }
                        </View>
                        <View style={{ flex: 0.7, alignItems: 'flex-end' }}>
                            <FiChevronRight style={{ fontSize: iconSize, color: 'grey' }} />
                        </View>
                    </TouchableOpacity>
                </Paper>
            </View>

        );
    }
}


const mapStateToProps = state => {
    return {
        data: state.setData.data,
        currentUser: state.setData['currentUser'],
        users: state.setData['users'],

    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch: action => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersItem);


