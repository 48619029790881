import firebase from "firebase/app";
import "firebase/messaging";
import { promiseTimeoutNoError } from "../utilities/utils";
import "firebase/functions";
import { TIMEOUT } from "../api/constants";

const firebaseConfig = {
  apiKey: "AIzaSyC_1V9r839fSvzWI3vw3sUrfADqp89LmMY",
  authDomain: "zinc-quest-363211.firebaseapp.com",
  databaseURL:
    "https://zinc-quest-363211-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "zinc-quest-363211",
  storageBucket: "zinc-quest-363211.appspot.com",
  messagingSenderId: "235029042154",
  appId: "1:235029042154:web:2cea25fd85cc28d1ee9893",
};

export const app = firebase.initializeApp(firebaseConfig);

//firebase.functions().useFunctionsEmulator("http://localhost:5001")

const publicKey =
  "BCnG1i1G_7v8zW_5NQpaGquaaCB3ZEBb74yj11wW__bcM_PvFxib4YDoc2-InaK0NPbPw5hTC7ZWyOyCqoheIgM";

export function getToken(props) {
  return promiseTimeoutNoError(
    TIMEOUT,
    "getToken",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }

      if (
        firebase.messaging.isSupported() /*&& Notification.permission === "granted"*/
      ) {
        const messaging = firebase.messaging(app);
        if (Notification.permission !== "granted") {
          Notification.requestPermission()
            .then((res) => {
              if (res === "granted") {
                messaging
                  .getToken({ vapidKey: publicKey })
                  .then((token) => {
                    resolve(token);
                  })
                  .catch((error) => {
                    console.warn(error);
                    resolve("");
                  });
              } else {
                resolve("");
              }
            })
            .catch((err) => {
              console.warn(err);

              resolve("");
            });
        } else {
          messaging
            .getToken({ vapidKey: publicKey })
            .then((token) => {
              resolve(token);
            })
            .catch((error) => {
              console.warn(error);
              resolve("");
            });
        }
      } else {
        resolve("");
      }
    })
  );
}

export const db = app.database();
