import React from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import {
  displayLoading,
  getAllRelanceByResponsable,
  handleBack,
} from '../../../utilities/utils';
import CustomList from '../../CustomComponents/CustomList';
import { globalStyles, labelColor, textFontSize } from '../../../styles/styles';
import Header from '../Headers/Header';
import CustomText from '../../CustomComponents/CustomText';
import CustomItemList from '../../CustomComponents/CustomItemList';

class ResponsableSubList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isLoading2: false,
      subList: [],
      listHeight: 0,
      listWidth: 0,
      reload: 0
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.navigation.addListener('blur', () => {
      window.removeEventListener('popstate', this.listener)

    })

    this.unsubscribe2 = this.props.navigation.addListener('focus', () => {
      setTimeout(() => {
        window.addEventListener('popstate', this.listener = (event) => handleBack(event, this));

      }, 1)

    })

    this._loadResponsbleSubList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this._loadResponsbleSubList();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.listener)

    if (this.unsubscribe)
      this.unsubscribe()
    if (this.unsubscribe2)
      this.unsubscribe2()
  }


  _loadResponsbleSubList() {
    this.setState({ isLoading: true })
    let relanceList = getAllRelanceByResponsable(
      this.props.route.params.userId,
      this.props.data
    );

    let subList = [];
    if (relanceList[0].length > 0) {
      subList.push({
        text: 'Relances en cours',
        length: relanceList[0].length,
        id: 0,
      });
    }
    if (relanceList[1].length > 0) {
      subList.push({
        text: 'Relances en retard',
        length: relanceList[1].length,
        id: 1,
      });
    }
    if (relanceList[2].length > 0) {
      subList.push({
        text: 'Sans relance',
        length: relanceList[2].length,
        id: 2,
      });
    }

    this.setState({ subList: subList, reload: new Date().getTime(), isLoading: false });
  }

  _renderItem = (item) => {
    return (
      <CustomItemList
        content={<View>
          <CustomText textType='bold' numberOfLines={1}>{item.text}</CustomText>
          <CustomText numberOfLines={1} style={{ color: labelColor, fontSize: textFontSize - 1 }}>{item.length === 1 ? '1 contact' : item.length + ' contacts'}</CustomText>
        </View>}
        onPress={() => this._displayDetailForResponsableSubList(item)}
        equalTest={item.text + item.length}
      />
    );
  };

  _displayDetailForResponsableSubList = relance => {
    this.props.navigation.navigate('ResponsableListByResponsable', {
      userId: this.props.route.params.userId,
      userName: this.props.route?.params?.userName ? this.props.route?.params?.userName : null,
      relance: relance
    });
  };



  render() {
    return (
      <View style={globalStyles.main_container}>
        <Header
          navigation={this.props.navigation}
        >
          {this.props.route.params.userName ? this.props.route.params.userName : this.props.users.find(user => user.id === this.props.route.params.userId).identifiant}
        </Header>

        {this.state.isLoading ? displayLoading(this.state.isLoading) :
          <View style={[{ paddingTop: 12 }, globalStyles.list_container]}>
            {this.state.subList.length === 0 ?
              <View
                style={{
                  flex: 1,
                  alignItems: 'center',
                  paddingHorizontal: '5%',
                  paddingTop: '5%'
                }}
              >
                <CustomText>Aucune relance pour cet utilisateur.</CustomText>
              </View>
              :
              <View key={this.state.reload} onLayout={(event) => this.setState({ listHeight: event.nativeEvent.layout.height, listWidth: event.nativeEvent.layout.width })} style={{ flex: 1 }}>
                <CustomList
                  listWidth={this.state.listWidth}
                  listHeight={this.state.listHeight}
                  datas={this.state.subList}
                  renderItem={this._renderItem}
                />
              </View>
            }
          </View>}

      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.setData.data,
    currentUser: state.setData['currentUser'],
    users: state.setData['users'],
    isConnectedToNetwork: state.setConnection.isConnectedToNetwork,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: action => {
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  ResponsableSubList
);
