import React from "react";
import {
  displayLoading,
  renderNoNetworkNotification,
  signOutFromSignInLoadingScreen,
} from "../../../utilities/utils";
import { connect } from "react-redux";
import { View } from "react-native";
import Header from "../Headers/Header";
import Cookies from "js-cookie";
import firebase from "firebase/app";
import "firebase/functions";

class SignInLoadingScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this._bootstrapAsync();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isConnectedToNetwork && this.props.isConnectedToNetwork) {
      this._bootstrapAsync();
    }
  }

  _bootstrapAsync() {
    if (this.props?.route?.params?.force) {
      this.props.navigation.replace("SignIn", {
        force: this.props?.route?.params?.force,
      });
    } else {
      if (this.props.isConnectedToNetwork) {
        firebase
          .functions()
          .httpsCallable("verifyJWT")({ token: Cookies.get("refresh_token") })
          .then((res) => {
            const response = res.data;
            if (response.code === "success") {
              this.props.navigation.replace("Contacts");
            } else {
              signOutFromSignInLoadingScreen(
                this.props,
                this.props.route?.params?.firstTime
              );
            }
          })
          .catch((err) => {
            console.warn(err);
            signOutFromSignInLoadingScreen(
              this.props,
              this.props.route?.params?.firstTime
            );
          });
      }
    }
  }

  render() {
    return (
      <View style={{ flex: 1 }}>
        <Header preventColorChange />
        <View style={{ flex: 1 }}>
          {this.props.isConnectedToNetwork
            ? displayLoading(true, "Chargement de vos données...")
            : renderNoNetworkNotification()}
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.setData.currentUser,
    language: state.setLanguage.language,
    isConnectedToNetwork: state.setConnection.isConnectedToNetwork,
    accountAccessKey: state.setAccount.accountAccessKey,
    users: state.setData.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInLoadingScreen);
