import { createStore } from "redux";
import { persistCombineReducers } from "redux-persist";
import AsyncStorage from "@react-native-community/async-storage";
import setConnection from "../reducers/networkReducer";
import setData from "../reducers/dataReducer";
import setLanguage from "../reducers/languageReducer";
import setVersionGuard from "../reducers/versionGuardReducer";
import setSnackbar from "../reducers/snackbarReducer";
import setAccount from "../reducers/accountReducer";
import localforage from "localforage";

const rootPersistConfig = {
  key: "root",
  storage: localforage,
  //blacklist: ['setConnection', 'setLanguage', 'setVersionGuard', 'setSnackbar']
};

export default createStore(
  persistCombineReducers(rootPersistConfig, {
    setConnection,
    setData,
    setLanguage,
    setVersionGuard,
    setSnackbar,
    setAccount,
  })
);
