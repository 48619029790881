import React from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import { IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { appBackgroundColor, labelFontSize, textFontSize } from '../../styles/styles';

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
        }
    }

    render() {
        return (
            <View
                style={{
                    backgroundColor: appBackgroundColor,
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderRadius: 5,
                    height: 40,
                    marginVertical: 10,
                    borderWidth: 1,
                    borderColor: '#C4C4C4'
                }}>
                <View style={{ flex: 1, alignItems: 'center' }}>
                    <SearchIcon
                        fontSize={'medium'}
                        style={{ color: 'grey' }}
                    />
                </View>
                <View style={{ flex: 6 }}>

                    <TextField
                        variant="standard"
                        InputLabelProps={{ style: { color: 'grey', fontFamily: 'Quicksand-Bold', fontSize: labelFontSize } }}
                        inputProps={{ enterKeyHint: 'search', style: { color: 'black', fontFamily: 'Quicksand-Regular', fontSize: textFontSize } }}
                        InputProps={{
                            disableUnderline: true,
                        }}
                        placeholder='Recherche...'
                        onChange={event => this.props.searchOnChangeText(event.target.value)}
                        value={this.props.searchValue}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                document.activeElement.blur();
                            }
                        }}
                    />

                </View>
                <View style={{ flex: 1, alignItems: 'center' }}>
                    <IconButton
                        style={{ color: 'grey' }}
                        title="Effacer"
                        onClick={this.props.searchOnClick}
                        size='small'
                    >
                        <CloseIcon
                            fontSize={'small'}
                        />
                    </IconButton>
                </View>
            </View>
        );
    }
}


const mapStateToProps = state => {
    return {
        data: state.setData.data,
        lists: state.setData.lists,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch: action => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);

