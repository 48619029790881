import React from "react";
import { connect } from "react-redux";
import ImportContact from "../Contact/ImportContact";

class ImportContactSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <ImportContact
        ws2={this.props.route.params.ws2}
        excelDatas={this.props.route.params.excelDatas}
        enTete={this.props.route.params.enTete}
        navigation={this.props.navigation}
        headerTitle="Importer mes contacts"
      ></ImportContact>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.setData.data,
    users: state.setData["users"],
    currentUser: state.setData["currentUser"],
    isConnectedToNetwork: state.setConnection.isConnectedToNetwork,
    settings: state.setAccount.settings,
    lists: state.setData.lists,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportContactSettings);
