import React from "react";
import { ScrollView, View } from "react-native";
import CustomText from "../../CustomComponents/CustomText";
import CustomButton from "../../CustomComponents/CustomButton";
import CustomTitle from "../../CustomComponents/CustomTitle";
import Header from "../Headers/Header";
import { connect } from "react-redux";
import {
  displayLoading,
  getCurrentToken,
  handleBack,
  hideKeyboard,
  setCurrentToken,
  setSnackbar,
  superSignOut,
} from "../../../utilities/utils";
import FloatingLabelInput from "../../CustomComponents/FloatingLabelInput";
import {
  deleteAccountDatabase,
  loadUserByIdentifiantFromDatabase,
  updateUserToDatabase,
} from "../../../db/db";
import { IconButton } from "@mui/material";
import DialogAlert from "../../CustomComponents/DialogAlert";
import LogoutIcon from "@mui/icons-material/Logout";
import NoNetworkNotification from "../../CustomComponents/NoNetworkNotification";
import { getToken } from "../../../db/dbInit";
import { globalStyles } from "../../../styles/styles";
import { DEBUG } from "../../../api/constants";
import firebase from "firebase/app";
import "firebase/functions";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import { Notifier } from "@airbrake/browser";

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoading2: false,
      password: "",
      identifiant: "",
      identifiantInfoLabel: "",
      passwordInfoLabel: "",
      secureTextEntry: true,
      isDialogVisible: false,
      canCloseDialogVisible: true,
      isDialogVisible2: false,
      canCloseDialogVisible2: true,
    };

    this.airbrake = new Notifier({
      projectId: 516005,
      projectKey: "0c44a5ed25cd023eeeece99d91a28f19",
    });
  }

  closeDialog = () => {
    this.setState({ isDialogVisible: false });
  };

  closeDialog2 = () => {
    this.setState({ isDialogVisible2: false });
  };

  componentDidMount() {
    this.unsubscribe = this.props.navigation.addListener("blur", () => {
      window.removeEventListener("popstate", this.listener);
    });

    this.unsubscribe2 = this.props.navigation.addListener("focus", () => {
      setTimeout(() => {
        window.addEventListener(
          "popstate",
          (this.listener = (event) => handleBack(event, this))
        );
      }, 1);
    });

    if (this.props.deleted) {
      superSignOut(this.props);
      deleteAccountDatabase(this.props)
        .then(() => {})
        .catch((error) => {
          if (DEBUG) console.warn(error);
        });
      return;
    }

    if (!this.props.isVersionValid) {
      superSignOut(this.props);
    }

    if (this.props?.route?.params?.force) {
      if (this.props.route.params.force === "deleted") {
        this.setState({ isDialogVisible2: true });
      } else {
        this.setState({ isDialogVisible: true });
      }
    }

    this.setState({ isLoading: false });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.deleted && this.props.deleted) {
      superSignOut(this.props);
      deleteAccountDatabase(this.props)
        .then(() => {})
        .catch((error) => {
          if (DEBUG) console.warn(error);
        });
      return;
    }

    if (prevProps.isVersionValid && !this.props.isVersionValid) {
      superSignOut(this.props);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.listener);

    if (this.unsubscribe) this.unsubscribe();
    if (this.unsubscribe2) this.unsubscribe2();
  }

  _validSignInForm() {
    if (
      this.state.identifiant.length === 0 ||
      this.state.password.length === 0
    ) {
      if (this.state.identifiant.length === 0) {
        this.setState({
          identifiantInfoLabel: "Paramètre obligatoire",
        });
      }

      if (this.state.password.length === 0) {
        this.setState({
          passwordInfoLabel: "Paramètre obligatoire",
        });
      }

      return false;
    }

    if (!this.props.isConnectedToNetwork) {
      setSnackbar("error", "Erreur réseau", this.props);
      return false;
    }

    return true;
  }

  _loadingOff = () => {
    this.setState({
      isLoading2: false,
    });
  };

  _signIn() {
    hideKeyboard();

    if (this.state.isLoading2) {
      return;
    }

    this.setState({
      isLoading2: true,
      identifiantInfoLabel: "",
      passwordInfoLabel: "",
    });

    setTimeout(() => {
      if (!this._validSignInForm()) {
        this.setState({ isLoading2: false });
        return;
      }

      loadUserByIdentifiantFromDatabase(
        this.state.identifiant.toString().trim().toLowerCase(),
        this.props
      )
        .then((user) => {
          if (!user || user.deleted) {
            setSnackbar(
              "error",
              "Identifiant ou mot de passe incorrect",
              this.props
            );

            this.setState({ isLoading2: false });
            return;
          }

          firebase
            .functions()
            .httpsCallable("generateJWT")({
              identifiant: this.state.identifiant,
              password: this.state.password,
              role: user.role,
              accountAccessKey: this.props.accountAccessKey,
            })
            .then((res) => {
              const response = res.data;
              if (response.code === "success") {
                const exp = jwtDecode(response.refreshToken);
                Cookies.set("refresh_token", response.refreshToken, {
                  expires: new Date(exp.exp * 1000),
                  path: "/",
                  secure: true,
                });
                setCurrentToken(response.accessToken);

                getToken(this.props)
                  .then((token) => {
                    user.deviceId = new Date().getTime();
                    user.notificationToken = token;
                    updateUserToDatabase(user, this.props)
                      .then(() => {
                        const action = {
                          type: "SET_CURRENT_USER",
                          value: user,
                        };
                        this.props.dispatch(action);
                        this.props.route.params.firstTime
                          ? this.props.navigation.replace("Personnalisation")
                          : this.props.navigation.replace("Contacts");
                      })
                      .catch((error) => {
                        setSnackbar(
                          "error",
                          error === "No network"
                            ? "Erreur réseau"
                            : "Une erreur est survenue. Veuillez réessayer plus tard",
                          this.props
                        );
                        if (error !== "No network") {
                          this.airbrake.notify({
                            error: error,
                          });
                        }
                        if (DEBUG) console.warn(error);
                        setTimeout(() => {
                          this.setState({ isLoading2: false });
                        }, 100);
                      });
                  })
                  .catch((err) => {
                    setSnackbar(
                      "error",
                      err === "No network"
                        ? "Erreur réseau"
                        : "Une erreur est survenue. Veuillez réessayer plus tard",
                      this.props
                    );
                    if (err !== "No network") {
                      this.airbrake.notify({
                        error: err,
                      });
                    }
                    if (DEBUG) console.warn(err);
                    setTimeout(() => {
                      this.setState({ isLoading2: false });
                    }, 100);
                  });
              } else if (response.code === "error") {
                this._loadingOff();
                setSnackbar("error", "Une erreur est survenue", this.props);
              } else {
                this._loadingOff();
                setSnackbar("warning", response.message, this.props);
              }
            })
            .catch((err) => {
              console.warn(err);
              this._loadingOff();
              setSnackbar("error", "Une erreur est survenue", this.props);
            });
        })
        .catch((error) => {
          if (error !== "deleted") {
            if (error !== "No network") {
              this.airbrake.notify({
                error: error,
              });
            }
            if (DEBUG) console.warn(error);
            setSnackbar(
              "error",
              error === "No network"
                ? "Erreur réseau"
                : "Une erreur est survenue. Veuillez réessayer plus tard",
              this.props
            );
            this.setState({ isLoading2: false });
          }
        });
    }, 1000);
  }

  _secureTextEntryOnOff() {
    this.setState({ secureTextEntry: !this.state.secureTextEntry });
  }

  render() {
    return (
      <View style={{ flex: 1 }}>
        <Header
          preventColorChange
          iconLeft={null}
          icon={
            <div>
              <IconButton
                color="secondary"
                title="Déconnexion"
                onClick={() => {
                  superSignOut(this.props);
                }}
                size="medium"
              >
                <LogoutIcon fontSize={"medium"} />
              </IconButton>
            </div>
          }
        />
        <DialogAlert
          isDialogVisible={this.state.isDialogVisible2}
          onClose={() => {
            this.closeDialog2();
          }}
          title={"Compte supprimé"}
          text={"Votre compte a été supprimé par un Administrateur."}
          onlyOkButton={true}
          cancel={() => {
            this.closeDialog2();
          }}
        />
        <DialogAlert
          isDialogVisible={this.state.isDialogVisible}
          onClose={() => {
            this.closeDialog();
          }}
          title={"Déconnexion"}
          text={"Votre compte est utilisé sur un autre appareil."}
          onlyOkButton={true}
          cancel={() => {
            this.closeDialog();
          }}
        />
        {displayLoading(this.state.isLoading2)}
        {this.state.isLoading ? (
          displayLoading(this.state.isLoading)
        ) : (
          <ScrollView
            style={globalStyles.form_container}
            contentContainerStyle={{
              paddingVertical: "5%",
              flexGrow: 1,
              opacity: this.state.isLoading2 ? 0.5 : 1,
            }}
          >
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  paddingVertical: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CustomTitle style={{ fontSize: 40, textAlign: "center" }}>
                  Bienvenue
                </CustomTitle>
              </View>
              <View
                style={{
                  paddingVertical: 10,
                  marginTop: "7%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CustomText style={{ textAlign: "center" }}>
                  Connectez-vous à l'aide de vos identifiants Tankō
                </CustomText>
              </View>
              <View
                style={{
                  paddingVertical: 10,
                  marginTop: "7%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CustomText textType="italic" style={{ textAlign: "center" }}>
                  Code d'accès du compte : {this.props.accountAccessKey}
                </CustomText>
              </View>
            </View>
            <View
              style={{
                marginVertical: "5%",
                justifyContent: "center",
              }}
            >
              <form onSubmit={(event) => event.preventDefault()}>
                <FloatingLabelInput
                  autoComplete="email"
                  autoCapitalize="none"
                  label={"Identifiant"}
                  onChange={(value) => this.setState({ identifiant: value })}
                  value={this.state.identifiant}
                  helperText={this.state.identifiantInfoLabel}
                />

                <FloatingLabelInput
                  autoComplete="current-password"
                  password
                  label={"Mot de passe"}
                  onChange={(value) => this.setState({ password: value })}
                  value={this.state.password}
                  helperText={this.state.passwordInfoLabel}
                  showPassword={!this.state.secureTextEntry}
                  type={!this.state.secureTextEntry ? "text" : "password"}
                  handleClickShowPassword={() => this._secureTextEntryOnOff()}
                  onSubmit={() => this._signIn()}
                  enterkeyhint="go"
                />
              </form>
            </View>
            <View
              style={{
                marginTop: 20,
                alignSelf: "center",
              }}
            >
              <CustomButton
                preventColorChange
                title={"Se connecter"}
                onPress={() => this._signIn()}
              />
            </View>
          </ScrollView>
        )}
        <NoNetworkNotification />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.setLanguage.language,
    users: state.setData.users,
    currentUser: state.setData.currentUser,
    isConnectedToNetwork: state.setConnection.isConnectedToNetwork,
    accountAccessKey: state.setAccount.accountAccessKey,
    settings: state.setAccount.settings,
    deleted: state.setAccount.deleted,
    isVersionValid: state.setVersionGuard.isVersionValid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
