import React from "react";
import { TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import {
  abonnements,
  displayLoading,
  getAllChampByContacts,
  getAllContactsByChamp,
  getAllResponsables,
  getAllValueFromChamp,
  getColor,
  getElementPreListLabelById,
  getList,
  getNbContactsByAbonnement,
  getNbContactsLabelByAbonnement,
  handleBack,
  setSnackbar,
  sortText,
} from "../../../utilities/utils";
import CustomText from "../../CustomComponents/CustomText";
import CustomList from "../../CustomComponents/CustomList";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CustomSelect from "../../CustomComponents/CustomSelect";
import CustomItemList from "../../CustomComponents/CustomItemList";
import {
  floatingLabelTextFontsize,
  globalStyles,
  labelColor,
  textFontSize,
} from "../../../styles/styles";
import { Checkbox, Modal, Paper } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteMultipleDataToDatabase } from "../../../db/db";
import DialogAlert from "../../CustomComponents/DialogAlert";
import Header from "../Headers/Header";
import moment from "moment";
import { DEBUG } from "../../../api/constants";
import PopUpMenu from "../Champ/PopUpMenu";
import DoneIcon from "@mui/icons-material/Done";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Search from "../../CustomComponents/Search";

class ContactList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isLoading2: false,
      isDialogVisible: false,
      canCloseDialogVisible: true,
      isDialogVisible2: false,
      canCloseDialogVisible2: true,
      isDialogVisible3: false,
      canCloseDialogVisible3: true,
      isDialogVisible4: false,
      canCloseDialogVisible4: true,
      isDialogVisible5: false,
      canCloseDialogVisible5: true,
      search: "",
      contactList: [],
      contactListModel: [],
      triOptions: [],
      selectedTri: "",
      checkedItems: [],
      societeOptions: [],
      checked: false,
      reload: "",
      reload2: "",
      listHeight: 0,
      listWidth: 0,
      nbContacts: 0,
    };
  }

  closeDialog = () => {
    this.setState({ isDialogVisible: false });
  };

  closeDialog2 = () => {
    this.setState({ isDialogVisible2: false });
  };

  closeDialog3 = () => {
    this.setState({ isDialogVisible3: false });
  };

  closeDialog4 = () => {
    this.setState({ isDialogVisible4: false });
  };

  closeDialog5 = () => {
    this.setState({ isDialogVisible5: false });
  };

  componentDidMount() {
    window.addEventListener(
      "popstate",
      (this.listener = (event) => handleBack(event, this))
    );

    this.unsubscribe = this.props.navigation.addListener("blur", () => {
      window.removeEventListener("popstate", this.listener);
    });

    this.unsubscribe2 = this.props.navigation.addListener("focus", () => {
      // this.setState({ reload2: new Date() });
      window.removeEventListener("popstate", this.listener);

      setTimeout(() => {
        window.addEventListener(
          "popstate",
          (this.listener = (event) => handleBack(event, this))
        );
      }, 1);
    });

    setTimeout(() => {
      let triOptions = [];
      if (!this.props.noTri) {
        let champs = Object.values(
          JSON.parse(JSON.stringify(this.props.settings.modeleContact))
        );
        triOptions = champs.map((ch) => {
          let list =
            ch.id === "Responsable"
              ? getAllResponsables(this.props.users)
              : ch.type === "Liste"
              ? getList(ch.id, this.props)
              : getAllValueFromChamp(ch.id, this.props.contacts);
          this.setState({ [ch.id + "Options"]: list });
          return { id: ch.id, label: ch.label };
        });
        triOptions = triOptions.sort(sortText);
      }
      let contactList = this._sortList(this.props.contacts);

      this.setState({
        forbiddenBack: this.props.tooManyContacts === true,
        isDialogVisible2: this.props.tooManyContacts === true,
        triOptions: triOptions,
        contactList: contactList,
        contactListModel: contactList,
        nbContacts: contactList.length,
        societeOptions: getList("Société", this.props),
        statutOptions: getList("Statut", this.props),
        isLoading: false,
      });
    }, 100);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.deleteMode !== this.props.deleteMode) {
      this.setState({ checkedItems: [] });
    }

    if (prevProps.lists !== this.props.lists) {
      this.setState({
        societeOptions: getList("Société", this.props),
        statutOptions: getList("Statut", this.props),
      });
    }

    if (
      this.props.settings?.modeleContact &&
      !this.props.noTri &&
      prevProps.settings.modeleContact !== this.props.settings.modeleContact
    ) {
      let champs = Object.values(
        JSON.parse(JSON.stringify(this.props.settings.modeleContact))
      );
      let triOptions = champs.map((ch) => {
        let list =
          ch.id === "Responsable"
            ? getAllResponsables(this.props.users)
            : ch.type === "Liste"
            ? getList(ch.id, this.props)
            : getAllValueFromChamp(ch.id, this.props.contacts);
        this.setState({ [ch.id + "Options"]: list });
        return { id: ch.id, label: ch.label };
      });
      triOptions = triOptions.sort(sortText);

      this.setState({ checkedItems: [], triOptions: triOptions });
    }

    if (
      prevProps.contacts !== this.props.contacts ||
      prevState.selectedTri !== this.state.selectedTri ||
      prevProps.lists !== this.props.lists
    ) {
      this.setState({ checkedItems: [] });

      if (this.state.selectedTri === "") {
        let contactList = this._sortList(this.props.contacts);
        this.setState({
          contactList: contactList,
          nbContacts: contactList.length,
          contactListModel: contactList,
        });

        this.setState({ reload2: new Date().getTime() });
      } else {
        this._loadList();
      }
      this._search(this.state.search);
    }

    if (prevState.checkedItems !== this.state.checkedItems) {
      if (this.state.checkedItems.length === this.props.contacts.length) {
        this.setState({ checked: true });
      } else {
        this.setState({ checked: false });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.listener);

    if (this.unsubscribe) this.unsubscribe();
    if (this.unsubscribe2) this.unsubscribe2();
  }

  _loadList() {
    const contactList = getAllChampByContacts(
      this.state.selectedTri,
      this.props.contacts,
      this.state[this.state.selectedTri + "Options"]
    );
    this.setState({
      contactList: contactList,
      contactListModel: contactList,
      nbContacts: contactList.reduce((accumulator, object) => {
        return accumulator + object.length;
      }, 0),
    });
  }

  _sortList(list) {
    if (this.props.relance) {
      return list.sort(function (a, b) {
        return (
          new Date(a["Date de relance"]).getTime() -
          new Date(b["Date de relance"]).getTime()
        );
      });
    } else if (this.state.selectedTri === "") {
      return list.sort(function (a, b) {
        if (
          a["Nom"]
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "") ===
          b["Nom"]
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        ) {
          return a["Prénom"]
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "") >
            b["Prénom"]
              .toUpperCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
            ? 1
            : -1;
        }

        if (
          a["Nom"]
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "") >
          b["Nom"]
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        ) {
          return 1;
        }

        if (
          a["Nom"]
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "") <
          b["Nom"]
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        ) {
          return -1;
        }

        return 0;
      });
    } else {
      return list.sort(function (a, b) {
        if (
          a["label"]
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "") >
          b["label"]
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        ) {
          return 1;
        }

        if (
          a["label"]
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "") <
          b["label"]
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        ) {
          return -1;
        }

        return 0;
      });
    }
  }

  _renderItem = (item) => {
    if (this.state.selectedTri !== "") {
      return (
        <CustomItemList
          deleteMode={this.props.deleteMode}
          checkItem={() => this._checkItem(item.id, this.state.selectedTri)}
          isChecked={() => this._isChecked(item.id, this.state.selectedTri)}
          content={
            <CustomText textType="bold" numberOfLines={1}>
              {item.label instanceof Date
                ? moment(item.label).format("DD/MM/YYYY")
                : item.label}
            </CustomText>
          }
          onPress={() =>
            this._displayDetailForChamp(this.state.selectedTri, item.id)
          }
          letters={item.length}
        />
      );
    } else {
      if (this.props.renderItem) {
        return this.props.renderItem(item);
      } else {
        return (
          <CustomItemList
            deleteMode={this.props.deleteMode}
            checkItem={() => this._checkItem(item.id, "")}
            isChecked={() => this._isChecked(item.id, "")}
            content={
              <View>
                <CustomText textType="bold" numberOfLines={1}>
                  {item["Prénom"] !== "" ? item["Prénom"] + " " : ""}
                  {item["Nom"]}
                </CustomText>
                <CustomText
                  numberOfLines={1}
                  style={{ color: labelColor, fontSize: textFontSize - 1 }}
                >
                  {getElementPreListLabelById(
                    this.state.societeOptions,
                    item["Société"]
                  )}
                </CustomText>
              </View>
            }
            onPress={() => this._displayDetailForContact(item.id)}
            letters={
              item["Prénom"] !== undefined && item["Nom"] !== undefined
                ? item["Prénom"].substring(0, 1) + item["Nom"].substring(0, 1)
                : ""
            }
          />
        );
      }
    }
  };

  _checkAllItems() {
    this.setState({
      checkedItems: this.state.checked
        ? []
        : this.props.contacts.map((c) => c.id),
      checked: !this.state.checked,
      reload: new Date().getTime(),
    });
  }

  _checkItem = (id, mode) => {
    if (mode !== "") {
      let contactsIds = getAllContactsByChamp(
        mode,
        id,
        this.props.contacts,
        this.state[mode + "Options"]
      ).map((c) => c.id);
      if (this.state.checkedItems.includes(contactsIds[0])) {
        this.setState({
          checkedItems: this.state.checkedItems.filter(
            (item) => !contactsIds.includes(item)
          ),
        });
      } else {
        this.setState({
          checkedItems: this.state.checkedItems.concat(contactsIds),
        });
      }
    } else {
      if (this.state.checkedItems.includes(id)) {
        this.setState({
          checkedItems: this.state.checkedItems.filter((item) => item !== id),
        });
      } else {
        this.setState({ checkedItems: this.state.checkedItems.concat(id) });
      }
    }
  };

  _isChecked = (id, mode) => {
    if (mode !== "") {
      let contactsIds = getAllContactsByChamp(
        mode,
        id,
        this.props.contacts,
        this.state[mode + "Options"]
      ).map((c) => c.id);
      if (this.state.checkedItems.includes(contactsIds[0])) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.state.checkedItems.includes(id)) {
        return true;
      } else {
        return false;
      }
    }
  };

  _displayDetailForContact(contactId) {
    this.props.navigation.navigate("ContactCard", {
      contactId: contactId,
      tooManyContacts: this.props.tooManyContacts,
    });
  }

  _displayDetailForChamp = (champId, champValue) => {
    if (this.props.tooManyContacts) {
      return;
    }

    let contacts = this.state.contactList.find(
      (list) => list.id === champValue
    ).contacts;

    this.props.navigation.push("Contact", {
      contactList: contacts,
      champId: champId,
      champValue: champValue,
    });
  };

  _displayNoFoundSearch() {
    if (this.state.contactList.length === 0) {
      return (
        <View
          style={{
            flex: 1,
            alignItems: "center",
            paddingHorizontal: "5%",
            paddingTop: "5%",
          }}
        >
          <CustomText>Vous n'avez pas de contacts.</CustomText>
        </View>
      );
    } else {
      return (
        <View
          key={this.state.reload2}
          onLayout={(event) =>
            this.setState({
              listHeight: event.nativeEvent.layout.height,
              listWidth: event.nativeEvent.layout.width,
            })
          }
          style={{ flex: 1 }}
        >
          {this.props.deleteMode ? (
            <Modal
              onBackdropClick={() => this.props.closeDeleteMode()}
              disablePortal
              sx={{ position: "absolute", overflowY: "scroll" }}
              BackdropProps={{
                style: {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                },
              }}
              open={true}
            >
              <View
                style={{ height: "100%", paddingTop: 4 }}
                key={this.state.reload}
              >
                <Paper
                  style={{
                    marginLeft: 15,
                    marginRight: 15,
                    borderRadius: 10,
                    marginBottom: 3,
                  }}
                  elevation={3}
                >
                  <TouchableOpacity
                    onPress={() => this._checkAllItems()}
                    style={{ flexDirection: "row", alignItems: "center" }}
                  >
                    <Checkbox checked={this.state.checked} />
                    <CustomText
                      style={{
                        fontFamily: "Quicksand-Bold",
                        color: getColor("color3", this.props),
                        textTransform: "uppercase",
                        fontSize: textFontSize - 3,
                      }}
                    >
                      Sélectionner tous les contacts
                    </CustomText>
                  </TouchableOpacity>
                </Paper>
                <CustomList
                  listWidth={this.state.listWidth}
                  listHeight={this.state.listHeight}
                  datas={this.state.contactList}
                  renderItem={this._renderItem}
                  addButton={this.props.addButton}
                />
              </View>
            </Modal>
          ) : (
            <CustomList
              listWidth={this.state.listWidth}
              listHeight={this.state.listHeight}
              datas={this.state.contactList}
              renderItem={this._renderItem}
              addButton={this.props.addButton}
            />
          )}
        </View>
      );
    }
  }

  _search = async (text) => {
    this.setState({ search: text });
    setTimeout(() => {
      if (text === this.state.search) {
        let newList = this.state.contactListModel.filter((item) => {
          let itemData = "";
          if (this.state.selectedTri !== "") {
            itemData = item["label"]
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "");
            itemData = itemData.toUpperCase();
          } else {
            itemData =
              item["Prénom"].normalize("NFD").replace(/[\u0300-\u036f]/g, "") +
              " " +
              item["Nom"].normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            itemData = itemData.toUpperCase();
          }

          return itemData.includes(
            text
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toUpperCase()
          );
        });
        this.setState({
          contactList: newList,
          nbContacts:
            this.state.selectedTri === ""
              ? newList.length
              : newList.reduce((accumulator, object) => {
                  return accumulator + object.length;
                }, 0),
        });
      }
    }, 100);
  };

  _deleteConfirm() {
    if (this.state.isLoading2) {
      return;
    }
    this.setState({ isLoading2: true });

    deleteMultipleDataToDatabase(this.state.checkedItems, this.props)
      .then(() => {
        setSnackbar(
          "success",
          "Contact(s) supprimé(s) avec succès",
          this.props
        );
      })
      .catch((error) => {
        setSnackbar(
          "error",
          error === "No network"
            ? "Erreur réseau"
            : "Une erreur est survenue. Veuillez réessayer plus tard",
          this.props
        );
        if (DEBUG) console.warn(error);
      })
      .finally(() => {
        this.setState({ isDialogVisible5: false }, () => {
          setTimeout(() => {
            this.props.closeDeleteMode();
            this.setState({ isLoading2: false, checkedItems: [] });
          }, 100);
        });
      });
  }

  _addContact() {
    if (
      this.props.data.length ===
      getNbContactsByAbonnement(this.props.abonnement)
    ) {
      this.setState({ isDialogVisible3: true });
    } else {
      this.props.navigation.navigate("UpdateContact");
    }
  }

  render() {
    return (
      <View style={{ flex: 1 }}>
        <Header
          navigation={this.props.headerBack ? this.props.navigation : null}
          iconLeft={
            this.props.headerIconLeft ? this.props.settings.icone : null
          }
          icon={
            this.props.headerIcon ? (
              <div style={{ display: "flex" }}>
                {this.props.headerIcon}
                {this.props.tooManyContacts ? (
                  <></>
                ) : (
                  <PopUpMenu
                    isDialogVisible={this.state.isDialogVisible4}
                    setDialogVisible={(visible) =>
                      this.setState({ isDialogVisible4: visible })
                    }
                    exportContacts={() =>
                      this.props.exportContacts(
                        this.state.contactList,
                        this.state.selectedTri !== ""
                      )
                    }
                    sendGroupedMail={(service) => {
                      this.closeDialog4();
                      this.props.sendGroupedMail(
                        this.state.contactList,
                        service,
                        this.state.selectedTri !== ""
                      );
                    }}
                    sendGroupedSms={() =>
                      this.props.sendGroupedSms(
                        this.state.contactList,
                        this.state.selectedTri !== ""
                      )
                    }
                  />
                )}
              </div>
            ) : null
          }
          headerId={this.props.headerId}
        >
          {this.props.headerTitle}
        </Header>
        <DialogAlert
          isDialogVisible={
            this.state.isDialogVisible === undefined
              ? false
              : this.state.isDialogVisible
          }
          onClose={() => {
            this.closeDialog();
          }}
          title={"Supprimer des contacts"}
          text={
            "Etes-vous sûr de vouloir supprimer les contacts sélectionnés ?"
          }
          confirm={() =>
            this.setState({ isDialogVisible: false, isDialogVisible5: true })
          }
          cancel={() => {
            this.closeDialog();
          }}
        />

        <DialogAlert
          isDialogVisible={
            this.state.isDialogVisible5 === undefined
              ? false
              : this.state.isDialogVisible5
          }
          onClose={() => {
            this.closeDialog5();
          }}
          title={"Confirmation"}
          confirm={() => this._deleteConfirm()}
          cancel={() => {
            this.closeDialog5();
          }}
          content={
            <View>
              <WarningAmberIcon
                color="warning"
                fontSize={"large"}
                style={{
                  marginBottom: 7,
                  textAlign: "center",
                  alignSelf: "center",
                  justifyContent: "center",
                }}
              />
              <CustomText style={{ fontSize: textFontSize - 2 }}>
                Etes-vous certain de vouloir supprimer les contacts sélectionnés
                ? Il sera impossible de les récupérer.
              </CustomText>
            </View>
          }
        />

        <DialogAlert
          isDialogVisible={
            this.state.isDialogVisible2 === undefined
              ? false
              : this.state.isDialogVisible2
          }
          onClose={() => {
            this.closeDialog2();
          }}
          title={"Nombre de contacts maximum dépassé"}
          text={
            "La limite du nombre de contacts autorisé dans votre abonnement est dépassée. Veuillez supprimer des contacts ou mettre à jour votre abonnement afin de continuer à utiliser l'application."
          }
          validText="Accéder au site"
          cancelText="Ok"
          confirm={() => {
            window.open("https://www.tanko.app");
            this.setState({ isDialogVisible2: false });
          }}
          cancel={() => {
            this.closeDialog2();
          }}
        />

        <DialogAlert
          isDialogVisible={
            this.state.isDialogVisible3 === undefined
              ? false
              : this.state.isDialogVisible3
          }
          onClose={() => {
            this.closeDialog3();
          }}
          title={"Impossible d'ajouter un contact"}
          text={
            "Votre abonnement ne vous permet pas d'ajouter un contact supplémentaire. Veuillez mettre à jour votre abonnement afin d'augmenter le nombre maximum de contacts autorisé."
          }
          validText="Accéder au site"
          cancelText="Ok"
          confirm={() => {
            window.open("https://www.tanko.app");
            this.setState({ isDialogVisible3: false });
          }}
          cancel={() => {
            this.closeDialog3();
          }}
        />
        {displayLoading(this.state.isLoading2)}
        {this.state.isLoading ? (
          displayLoading(this.state.isLoading)
        ) : (
          <View style={globalStyles.list_container}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                opacity: this.state.isLoading2 ? 0.5 : 1,
                overflowY: "scroll",
                scrollbarWidth: "none",
              }}
            >
              {this.props.noTri ? (
                <></>
              ) : (
                <View style={{ paddingHorizontal: "5%", paddingTop: 8 }}>
                  <CustomSelect
                    noPadding
                    label={"Sélectionner par"}
                    value={this.state.selectedTri}
                    onChange={(value) => {
                      this.setState({ selectedTri: value.id });
                    }}
                    options={this.state.triOptions}
                    isClearable
                    isSearchable
                  />
                  <Search
                    searchOnClick={() => this._search("")}
                    searchOnChangeText={(text) => this._search(text)}
                    searchValue={this.state.search}
                  />
                </View>
              )}
              {this.state.contactList.length > 0 ? (
                <View style={{ padding: 12, paddingTop: 13 }}>
                  <CustomText
                    textType="bold"
                    style={{ fontSize: floatingLabelTextFontsize }}
                  >
                    {this.props.abonnement ===
                    abonnements.find((abo) => abo.label === "subscriber").label
                      ? "Contacts : " +
                        this.state.nbContacts +
                        " / " +
                        getNbContactsLabelByAbonnement(this.props.abonnement)
                      : "Contacts : " + this.state.nbContacts}{" "}
                  </CustomText>
                </View>
              ) : (
                <></>
              )}
              {this._displayNoFoundSearch()}
            </div>
          </View>
        )}
        {((!this.props.deleteMode && this.props.addButton) ||
          (this.props.deleteMode && this.state.checkedItems.length > 0)) &&
        this.props.currentUser.type === "edit" ? (
          <View
            style={{ position: "absolute", right: 15, bottom: 15, zIndex: 2 }}
          >
            <Fab
              title={
                this.props.deleteMode
                  ? "Supprimer les contacts sélectionnés"
                  : this.props.tooManyContacts
                  ? "Valider"
                  : "Créer un contact"
              }
              onClick={() =>
                this.props.deleteMode
                  ? this.setState({ isDialogVisible: true })
                  : this.props.tooManyContacts
                  ? this.props.navigation.goBack()
                  : this._addContact()
              }
              color="primary"
            >
              {this.props.deleteMode ? (
                <DeleteIcon style={{ fontSize: 30, color: "white" }} />
              ) : this.props.tooManyContacts ? (
                <DoneIcon style={{ fontSize: 30, color: "white" }} />
              ) : (
                <AddIcon style={{ fontSize: 30, color: "white" }} />
              )}
            </Fab>
          </View>
        ) : (
          <></>
        )}
        {this.props.deleteMode ? (
          <View
            style={{
              position: "absolute",
              left: 15,
              bottom: 15,
              flexDirection: "row",
              zIndex: 1,
            }}
          >
            <Fab
              title="Annuler"
              onClick={() => {
                this.setState({ checkedItems: [] });
                this.props.closeDeleteMode();
              }}
              color="primary"
              aria-label="close delete mode"
            >
              <CloseIcon style={{ fontSize: 30, color: "white" }} />
            </Fab>
            <Paper
              sx={{ border: 1 }}
              elevation={8}
              style={{
                backgroundColor: "white",
                alignSelf: "center",
                borderColor: getColor("color1", this.props),
                borderWidth: 2,
                padding: 7,
                paddingLeft: 12,
                borderLeftWidth: 0,
                marginLeft: -7,
              }}
            >
              <CustomText>
                {this.state.checkedItems.length + " contact(s) sélectionné(s)"}
              </CustomText>
            </Paper>
          </View>
        ) : (
          <></>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.setData.data,
    settings: state.setAccount.settings,
    currentUser: state.setData.currentUser,
    lists: state.setData.lists,
    isConnectedToNetwork: state.setConnection.isConnectedToNetwork,
    accountAccessKey: state.setAccount.accountAccessKey,
    abonnement: state.setAccount.abonnement,
    users: state.setData["users"],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactList);
