const initialState = {language: 'fr'};

function setLanguage (state = initialState, action) {
  let nextState;
  switch (action.type) {
    case 'SET_LANGUAGE':
      nextState = {
        ...state,
        language: action.value,
      };
      return nextState || state;
    default:
      return state;
  }
}

export default setLanguage;
