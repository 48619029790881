import React, { createRef } from "react";
import { View } from "react-native";
import {
  floatingLabelTextFontsize,
  labelFontSize,
  textFontSize,
} from "../../styles/styles";
import { IconButton, MenuItem, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { getRealHeight } from "../../utilities/utils";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CustomText from "./CustomText";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const styles = (theme) => ({
  noOptions: {
    fontSize: textFontSize,
    fontFamily: "Quicksand-Regular",
  },
  groupLabel: {
    fontFamily: "Quicksand-Bold",
    fontSize: labelFontSize,
  },
});

class CustomSelect extends React.Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
    this.inputRef = createRef();
    this.state = {
      inputVal:
        this.props.value !== "" &&
        this.props.options.find((e) => e.id === this.props.value) !== undefined
          ? this.props.options.find((e) => e.id === this.props.value).label
          : "",
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isSearchable &&
      (prevProps.value !== this.props.value ||
        prevProps.options !== this.props.options)
    ) {
      this.setState(
        {
          inputVal:
            this.props.value !== "" &&
            this.props.options.find((e) => e.id === this.props.value) !==
              undefined
              ? this.props.options.find((e) => e.id === this.props.value).label
              : "",
        },
        () => {
          this.inputRef.current.blur();
        }
      );
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <View
        onLayout={() =>
          this.props.setRef ? this.props.setRef(this.ref) : null
        }
        style={{
          paddingBottom: this.props.noPadding ? 0 : 10,
          paddingTop: 10,
          justifyContent: "center",
        }}
      >
        <div key={this.props.key} className="scrollbar">
          {this.props.isSearchable ? (
            <View style={{ justifyContent: "center" }}>
              <Autocomplete
                {...{
                  options: this.props.options,
                  getOptionLabel: (option) => option.label,
                }}
                ref={this.ref}
                groupBy={(option) => option.category}
                renderGroup={(params) => (
                  <li key={params.key}>
                    {params.group ? (
                      <View
                        style={{ flexDirection: "row", paddingVertical: 6 }}
                      >
                        <ArrowForwardIcon
                          fontSize="medium"
                          style={{ marginRight: 5, marginLeft: 5 }}
                        />
                        <CustomText textType="bold">{params.group}</CustomText>
                      </View>
                    ) : (
                      <></>
                    )}
                    {this.props.importContact && params.group === undefined ? (
                      <ul>
                        {params.children.map((child) => (
                          <li
                            {...child.props}
                            style={{ flex: 1, paddingLeft: 0 }}
                          >
                            {" "}
                            <ArrowForwardIcon
                              fontSize="medium"
                              style={{ marginRight: 5, marginLeft: 5 }}
                            />
                            <b>{child.props.children}</b>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <ul>
                        {params.children.map((child) => (
                          <li
                            {...child.props}
                            style={{
                              flex: 1,
                              paddingLeft: this.props.importContact ? 40 : 20,
                            }}
                          >
                            {" "}
                            {child.props.children}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                )}
                onOpen={
                  this.props.changeScrolling
                    ? () => this.props.changeScrolling()
                    : null
                }
                onClose={
                  this.props.changeScrolling
                    ? () => this.props.changeScrolling()
                    : null
                }
                onBlur={() => {
                  if (
                    this.props.options.find(
                      (e) => e.id === this.props.value
                    ) === undefined
                  ) {
                    this.setState({ inputVal: "" });
                  } else {
                    this.setState({
                      inputVal: this.props.options.find(
                        (e) => e.id === this.props.value
                      ).label,
                    });
                  }
                }}
                classes={{
                  noOptions: classes.noOptions,
                  groupLabel: classes.groupLabel,
                }}
                disabled={this.props.disabled}
                renderInput={(params) => (
                  <div>
                    <TextField
                      {...params}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": {
                            borderColor: this.props.borderColor,
                          },
                        },
                        "& .MuiListSubheader-root .MuiListSubheader-gutters .MuiListSubheader-sticky .CustomSelect-groupLabel-2 .MuiAutocomplete-groupLabel .css-esv6et-MuiListSubheader-root-MuiAutocomplete-groupLabel":
                          {
                            color: "red",
                          },
                      }}
                      label={this.props.label}
                      size="small"
                      inputRef={this.inputRef}
                      InputLabelProps={{
                        ...params.InputLabelProps,
                        style: {
                          color: "grey",
                          fontFamily: "Quicksand-Bold",
                          fontSize: labelFontSize,
                        },
                      }}
                      itemProp={{
                        ...params.itemProp,
                        style: {
                          color: "grey",
                          fontFamily: "Quicksand-Regular",
                          fontSize: textFontSize,
                        },
                      }}
                      SelectProps={{
                        ...params.SelectProps,
                        style: {
                          color: "grey",
                          fontFamily: "Quicksand-Regular",
                          fontSize: textFontSize,
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        className: "noselect",
                        style: {
                          color: "grey",
                          fontFamily: "Quicksand-Regular",
                          fontSize: textFontSize,
                        },
                      }}
                      onChange={(event) => {
                        this.setState({ inputVal: event.target.value });
                        if (this.props.onInputChange)
                          this.props.onInputChange(event.target.value);
                      }}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          color: "black",
                          fontFamily: "Quicksand-Regular",
                          fontSize: textFontSize,
                          paddingRight: 30,
                        },
                        maxLength: 20,
                      }}
                      FormHelperTextProps={{
                        style: {
                          color: "red",
                          fontFamily: "Quicksand-Regular",
                          fontSize: floatingLabelTextFontsize,
                        },
                      }}
                      helperText={this.props.helperText}
                      error={
                        this.props.helperText !== undefined &&
                        this.props.helperText !== ""
                      }
                      type={this.props.type}
                    />
                  </div>
                )}
                inputValue={this.state.inputVal}
                ListboxProps={{
                  style: {
                    color: "black",
                    fontFamily: "Quicksand-Regular",
                    fontSize: textFontSize,
                    maxHeight: getRealHeight() * 0.4,
                  },
                }}
                disableClearable={!this.props.isClearable}
                onChange={(value, option) => {
                  this.setState({ inputVal: option ? option.label : "" });
                  this.props.onChange(option ? option : { label: "", id: "" });
                }}
                value={
                  this.props.value === ""
                    ? { label: "", id: "" }
                    : this.props.options.find((e) => e.id === this.props.value)
                }
                isOptionEqualToValue={(option, value) => {
                  if (
                    option &&
                    value &&
                    option !== "" &&
                    option.id === value.id
                  ) {
                    return true;
                  }
                  return false;
                }}
                noOptionsText={
                  this.props.noOptionsAction
                    ? this.props.noOptionsAction
                    : "Aucune correspondance"
                }
                openText=""
                clearText=""
                closeText=""
              />
              {(this.props.setList || this.props.navigateTo) &&
              this.props.currentUser.type === "edit" ? (
                <div
                  id={this.props.setListId}
                  style={{
                    position: "absolute",
                    right: 53,
                    //padding: 5,
                    cursor: "pointer",
                  }}
                >
                  <IconButton
                    title="Gérer la liste"
                    size="small"
                    onClick={
                      this.props.navigateTo
                        ? this.props.navigateTo
                        : this.props.setList
                    }
                  >
                    <FormatListBulletedIcon
                      fontSize="small"
                      style={{ color: "grey" }}
                    />
                  </IconButton>
                </div>
              ) : (
                <></>
              )}
            </View>
          ) : (
            <View style={{ justifyContent: "center" }}>
              <TextField
                disabled={this.props.disabled}
                select
                label={this.props.label}
                size="small"
                value={
                  this.props.value === "" ||
                  this.props.options.find((e) => e.id === this.props.value) ===
                    undefined
                    ? ""
                    : this.props.options.find((e) => e.id === this.props.value)
                        .label
                }
                InputLabelProps={{
                  style: { color: "grey", fontFamily: "Quicksand-Bold" },
                }}
                inputProps={{
                  style: {
                    color: "black",
                    fontFamily: "Quicksand-Regular",
                    fontSize: textFontSize,
                  },
                  maxLength: 20,
                }}
                SelectProps={{
                  style: {
                    color: "black",
                    fontFamily: "Quicksand-Regular",
                    fontSize: textFontSize,
                  },
                }}
                FormHelperTextProps={{
                  style: {
                    color: "black",
                    fontFamily: "Quicksand-Regular",
                    fontSize: floatingLabelTextFontsize,
                  },
                }}
                onChange={(event, child) =>
                  this.props.onChange(
                    child && child.props
                      ? { label: child.props.value, id: child.props.id }
                      : { label: "", id: "" }
                  )
                }
                helperText={this.props.helperText}
                error={
                  this.props.helperText !== undefined &&
                  this.props.helperText !== ""
                }
                type={this.props.type}
              >
                {this.props.options.map((option) => (
                  <MenuItem
                    key={option.id}
                    style={{
                      fontFamily: "Quicksand-Regular",
                      fontSize: textFontSize,
                    }}
                    id={option.id}
                    value={option.label}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {this.props.value && this.props.isClearable ? (
                <div
                  style={{
                    position: "absolute",
                    right: 25,
                    padding: 7,
                    cursor: "pointer",
                  }}
                >
                  <IconButton
                    title="Effacer"
                    onClick={() => this.props.onChange({ label: "", id: "" })}
                  >
                    <CloseIcon style={{ color: "grey" }} />
                  </IconButton>
                </div>
              ) : (
                <></>
              )}
            </View>
          )}
        </div>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.setData["currentUser"],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CustomSelect));
