import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Dialog, DialogContent, Divider } from "@mui/material";
import { TouchableOpacity } from "react-native";
import CustomText from "../../CustomComponents/CustomText";
import EmailIcon from "@mui/icons-material/Email";
import { connect } from "react-redux";

class PopUpMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  _handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  _handleClose = () => {
    this.setState({ anchorEl: null });
  };

  _sendGroupedMail = (service) => {
    this.props.sendGroupedMail(service);
    this.setState({ anchorEl: null });
  };

  _sendGroupedSms = () => {
    this.props.sendGroupedSms();
    this.setState({ anchorEl: null });
  };

  _exportContacts = () => {
    this.props.exportContacts();
    this.setState({ anchorEl: null });
  };

  render() {
    return (
      <div>
        <Dialog
          onBackdropClick={() => this.props.setDialogVisible(false)}
          open={this.props.isDialogVisible}
        >
          <DialogContent>
            <TouchableOpacity
              onPress={() => this._sendGroupedMail()}
              style={{
                flexDirection: "row",
                alignItems: "center",
                paddingVertical: 12,
              }}
            >
              <IconButton
                color="primary"
                title="Email"
                size="medium"
                style={{ marginRight: 24 }}
              >
                <EmailIcon fontSize={"medium"} />
              </IconButton>
              <CustomText style={{ justifyContent: "center" }}>
                Utiliser mon application de messagerie
              </CustomText>
            </TouchableOpacity>
            <Divider style={{ marginVertical: 7 }} />

            <TouchableOpacity
              onPress={() => this._sendGroupedMail("sendinblue")}
              style={{
                flexDirection: "row",
                alignItems: "center",
                paddingVertical: 12,
              }}
            >
              <img
                style={{ padding: 8, marginRight: 24 }}
                alt="Logo Sendinblue"
                width={40}
                src={require("../../../assets/images/Sendinblue.webp")}
              />

              <CustomText style={{ justifyContent: "center" }}>
                Utiliser un template Brevo (ex Sendinblue)
              </CustomText>
            </TouchableOpacity>
          </DialogContent>
        </Dialog>

        <IconButton
          color="secondary"
          title="Menu"
          size="medium"
          onClick={this._handleClick}
        >
          <MoreVertIcon fontSize={"medium"} />
        </IconButton>

        <Menu
          anchorEl={this.state.anchorEl}
          open={this.state.anchorEl}
          onClose={this._handleClose}
        >
          {this.props.currentUser.type === "read" ? (
            <></>
          ) : (
            <MenuItem key={0} onClick={this._exportContacts}>
              Exporter les contacts
            </MenuItem>
          )}
          <MenuItem
            key={1}
            onClick={() => {
              this.setState({ anchorEl: null });
              this.props.setDialogVisible(true);
            }}
          >
            Envoyer un mail groupé
          </MenuItem>
          <MenuItem key={2} onClick={this._sendGroupedSms}>
            Envoyer un sms groupé
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.setData.data,
    currentUser: state.setData["currentUser"],
    abonnement: state.setAccount.abonnement,
    accountAccessKey: state.setAccount.accountAccessKey,
    settings: state.setAccount.settings,
    isConnectedToNetwork: state.setConnection.isConnectedToNetwork,
    lists: state.setData.lists,
    users: state.setData["users"],
    deleted: state.setAccount.deleted,
    isVersionValid: state.setVersionGuard.isVersionValid,
    abonnementEotTime: state.setAccount.abonnementEotTime,
    id: state.setAccount.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PopUpMenu);
