const initialState = {
  data: [],
  users: [],
  currentUser: {},
  lists: [],
  dataToDelete: [],
  dataToAdd: [],
  dataIsImporting: false,
};

function setData(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "SET_DATA":
      nextState = {
        ...state,
        data: action.value,
      };
      return nextState || state;
    case "DATA_IS_IMPORTING":
      nextState = {
        ...state,
        dataIsImporting: action.value,
      };
      return nextState || state;
    case "SET_USERS":
      nextState = {
        ...state,
        users: action.value,
      };
      return nextState || state;
    case "SET_CURRENT_USER":
      nextState = {
        ...state,
        currentUser: action.value,
      };
      return nextState || state;
    case "SET_LISTS":
      nextState = {
        ...state,
        lists: action.value,
      };
      return nextState || state;
    case "SET_DATA_TO_DELETE":
      nextState = {
        ...state,
        dataToDelete: action.value,
      };
      return nextState || state;
    case "SET_DATA_TO_ADD":
      nextState = {
        ...state,
        dataToAdd: action.value,
      };
      return nextState || state;

    default:
      return state;
  }
}

export default setData;
