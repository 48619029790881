import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux';

function Snackbar(props) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isFirstRender, setIsFirstRender] = useState(true)


    useEffect(() => {
        if (!isFirstRender && props.snackbar !== undefined && props.snackbar.message !== undefined && props.snackbar.message !== "") {

            enqueueSnackbar(props.snackbar.message, {
                autoHideDuration: 10000,
                variant: props.snackbar.severity,
                action: (id) => <>
                    <IconButton
                        color='secondary'
                        title="Fermer"
                        size='medium'
                        onClick={() => closeSnackbar(id)}
                    >
                        <CloseIcon fontSize={'medium'} />
                    </IconButton>

                </>
            })

        } else {
            setIsFirstRender(false)
        }
    }, [props.snackbar])

    return (<></>)

};


const mapStateToProps = state => {
    return {
        snackbar: state.setSnackbar.snackbar
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch: action => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
