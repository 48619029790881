import { StyleSheet } from 'react-native';
import { textFontSize } from '../../../styles/styles';

export const styles = StyleSheet.create({
  image: {
    flex: 2,
  },
  text: {
    textAlign: 'center',
    fontSize: textFontSize,
  },
  cgu_paragraphe: {
    marginVertical: 15
  },
  cgu_text: {
    fontSize: 11
  },
  cgu_link: {
    fontSize: 11,
    color: 'blue',
    textDecorationLine: 'underline'
  },
  cgu_title: {
    fontSize: 12,
    marginBottom: 7,
    textTransform: 'uppercase',
    fontWeight: 'bold'

  },
  confidentialite_text: {
    fontSize: 12
  }
});
