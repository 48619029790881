import React from "react";
import { ScrollView, TouchableOpacity, View } from "react-native";
import CustomText from "../../CustomComponents/CustomText";
import CustomButton from "../../CustomComponents/CustomButton";
import Header from "../Headers/Header";
import { connect } from "react-redux";
import {
  displayLoading,
  hideKeyboard,
  resetStorage,
  setSnackbar,
} from "../../../utilities/utils";
import FloatingLabelInput from "../../CustomComponents/FloatingLabelInput";
import { accountSignIn } from "../../../api/helpers";
import { checkIfDBExistsForCreate, createDB, version } from "../../../db/db";
import { globalStyles, smallTextFontSize } from "../../../styles/styles";
import NoNetworkNotification from "../../CustomComponents/NoNetworkNotification";
import AddToHomeScreenBanner from "../../CustomComponents/AddToHomeScreenBanner";
import { isIOS, isMobileSafari } from "react-device-detect";
import firebase from "firebase/app";
import "firebase/auth";
import { authorization, DEBUG } from "../../../api/constants";
import DialogAlert from "../../CustomComponents/DialogAlert";

class SuperSignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoading2: false,
      isDialogVisible: false,
      canCloseDialogVisible: false,
      accountAccessKey: "",
      accountAccessKeyInfoLabel: "",
      secureTextEntry: true,
    };
  }

  componentDidMount() {
    let action = { type: "SET_DATA", value: [] };
    this.props.dispatch(action);

    action = { type: "DATA_IS_IMPORTING", value: false };
    this.props.dispatch(action);

    action = { type: "SET_USERS", value: [] };
    this.props.dispatch(action);

    action = { type: "SET_CURRENT_USER", value: {} };
    this.props.dispatch(action);

    action = { type: "SET_LISTS", value: [] };
    this.props.dispatch(action);

    action = { type: "SET_LANGUAGE", value: "fr" };
    this.props.dispatch(action);

    action = { type: "SET_CONNECTION", value: true };
    this.props.dispatch(action);

    action = { type: "SET_ACCOUNT_ACCESS_KEY", value: "" };
    this.props.dispatch(action);

    action = { type: "SET_ACCOUNT_ID", value: "" };
    this.props.dispatch(action);

    action = { type: "SET_ACCOUNT_ABONNEMENT", value: "" };
    this.props.dispatch(action);

    action = { type: "SET_ACCOUNT_ABONNEMENT_EOT_TIME", value: "" };
    this.props.dispatch(action);

    action = { type: "SET_ACCOUNT_SETTINGS", value: {} };
    this.props.dispatch(action);

    action = { type: "SET_ACCOUNT_ICON", value: "" };
    this.props.dispatch(action);

    action = { type: "SET_ACCOUNT_DELETED", value: false };
    this.props.dispatch(action);

    action = { type: "SET_SNACKBAR", value: "" };
    this.props.dispatch(action);
    this.setState({ isLoading: false });
  }

  _validForm() {
    if (this.state.accountAccessKey.length === 0) {
      if (this.state.accountAccessKey.length === 0) {
        this.setState({
          accountAccessKeyInfoLabel: "Paramètre obligatoire",
        });
      }
      return false;
    }

    if (!this.props.isConnectedToNetwork) {
      setSnackbar("error", "Erreur réseau", this.props);
      return false;
    }

    return true;
  }

  _signIn() {
    hideKeyboard();

    if (this.state.isLoading2) {
      return;
    }

    this.setState({
      isLoading2: true,
      emailInfoLabel: "",
      accountAccessKeyInfoLabel: "",
    });

    setTimeout(() => {
      if (!this.props.isVersionValid) {
        this.setState({ isDialogVisible: true, isLoading2: false });
        return;
      }

      if (!this._validForm()) {
        this.setState({ isLoading2: false });
        return;
      }

      accountSignIn(
        this.state.accountAccessKey.toString().trim(),
        this.props,
        this._loadingOff,
        this._createDB
      );
    }, 1000);
  }

  _loadingOn = () => {
    this.setState({ isLoading2: true });
  };

  _loadingOff = () => {
    this.setState({ isLoading2: false });
  };

  _secureTextEntryOnOff() {
    this.setState({ secureTextEntry: !this.state.secureTextEntry });
  }

  _createDB = (email, name) => {
    checkIfDBExistsForCreate(
      this.state.accountAccessKey.toString().trim(),
      this.props
    )
      .then((res) => {
        if (!res) {
          firebase
            .auth()
            .createUserWithEmailAndPassword(
              this.state.accountAccessKey.toString().trim() + "@firebase.com",
              this.state.accountAccessKey.toString().trim() + authorization
            )
            .then(() => {
              createDB(
                email,
                name,
                this.state.accountAccessKey.toString().trim(),
                this.props
              )
                .then(() => {
                  let action = {
                    type: "SET_ACCOUNT_ACCESS_KEY",
                    value: this.state.accountAccessKey.toString().trim(),
                  };
                  this.props.dispatch(action);
                  this.props.navigation.replace("SignInLoadingScreen", {
                    firstTime: true,
                  });
                })
                .catch((error) => {
                  if (DEBUG) console.warn(error);
                  setSnackbar(
                    "error",
                    error === "No network"
                      ? "Erreur réseau"
                      : "Une erreur est survenue. Veuillez réessayer plus tard",
                    this.props
                  );
                  this._loadingOff();
                });
            })
            .catch((err) => {
              if (DEBUG) console.warn(err);
              setSnackbar(
                "error",
                "Une erreur est survenue. Veuillez réessayer plus tard",
                this.props
              );
              this._loadingOff();
            });
        } else {
          firebase
            .auth()
            .signInWithEmailAndPassword(
              this.state.accountAccessKey.toString().trim() + "@firebase.com",
              this.state.accountAccessKey.toString().trim() + authorization
            )
            .then(() => {
              let action = {
                type: "SET_ACCOUNT_ACCESS_KEY",
                value: this.state.accountAccessKey.toString().trim(),
              };
              this.props.dispatch(action);
              this.props.navigation.replace("SignInLoadingScreen");
            })
            .catch((err) => {
              if (DEBUG) console.warn(err);
              setSnackbar(
                "error",
                "Une erreur est survenue. Veuillez réessayer plus tard",
                this.props
              );
              this._loadingOff();
            });
        }
      })
      .catch((err) => {
        if (DEBUG) console.warn(err);
        setSnackbar(
          "error",
          err === "No network"
            ? "Erreur réseau"
            : "Une erreur est survenue. Veuillez réessayer plus tard",
          this.props
        );
        this._loadingOff();
      });
  };

  render() {
    return (
      <View style={globalStyles.main_container}>
        <Header preventColorChange />

        <DialogAlert
          onClose={() => {}}
          isDialogVisible={this.state.isDialogVisible}
          title={"Votre application n'est pas à jour"}
          text={
            "Une nouvelle version est disponible. Veuillez recharger l'application et vous reconnecter."
          }
          cancelText="Recharger"
          onlyOkButton={true}
          cancel={() => {
            this.setState({ isDialogVisible: false, isLoading: true });
            setTimeout(() => {
              resetStorage();
              window.location.reload(true);
            }, 300);
          }}
        />

        {displayLoading(this.state.isLoading2)}
        {this.state.isLoading ? (
          displayLoading(this.state.isLoading)
        ) : (
          <ScrollView
            style={globalStyles.form_container}
            contentContainerStyle={{
              flexGrow: 1,
              paddingVertical: "5%",
              opacity: this.state.isLoading2 ? 0.5 : 1,
            }}
          >
            <View style={{ flex: 1 }}>
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="Logo Tankō"
                  width={300}
                  src={require("../../../assets/images/Tanko_Logo.png")}
                />

                <View
                  style={{
                    paddingVertical: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CustomText style={{ textAlign: "center" }}>
                    Connectez vous à l'aide de votre Code d'accès Tankō
                  </CustomText>
                </View>
              </View>
              <View
                style={{
                  marginVertical: "5%",
                  justifyContent: "center",
                }}
              >
                <form onSubmit={(event) => event.preventDefault()}>
                  <FloatingLabelInput
                    autoComplete
                    label={"Code d'accès"}
                    onChange={(value) =>
                      this.setState({ accountAccessKey: value })
                    }
                    value={this.state.accountAccessKey}
                    helperText={this.state.accountAccessKeyInfoLabel}
                    type={"text"}
                    onSubmit={() => this._signIn()}
                    enterkeyhint="go"
                  />
                </form>
              </View>
              <View
                style={{
                  marginTop: 5,
                  alignSelf: "center",
                }}
              >
                <CustomButton
                  preventColorChange
                  title={"Se connecter"}
                  onPress={() => this._signIn()}
                />
              </View>
              <View
                style={{
                  marginTop: 30,
                }}
              >
                <TouchableOpacity
                  onPress={() => this.props.navigation.navigate("CGU")}
                >
                  <CustomText
                    style={{
                      textAlign: "center",
                      color: "blue",
                      textDecorationLine: "underline",
                    }}
                  >
                    En appuyant sur "Se connecter", vous acceptez les Conditions
                    d'utilisation de Tankō
                  </CustomText>
                </TouchableOpacity>
              </View>
            </View>
            <View>
              <CustomText
                style={{ fontSize: smallTextFontSize, textAlign: "center" }}
                tradOff
              >
                {"v " + version}
              </CustomText>
            </View>
          </ScrollView>
        )}
        <NoNetworkNotification />
        {isMobileSafari &&
        isIOS &&
        !window.matchMedia("(display-mode: standalone)").matches ? (
          <AddToHomeScreenBanner></AddToHomeScreenBanner>
        ) : (
          <></>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.setLanguage.language,
    users: state.setData.users,
    currentUser: state.setData.currentUser,
    isConnectedToNetwork: state.setConnection.isConnectedToNetwork,
    settings: state.setAccount.settings,
    isVersionValid: state.setVersionGuard.isVersionValid,
    accountAccessKey: state.setAccount.accountAccessKey,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SuperSignIn);
