import React, { createRef } from 'react';
import {
    View,
} from 'react-native';
import CustomText from '../../CustomComponents/CustomText';
import CustomButton from '../../CustomComponents/CustomButton';
import CustomTitle from '../../CustomComponents/CustomTitle';
import Header from '../Headers/Header';
import { connect } from 'react-redux';
import { displayLoading, handleBack, promiseTimeout, setSnackbar } from '../../../utilities/utils';
import { updateIconeToDatabase } from '../../../db/db';
import { Fab } from '@mui/material';
import firebase from 'firebase/app';
import 'firebase/storage'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { globalStyles } from '../../../styles/styles';
import { DEBUG, TIMEOUT } from '../../../api/constants';
import NoNetworkNotification from '../../CustomComponents/NoNetworkNotification';

class PersonnalisationIcone extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isLoading2: false,
        };

        this.inputIconFile = createRef(null)

    }

    componentDidMount() {
        this.unsubscribe = this.props.navigation.addListener('blur', () => {
            window.removeEventListener('popstate', this.listener)
        })

        this.unsubscribe2 = this.props.navigation.addListener('focus', () => {
            setTimeout(() => {
                window.addEventListener('popstate', this.listener = (event) => handleBack(event, this));
            }, 1)
        })
    }


    componentWillUnmount() {
        window.removeEventListener('popstate', this.listener)

        if (this.unsubscribe)
            this.unsubscribe()
        if (this.unsubscribe2)
            this.unsubscribe2()
    }



    _uploadIcone = event => {
        if (this.state.isLoading2) {
            return
        }

        this.setState({ isLoading2: true })

        if (!(event && event.target && event.target.files && event.target.files[0] && event.target.files[0].type)) {
            setSnackbar('error', "Une erreur est survenue. Veuillez réessayer ultérieurement", this.props)

            this.setState({ isLoading2: false })
            return
        }


        if (event.target.files[0].type !== "image/jpeg" && event.target.files[0].type !== "image/jpg" && event.target.files[0].type !== "image/png" && event.target.files[0].type !== "image/svg+xml") {
            setSnackbar('error', "Fichier incorrect. Veuillez choisir un fichier png, jpg ou svg", this.props)

            this.setState({ isLoading2: false })
            return
        }

        let scope = this


        promiseTimeout(TIMEOUT, "firebase.storage", new Promise(function (resolve, reject) {
            if (!scope.props.isConnectedToNetwork) {
                reject("No network")
            }

            const ref = firebase.storage().ref().child(scope.props.accountAccessKey + "/icone")
            ref.put(event.target.files[0]).then(() => {
                ref.getDownloadURL().then(url => {
                    resolve(url)
                }).catch(err2 => {
                    if (DEBUG) console.warn(err2)
                    reject()
                })
            }).catch(err => {
                if (DEBUG) console.warn(err)
                reject()
            })
        })).then((url) => {
            updateIconeToDatabase(url, scope.props).then(() => {
                setSnackbar('success', "Icône importée avec succès", scope.props)
            }).catch(err => {
                setSnackbar('error', err === "No network" ? "Erreur réseau" : 'Une erreur est survenue. Veuillez réessayer plus tard', scope.props)
            }).finally(() => {
                scope.setState({ isLoading2: false })
            })
        }).catch(error => {
            setSnackbar('error', error === "No network" ? "Erreur réseau" : 'Une erreur est survenue. Veuillez réessayer plus tard', scope.props)
            if (DEBUG) console.warn(error)
        }).finally(() => {
            setTimeout(() => {
                scope.setState({ isLoading2: false })
                document.getElementById('file').value = ''
            }, 100)
        })

    }



    render() {
        return (
            <View style={{ flex: 1 }}>
                <Header
                    iconLeft={this.props.settings.icone}
                >
                </Header>
                {displayLoading(this.state.isLoading2)}
                {this.state.isLoading ? displayLoading(this.state.isLoading) :
                    <View
                        style={[{
                            paddingVertical: 20,
                            flex: 1,
                            opacity: this.state.isLoading2 ? 0.5 : 1,
                        }, globalStyles.list_container]}>
                        <div style={{
                            paddingLeft: '7%',
                            paddingRight: '7%',
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1
                        }}>
                            <View style={{ flex: 1 }}>
                                <View style={{ paddingBottom: 20, justifyContent: 'center', alignItems: 'center' }}>
                                    <CustomTitle style={{ fontSize: 25, textAlign: 'center' }}>
                                        Personnalisez votre Tankō
                                    </CustomTitle>
                                </View>
                                <View style={{ paddingVertical: 10, justifyContent: 'center' }}>
                                    <CustomText style={{}}>
                                        Commencez par personnaliser votre Tankō en important votre logo.
                                    </CustomText>

                                </View>
                                <View style={{ paddingVertical: 10, justifyContent: 'center' }}>

                                    <CustomText style={{}}>
                                        Vous pourrez toujours importer votre logo plus tard dans les paramètres.
                                    </CustomText>
                                </View>

                                <View
                                    style={{
                                        paddingTop: 30,
                                        alignSelf: 'center',
                                    }}
                                >
                                    <CustomButton
                                        onPress={() => this.inputIconFile.current.click()}
                                        title={this.props.settings && this.props.settings.icone === false ? "Importer mon logo" : "Modifier mon logo"}
                                    />
                                    <input id='file' type="file" ref={this.inputIconFile} style={{ display: 'none' }} onChange={this._uploadIcone} accept=".png, .jpg, .jpeg, .svg" />

                                </View>
                                <View style={{ position: 'absolute', right: 0, bottom: 20 }}>
                                    <Fab onClick={() => this.props.navigation.navigate("PersonnalisationSendinblue")}
                                        color="primary"
                                        title="Suivant"
                                    >
                                        <ArrowForwardIcon style={{ fontSize: 30, color: 'white' }} />
                                    </Fab>
                                </View>
                            </View>
                            <View
                                style={{
                                    alignSelf: 'center',
                                }}
                            >
                                <CustomButton
                                    onPress={() => this.props.navigation.navigate("PersonnalisationSendinblue")}
                                    title={"Ignorer cette étape pour l'instant"}
                                />
                            </View>

                        </div>

                    </View>

                }
                <NoNetworkNotification />

            </View >

        );
    }
}


const mapStateToProps = state => {
    return {
        language: state.setLanguage.language,
        users: state.setData.users,
        currentUser: state.setData.currentUser,
        isConnectedToNetwork: state.setConnection.isConnectedToNetwork,
        accountAccessKey: state.setAccount.accountAccessKey,
        settings: state.setAccount.settings,

    };
};


const mapDispatchToProps = dispatch => {
    return {
        dispatch: action => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonnalisationIcone);