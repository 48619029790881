import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    /*this.airbrake = new Notifier({
            projectId: 516005,
            projectKey: '0c44a5ed25cd023eeeece99d91a28f19'
        });*/
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    /* this.airbrake.notify({
            error: error,
            params: { info: info }
        });*/
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ flex: 1 }}>
          <h1>
            Une erreur est survenue. Un rapport a été envoyé au développeur.
          </h1>
          <h2>Veuillez relancer l'application.</h2>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
