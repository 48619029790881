import React from 'react';
import { View, Animated } from 'react-native';
import { connect } from 'react-redux';
import CustomText from './CustomText';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import add_to_home_screen from '../../assets/images/add_to_home_screen.png';
import { getColor } from '../../utilities/utils';

class AddToHomeScreenBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            animated: new Animated.Value(0),
            duration: 1000,
            visible: true,
        }
    }

    render() {
        return (
            <Animated.View
                style={{
                    position: 'absolute',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: getColor("color3", this.props),
                    paddingVertical: 7,
                    paddingHorizontal: 12,
                    bottom: 0,
                    width: '100%',
                    flexDirection: 'row',
                    transform: [{ translateY: this.state.animated }]
                }}>


                <View
                    style={{ flex: 6, alignItems: 'center', justifyContent: 'center' }}
                >
                    <CustomText style={{ color: 'white', fontSize: 13 }}>Installez cette application sur votre iPhone en appuyant sur  <img alt='ios menu icon' width={20} height={20} src={add_to_home_screen} />  puis "Sur l'écran d'accueil"</CustomText>
                </View>
                <View style={{ flex: 1, alignItems: 'flex-end' }}>
                    <IconButton
                        color='secondary'
                        title="Fermer"
                        onClick={() => {
                            if (this.state.visible) {
                                this.setState({ visible: false })
                                Animated.sequence([
                                    Animated.timing(this.state.animated, {
                                        toValue: 0,
                                        duration: this.state.duration,
                                        useNativeDriver: true,
                                    }),
                                    Animated.timing(this.state.animated, {
                                        toValue: 255,
                                        duration: this.state.duration,
                                        useNativeDriver: true,
                                    }),
                                ]).start();
                            }
                        }}
                        size='medium'
                    >
                        <CloseIcon
                            fontSize={'medium'}
                        />
                    </IconButton>
                </View>
            </Animated.View>
        );
    }
};


const mapStateToProps = state => {
    return {
        currentUser: state.setData.currentUser

    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch: action => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToHomeScreenBanner);
