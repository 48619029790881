import React from "react";
import { View } from "react-native";
import Header from "../Headers/Header";
import {
  abonnements,
  displayLoading,
  excelIndexToCol,
  getColor,
  getElementPreListIdByLabel,
  getList,
  getNbContactsByAbonnement,
  getNbContactsLabelByAbonnement,
  handleBack,
  isDoublon,
  setSnackbar,
} from "../../../utilities/utils";
import { connect } from "react-redux";
import {
  appBackgroundColor,
  globalStyles,
  labelColor,
  labelFontSize,
  textFontSize,
} from "../../../styles/styles";
import CustomSelect from "../../CustomComponents/CustomSelect";
import CustomText from "../../CustomComponents/CustomText";
import CustomButton from "../../CustomComponents/CustomButton";
import {
  addChampPersoToModeleContactToDatabase,
  addElementToPreListDatabase,
  bddTableName,
  dataTableName,
  setupTableName,
} from "../../../db/db";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import { db } from "../../../db/dbInit";
import FloatingLabelInput from "../../CustomComponents/FloatingLabelInput";
import DialogAlert from "../../CustomComponents/DialogAlert";
import NoNetworkNotification from "../../CustomComponents/NoNetworkNotification";
import { DEBUG } from "../../../api/constants";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

class ImportContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoading2: false,
      isLoading3: false,
      enTete: true,
      scrollEnabled: true,
      nbDataImportSuccess: 0,
      totalToProcess: 0,
      elementProccessed: 0,
      updates: {},
      updatesPreList: {},
      champsOptions: [],
      isDialogVisible: false,
      canCloseDialogVisible: true,
      isDialogVisible2: false,
      canCloseDialogVisible2: true,
      champTypeOptions: [
        { id: "Texte", label: "Texte" },
        { id: "Date", label: "Date" },
        { id: "Liste", label: "Liste" },
      ],
      champType: "Texte",
      champLabel: "",
      champLabelInfoLabel: "",
      selectedChampId: "",
      nbSlice: 1,
      currentSlice: 0,
      contactsTemp: [],
      valuePreListTemp: {},
    };
  }

  closeDialog = () => {
    this.setState({
      isDialogVisible: false,
      [this.state.selectedColumn]: "",
      champLabel: "",
      champLabelInfoLabel: "",
      champType: "Texte",
    });
  };

  closeDialog2 = () => {
    this.setState({ isDialogVisible2: false });
  };

  componentDidMount() {
    this.unsubscribe = this.props.navigation.addListener("blur", () => {
      window.removeEventListener("popstate", this.listener);
    });

    this.unsubscribe2 = this.props.navigation.addListener("focus", () => {
      setTimeout(() => {
        window.addEventListener(
          "popstate",
          (this.listener = (event) => handleBack(event, this))
        );
      }, 1);
    });

    setTimeout(() => {
      let preListOptions = [];

      this.props.settings.modeleContact.forEach((ch) => {
        if (ch.type === "Liste") {
          preListOptions.push({
            id: ch.id,
            list: getList(ch.id, this.props),
            valuePreListTemp: [],
          });
        }
      });

      let excelDatas =
        this.props.excelDatas !== undefined
          ? this.props.excelDatas
          : this.props.route.params.excelDatas;
      const enTete =
        this.props.enTete !== undefined
          ? this.props.enTete
          : this.props.route.params.enTete;

      let champsOptions = [
        { id: "Ne pas importer", initialLabel: "", label: "Ne pas importer" },
        {
          id: "Créer un nouveau champ",
          initialLabel: "",
          label: "Créer un nouveau champ",
        },
      ];
      this.props.settings.modeleContact.forEach((ch) => {
        champsOptions.push({
          id: ch.id,
          initialLabel: ch.label,
          label:
            ch.label +
            " - " +
            (ch.custom
              ? "Personnalisé (" + ch.type + ")"
              : "Obligatoire (" + ch.type + ")"),
          category: "Importer sous un nom existant",
        });
      });

      let columnNames = [];
      excelDatas[0].forEach((column, index) => {
        let name = column !== "" && enTete ? column : "Champ " + (index + 1);

        let matchName = champsOptions.find(
          (ch) =>
            ch.initialLabel
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toUpperCase() ===
            name
              .trim()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toUpperCase()
        );

        if (matchName !== undefined) {
          this.setState({
            [name]: matchName.id,
            ["champsOptionsMatchName" + name]: [
              {
                id: "Ne pas importer",
                initialLabel: "",
                label: "Ne pas importer",
              },
              matchName,
            ],
          });
        }

        columnNames.push({
          id: name,
          name: name,
          index: index,
          matchName: matchName !== undefined,
        });
      });

      const champs = Object.values(
        JSON.parse(JSON.stringify(this.props.settings.modeleContact))
      );
      const nbSlice = Math.ceil(excelDatas.length - (enTete ? 1 : 0) / 1000);

      this.setState({
        champs: champs,
        enTete: enTete,
        columnNames: columnNames,
        champsOptions: champsOptions,
        datas: enTete ? excelDatas.slice(1) : excelDatas,
        preListOptions: preListOptions,
        totalToProcess: excelDatas.length - (enTete ? 1 : 0),
        isDialogVisible2:
          this.props.abonnement ===
          abonnements.find((abo) => abo.label === "subscriber").label,
        nbSlice: nbSlice,
        isLoading: false,
      });
    }, 100);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.settings.modeleContact !== this.props.settings.modeleContact
    ) {
      this._reloadChamps();
    }

    if (
      this.state.elementProccessed !== prevState.elementProccessed &&
      this.state.totalToProcess === this.state.elementProccessed
    ) {
      const action = { type: "DATA_IS_IMPORTING", value: false };
      this.props.dispatch(action);

      if (!this.props.isConnectedToNetwork) {
        this._reload();
        setSnackbar("error", "Erreur réseau", this.props);
        this.setState({ isLoading2: false });
        return;
      }

      db.ref()
        .update(this.state.updatesPreList)
        .then(() => {
          db.ref()
            .update(this.state.updates)
            .then(() => {
              if (this.state.nbDataImportSuccess === 0) {
                setSnackbar("warning", "Aucun contact importé", this.props);
              } else {
                setSnackbar(
                  "success",
                  this.state.nbDataImportSuccess +
                    " contact(s) créé(s) avec succès",
                  this.props
                );
              }
              this.props.navigation.goBack();
            })
            .catch((error) => {
              this._reload();
              if (DEBUG) console.warn(error);
              setSnackbar(
                "error",
                "Une erreur est survenue. Veuillez réessayer plus tard",
                this.props
              );
              this.setState({ isLoading2: false });
            });
        })
        .catch((err) => {
          this._reload();
          if (DEBUG) console.warn(err);
          setSnackbar(
            "error",
            "Une erreur est survenue. Veuillez réessayer plus tard",
            this.props
          );
          this.setState({ isLoading2: false });
        });
    } else if (
      this.state.elementProccessed !== prevState.elementProccessed &&
      this.state.nbSlice > 1 &&
      this.state.elementProccessed === (this.state.currentSlice + 1) * 500
    ) {
      this.setState({ currentSlice: this.state.currentSlice + 1 }, () => {
        this._import();
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.listener);

    if (this.catchError) clearTimeout(this.catchError);
    if (this.unsubscribe) this.unsubscribe();
    if (this.unsubscribe2) this.unsubscribe2();
  }

  _reloadChamps() {
    let preListOptions = [];

    this.props.settings.modeleContact.forEach((ch) => {
      if (ch.type === "Liste") {
        preListOptions.push({
          id: ch.id,
          list: getList(ch.id, this.props),
          valuePreListTemp: [],
        });
      }
    });

    let champsOptions = [
      { id: "Ne pas importer", initialLabel: "", label: "Ne pas importer" },
      {
        id: "Créer un nouveau champ",
        initialLabel: "",
        label: "Créer un nouveau champ",
      },
    ];
    this.props.settings.modeleContact.forEach((ch) => {
      champsOptions.push({
        id: ch.id,
        initialLabel: ch.label,
        label:
          ch.label +
          " - " +
          (ch.custom
            ? "Personnalisé (" + ch.type + ")"
            : "Obligatoire (" + ch.type + ")"),
        category: "Importer sous un nom existant",
      });
    });

    let champs = Object.values(
      JSON.parse(JSON.stringify(this.props.settings.modeleContact))
    );

    this.setState({
      champs: champs,
      champsOptions: champsOptions,
      preListOptions: preListOptions,
    });
  }

  _addChamp = () => {
    if (this.state.isLoading3) {
      return;
    }

    this.setState({ isLoading3: true, champLabelInfoLabel: "" });

    if (this.state.champLabel === "") {
      this.setState({
        champLabelInfoLabel: "Paramètre obligatoire",
        isLoading3: false,
      });
      return;
    }

    if (
      this.state.champs
        .map((ch) => ch.label)
        .includes(this.state.champLabel.trim())
    ) {
      this.setState({
        champLabelInfoLabel: "Ce champ existe déjà",
        isLoading3: false,
      });
      return;
    }

    let champ = {};
    champ["type"] = this.state.champType;
    champ["label"] = this.state.champLabel.trim();
    champ["value"] = "";
    champ["order"] = Math.max.apply(
      Math,
      this.state.champs.map(function (o) {
        return o.order + 1;
      })
    );
    champ["visible"] = true;
    champ["custom"] = true;

    addChampPersoToModeleContactToDatabase(champ, this.props)
      .then((key) => {
        if (champ["type"] === "Liste") {
          addElementToPreListDatabase("", key, this.props).then(() => {});
        }

        setTimeout(() => {
          this.setState({
            [this.state.selectedColumn]: key,
            [this.state.selectedColumn + "Reload"]: new Date(),
          });
        }, 100);

        setSnackbar("success", "Champ ajouté avec succès", this.props);
      })
      .catch((error) => {
        setSnackbar(
          "error",
          error === "No network"
            ? "Erreur réseau"
            : "Une erreur est survenue. Veuillez réessayer plus tard",
          this.props
        );
        if (DEBUG) console.warn(error);
      })
      .finally(() => {
        this.setState(
          {
            isDialogVisible: false,
            champLabel: "",
            champLabelInfoLabel: "",
            champType: "Texte",
          },
          () => {
            setTimeout(() => {
              this.setState({ isLoading3: false });
            }, 100);
          }
        );
      });
  };

  _reload = () => {
    let preListOptions = [];

    this.props.settings.modeleContact.forEach((ch) => {
      if (ch.type === "Liste")
        preListOptions.push({
          id: ch.id,
          perso: true,
          list: getList(ch.id, this.props),
          valuePreListTemp: [],
        });
    });

    let excelDatas = this.props.excelDatas
      ? this.props.excelDatas
      : this.props.route.params.excelDatas;

    let enTete =
      this.props.enTete !== undefined
        ? this.props.enTete
        : this.props.route.params.enTete;

    this.setState({
      datas: excelDatas,
      preListOptions: preListOptions,
      totalToProcess: excelDatas.length,
      enTete: enTete,
      scrollEnabled: true,
      nbDataImportSuccess: 0,
      elementProccessed: 0,
      updates: {},
      updatesPreList: {},
      isLoading: false,
    });
  };

  _checkIfValueExistInPreList(list, valuePreListTemp, value) {
    return (
      value === "" ||
      list.find((opt) => opt.label === value) !== undefined ||
      valuePreListTemp.includes(value)
    );
  }

  _startImport() {
    if (this.state.isLoading2) {
      return;
    }

    let err = false;

    this.state.columnNames.forEach((column) => {
      if (this.state[column.id] === undefined || this.state[column.id] === "") {
        this.setState({ [column.id + "InfoLabel"]: "Paramètre obligatoire" });
        err = true;
      } else {
        this.setState({ [column.id + "InfoLabel"]: "" });
      }
    });

    if (err === true) {
      setSnackbar(
        "warning",
        "Veuillez remplir tous les champs avant de valider !",
        this.props
      );
      this.setState({ isLoading2: false });
      return;
    }

    /* if (this.catchError) clearTimeout(this.catchError);

    this.catchError = setTimeout(() => {
      if (this.props.dataIsImporting) {
        let action = { type: "DATA_IS_IMPORTING", value: false };
        this.props.dispatch(action);
        this._reload();
        setSnackbar(
          "error",
          "Une erreur est survenue. Veuillez réessayer plus tard",
          this.props
        );
        this.setState({ isLoading2: false });
      }
    }, 120 * this.state.totalToProcess);*/

    let action = { type: "DATA_IS_IMPORTING", value: true };
    this.props.dispatch(action);

    let valuePreListTemp = {};

    this.state.preListOptions.forEach((preList) => {
      valuePreListTemp[preList.id] = [];
    });

    this.setState({
      valuePreListTemp: valuePreListTemp,
      isLoading2: true,
      isDialogVisible: false,
    });

    this._import();
  }

  _import() {
    let datas = this.state.datas.slice(
      this.state.currentSlice * 500,
      (this.state.currentSlice + 1) * 500
    );

    let updates = JSON.parse(JSON.stringify(this.state.updates));
    let updatesPreList = JSON.parse(JSON.stringify(this.state.updatesPreList));
    let newPostKey;

    datas.forEach((line, index) => {
      setTimeout(() => {
        if (
          this.props.abonnement ===
            abonnements.find((abo) => abo.label === "subscriber").label &&
          this.props.data.length + this.state.nbDataImportSuccess >=
            getNbContactsByAbonnement(this.props.abonnement)
        ) {
          this.setState((prevState) => ({
            elementProccessed: prevState.elementProccessed + 1,
          }));
          return;
        }
        let contactU = {};
        let empty = true;

        this.props.settings.modeleContact.forEach((ch) => {
          let col = this.state.columnNames.find(
            (column) => this.state[column.id] === ch.id
          );
          let ind = col === undefined ? -1 : col.index;

          let href = "";

          if (ind !== -1) {
            const coords =
              'id="sjs-' +
              excelIndexToCol(ind + 1) +
              (this.state.enTete ? index + 2 : index + 1) +
              '"><a href="';

            const indexStr1 = this.props.ws2.indexOf(coords);
            if (indexStr1 !== -1) {
              const indexStr2 = this.props.ws2.indexOf(
                '"',
                indexStr1 + coords.length
              );
              href = this.props.ws2.substring(
                indexStr1 + coords.length,
                indexStr2
              );
            }
          }

          let val = line[ind] !== undefined ? line[ind] : "";

          contactU[ch.id] = href !== "" ? val + " (lien : " + href + ")" : val;
          if (val !== "") {
            empty = false;
          }
        });

        if (empty) {
          this.setState((prevState) => ({
            elementProccessed: prevState.elementProccessed + 1,
          }));
          return;
        }

        contactU["Date de création"] = moment(
          contactU["Date de création"],
          "MM/DD/YY"
        ).isValid()
          ? moment(contactU["Date de création"], "MM/DD/YY").format(
              "DD/MM/YYYY HH:mm"
            )
          : "";
        contactU["Date de relance"] = moment(
          contactU["Date de relance"],
          "MM/DD/YY"
        ).isValid()
          ? moment(contactU["Date de relance"], "MM/DD/YY").format(
              "DD/MM/YYYY HH:mm"
            )
          : "";

        if (
          !isDoublon(contactU, this.props.data.concat(this.state.contactsTemp))
        ) {
          this.setState((prevState) => ({
            contactsTemp: prevState.contactsTemp.concat(contactU),
          }));

          this.state.preListOptions.forEach((preList, index2, array2) => {
            let val = preList.perso
              ? contactU[preList.id]
              : contactU[preList.id];

            if (preList.id === "Statut") {
              if (
                !this._checkIfValueExistInPreList(
                  preList.list,
                  this.state.valuePreListTemp[preList.id],
                  contactU["Statut"]
                )
              ) {
                this.state.valuePreListTemp["Statut"].push(contactU["Statut"]);
                newPostKey = db
                  .ref(
                    `${bddTableName}/${this.props.accountAccessKey}/${setupTableName}/Statut`
                  )
                  .push().key;
                updatesPreList[
                  `${bddTableName}/${this.props.accountAccessKey}/${setupTableName}/Statut/${newPostKey}`
                ] = contactU["Statut"];
                contactU["Statut"] = newPostKey;
              } else {
                contactU["Statut"] = getElementPreListIdByLabel(
                  preList.list,
                  this.state.valuePreListTemp[preList.id],
                  contactU["Statut"]
                );
              }
            } else if (preList.id === "Responsable") {
              contactU["Responsable"] =
                contactU["Responsable"] !== "" &&
                this.props.users.find(
                  (user) => user.name === contactU["Responsable"]
                ) !== undefined
                  ? this.props.users.find(
                      (user) => user.name === contactU["Responsable"]
                    ).id
                  : contactU["Responsable"];
              contactU["Responsable"] =
                contactU["Responsable"] !== "" &&
                this.props.users.find(
                  (user) => user.identifiant === contactU["Responsable"]
                ) !== undefined
                  ? this.props.users.find(
                      (user) => user.identifiant === contactU["Responsable"]
                    ).id
                  : contactU["Responsable"];
            } else {
              if (
                !this._checkIfValueExistInPreList(
                  preList.list,
                  preList.valuePreListTemp,
                  val
                )
              ) {
                preList.valuePreListTemp.push(val);

                newPostKey = db
                  .ref(
                    `${bddTableName}/${this.props.accountAccessKey}/${setupTableName}/${preList.id}`
                  )
                  .push().key;
                updatesPreList[
                  `${bddTableName}/${this.props.accountAccessKey}/${setupTableName}/${preList.id}/${newPostKey}`
                ] = val;

                updatesPreList[
                  bddTableName +
                    "/" +
                    this.props.accountAccessKey +
                    "/" +
                    setupTableName +
                    "/" +
                    preList.id +
                    "/" +
                    newPostKey
                ] = val;

                contactU[preList.id] = newPostKey;
              } else {
                contactU[preList.id] = getElementPreListIdByLabel(
                  preList.list,
                  this.state.valuePreListTemp[preList.id],
                  contactU[preList.id]
                );
              }
            }
          });

          newPostKey = db
            .ref(
              bddTableName +
                "/" +
                this.props.accountAccessKey +
                "/" +
                dataTableName
            )
            .push().key;

          updates[
            bddTableName +
              "/" +
              this.props.accountAccessKey +
              "/" +
              dataTableName +
              "/" +
              newPostKey
          ] = contactU;

          this.setState(
            (prevState) => ({
              updates: updates,
              updatesPreList: updatesPreList,
            }),
            () => {
              this.setState((prevState) => ({
                nbDataImportSuccess: prevState.nbDataImportSuccess + 1,
                elementProccessed: prevState.elementProccessed + 1,
              }));
            }
          );
        } else {
          this.setState((prevState) => ({
            elementProccessed: prevState.elementProccessed + 1,
          }));
        }
      }, 100);
    });
  }

  _changeScrolling = (enabled) => {
    this.setState((prevState) => ({
      scrollEnabled: enabled === undefined ? !prevState.scrollEnabled : enabled,
    }));
  };

  render() {
    let i = -1;
    return (
      <View style={globalStyles.main_container}>
        <Header navigation={this.props.navigation}>
          {this.props.headerTitle
            ? this.props.headerTitle
            : "import_contacts_title"}
        </Header>
        {this.state.isLoading2 ? (
          <View style={globalStyles.loading_container}>
            <Paper
              elevation={2}
              style={{
                width: "80%",
                display: "flex",
                flexDirection: "column",
                padding: 12,
                paddingTop: 20,
                paddingBottom: 20,
                backgroundColor: appBackgroundColor,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ProgressBar
                style={{ width: "100%", height: 20, marginBottom: 12 }}
                animated
                color={getColor("color2", this.props)}
                now={Math.round(
                  (this.state.elementProccessed / this.state.totalToProcess) *
                    100
                )}
              />
              <CustomText>
                {"Importation en cours... (" +
                  this.state.elementProccessed +
                  "/" +
                  this.state.totalToProcess +
                  ")"}
              </CustomText>
            </Paper>
          </View>
        ) : (
          <></>
        )}

        <Dialog
          onBackdropClick={() => this.closeDialog()}
          open={this.state.isDialogVisible}
          onClose={() => {
            this.closeDialog();
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ fontFamily: "Quicksand-Regular" }}
        >
          <DialogTitle style={{ fontFamily: "Quicksand-Bold" }}>
            Ajouter un nouveau champ
          </DialogTitle>
          <DialogContent style={{ fontFamily: "Quicksand-Bold" }}>
            <View style={{ paddingBottom: 12, fontSize: textFontSize - 3 }}>
              <CustomText style={{ fontSize: textFontSize - 3 }}>
                Ce nouveau champ sera ajouté à tous vos contacts !
              </CustomText>
            </View>
            <CustomSelect
              label={"Type"}
              value={this.state.champType}
              onChange={(value) => this.setState({ champType: value.id })}
              options={this.state.champTypeOptions}
            />
            <FloatingLabelInput
              label="Nom du champ"
              onChange={(value) => this.setState({ champLabel: value })}
              value={this.state.champLabel}
              helperText={this.state.champLabelInfoLabel}
              maxLength={30}
            />
          </DialogContent>
          <DialogActions>
            <Button
              style={{ fontFamily: "Quicksand-Bold" }}
              onClick={() => {
                this.closeDialog();
              }}
              color="primary"
            >
              Annuler
            </Button>
            <Button
              style={{ fontFamily: "Quicksand-Bold" }}
              onClick={() => this._addChamp()}
              color="primary"
            >
              Valider
            </Button>
          </DialogActions>
        </Dialog>

        <DialogAlert
          onClose={() => this.closeDialog2()}
          isDialogVisible={this.state.isDialogVisible2}
          title={"Nombre de contacts limité"}
          text={
            "Dans le cadre de votre abonnement, vous avez la possibilité de créer " +
            getNbContactsLabelByAbonnement(this.props.abonnement) +
            " contacts tout au plus. Seules les premières lignes de votre fichier seront importées dans la limite autorisée par votre abonnement."
          }
          onlyOkButton={true}
          cancel={() => {
            this.closeDialog2();
          }}
        />
        {this.state.isLoading ? (
          displayLoading(this.state.isLoading)
        ) : (
          <View style={globalStyles.list_container}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5%",
                opacity: this.state.isLoading2 ? 0.5 : 1,
                overflowY: "scroll",
                scrollbarWidth: "none",
                paddingBottom: this.props.fab ? 80 : "5%",
              }}
            >
              {this.props.children}
              <View style={{ flexDirection: "row" }}>
                <CustomText style={{}}>
                  Choisissez les champs qui correspondent à vos données. Vous
                  avez 3 possibilités
                  <ArrowDropDownIcon
                    fontSize={"medium"}
                    style={{
                      marginBottom: 7,
                      textAlign: "center",
                      alignSelf: "center",
                      justifyContent: "center",
                      color: labelColor,
                    }}
                  />
                </CustomText>
              </View>
              <CustomText
                textType="bold"
                style={{ paddingBottom: 10, fontSize: labelFontSize + 2 }}
              >
                <WarningAmberIcon
                  color="warning"
                  fontSize={"medium"}
                  style={{
                    marginBottom: 7,
                    marginRight: 5,
                    textAlign: "center",
                    alignSelf: "center",
                    justifyContent: "center",
                  }}
                />
                Vous devez choisir en priorité les champs obligatoires !
              </CustomText>

              <CustomText
                style={{ paddingVertical: 10, fontSize: labelFontSize }}
              >
                Pour les champs de type Date, le format attendu est le suivant :
                JJ/MM/AAAA.
              </CustomText>

              <CustomText
                textType="bold"
                style={{ paddingVertical: 10, fontSize: labelFontSize }}
              >
                Votre fichier contient {this.state.datas.length} lignes et{" "}
                {this.state.datas[0].length} colonnes.
              </CustomText>

              <View
                style={{
                  height: 1,
                  width: "100%",
                  backgroundColor: "#DBDBDB",
                  marginBottom: 10,
                  marginTop: 5,
                  alignSelf: "center",
                }}
              />

              {this.state.columnNames.map((column) => {
                i++;
                return (
                  <View>
                    <CustomText>
                      <b>En-tête du fichier</b> : "{column.name}"
                    </CustomText>
                    {this.state.datas[1] &&
                    this.state.datas[2] &&
                    this.state.datas[3] &&
                    this.state.datas[1][i] +
                      this.state.datas[2][i] +
                      this.state.datas[3][i] !==
                      "" ? (
                      <CustomText numberOfLines={3}>
                        <b>Exemple de données</b> :{" "}
                        <i>
                          {this.state.datas[1][i]}
                          {this.state.datas[1][i] !== "" &&
                          this.state.datas[2][i] !== ""
                            ? ", " + this.state.datas[2][i]
                            : this.state.datas[2][i]}
                          {(this.state.datas[2][i] !== "" &&
                            this.state.datas[3][i] !== "") ||
                          (this.state.datas[2][i] === "" &&
                            this.state.datas[1][i] !== "" &&
                            this.state.datas[3][i] !== "")
                            ? ", " + this.state.datas[3][i]
                            : this.state.datas[3][i]}
                        </i>
                      </CustomText>
                    ) : (
                      <></>
                    )}
                    <View style={{ marginTop: 5 }}>
                      <CustomSelect
                        borderColor={
                          this.state[column.id] !== undefined &&
                          this.state[column.id] !== ""
                            ? "green"
                            : "orange"
                        }
                        key={this.state[column.id + "Reload"]}
                        setRef={(ref) => (this[column.id + "Ref"] = ref)}
                        changeScrolling={this._changeScrolling}
                        label={column.name}
                        value={this.state[column.id]}
                        onChange={(value) => {
                          if (value.id === "Créer un nouveau champ") {
                            this.setState(
                              {
                                [column.id]: value.id,
                                selectedColumn: column.id,
                                champLabel: column.name,
                                isDialogVisible: true,
                              },
                              () => {
                                this[this.state.selectedColumn + "Ref"].current
                                  .getElementsByClassName(
                                    "MuiAutocomplete-clearIndicator"
                                  )[0]
                                  .click();
                              }
                            );
                          } else {
                            this.setState({ [column.id]: value.id });
                          }
                        }}
                        options={
                          column.matchName
                            ? this.state["champsOptionsMatchName" + column.name]
                            : this.state.champsOptions
                        }
                        isClearable
                        isSearchable
                        importContact
                        helperText={this.state[column.id + "InfoLabel"]}
                      />
                    </View>
                    {i + 1 === this.state.columnNames.length ? (
                      <></>
                    ) : (
                      <View
                        style={{
                          height: 1,
                          width: "100%",
                          backgroundColor: "#DBDBDB",
                          marginBottom: 10,
                          marginTop: 5,
                          alignSelf: "center",
                        }}
                      />
                    )}
                  </View>
                );
              })}
              <View
                style={{
                  marginTop: 15,
                  alignSelf: "center",
                }}
              >
                <CustomButton
                  title={"Importer"}
                  onPress={() => this._startImport()}
                />
              </View>
            </div>
          </View>
        )}
        {this.props.fab}
        {this.props.noNetworkNotification ? <NoNetworkNotification /> : <></>}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.setData.data,
    users: state.setData["users"],
    currentUser: state.setData["currentUser"],
    isConnectedToNetwork: state.setConnection.isConnectedToNetwork,
    settings: state.setAccount.settings,
    lists: state.setData.lists,
    accountAccessKey: state.setAccount.accountAccessKey,
    dataIsImporting: state.setData.dataIsImporting,
    abonnement: state.setAccount.abonnement,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportContact);
