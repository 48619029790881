import React, { createRef } from 'react';
import {
    View,
} from 'react-native';
import CustomText from '../../CustomComponents/CustomText';
import CustomButton from '../../CustomComponents/CustomButton';
import Header from '../Headers/Header';
import { connect } from 'react-redux';
import { displayLoading, handleBack, setSnackbar } from '../../../utilities/utils';
import { Fab } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import * as XLSX from "xlsx";
import { globalStyles } from '../../../styles/styles';
import CustomTitle from '../../CustomComponents/CustomTitle';
import DialogAlert from '../../CustomComponents/DialogAlert';
import NoNetworkNotification from '../../CustomComponents/NoNetworkNotification';

class PersonnalisationImportContact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isLoading2: false,
            excelDatas: [],
            isDialogVisible: false,
            canCloseDialogVisible: false
        };
        this.inputContactsFile = createRef(null)
    }

    componentDidMount() {
        this.unsubscribe = this.props.navigation.addListener('blur', () => {
            window.removeEventListener('popstate', this.listener)
        })

        this.unsubscribe2 = this.props.navigation.addListener('focus', () => {
            setTimeout(() => {
                window.addEventListener('popstate', this.listener = (event) => handleBack(event, this));
            }, 1)
        })

        this._initXlsxReader()
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.listener)

        if (this.unsubscribe)
            this.unsubscribe()
        if (this.unsubscribe2)
            this.unsubscribe2()
    }


    _importContacts = event => {
        if (this.state.isLoading2) {
            return
        }
        this.setState({ isLoading2: true })

        if (!(event && event.target && event.target.files && event.target.files[0] && event.target.files[0].type)) {
            setSnackbar('error', "Une erreur est survenue. Veuillez réessayer ultérieurement", this.props)

            this.setState({ isLoading2: false })
            return
        }

        if (event.target.files[0].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            setSnackbar('error', "Fichier incorrect. Veuillez choisir un fichier Excel", this.props)
            this.setState({ isLoading2: false })
            return
        }

        let file = event.target.files[0];
        this.reader.readAsBinaryString(file);
        document.getElementById('file2').value = ''
        this.setState({ isLoading2: false })

    };

    _initXlsxReader() {
        this.reader = new FileReader();

        this.reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary", cellDates: true });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { defval: "", header: 1, blankrows: false, dateNF: 'dd/mm/yyyy', raw: false });

            document.getElementById('file2').value = ''
            this.setState({ isLoading2: false })
            if (data.length > 0) {
                this.setState({ excelDatas: data, isDialogVisible: true })
            } else {
                setSnackbar('warning', "Le fichier que vous avez sélectionné ne contient pas de données", this.props)
            }
        };
    }


    render() {
        return (
            <View style={{ flex: 1 }}>
                <Header
                    navigation={this.props.navigation}
                >
                </Header>
                <DialogAlert
                    noBackDrop={true}
                    isDialogVisible={this.state.isDialogVisible}
                    onClose={() => {
                    }}
                    title={'En-tête de colonne'}
                    text={
                        "Votre fichier Excel contient-il des noms de colonnes ? Si vous répondez oui, l'importation de votre fichier commencera à la ligne 2."
                    }
                    validText="Oui"
                    cancelText="Non"
                    confirm={() => {
                        this.setState({ isDialogVisible: false });
                        if (this.state.excelDatas.length === 1) {
                            setSnackbar('warning', "Le fichier que vous avez sélectionné ne contient pas de données", this.props)
                        } else {
                            this.props.navigation.navigate('PersonnalisationImportContact2', { excelDatas: this.state.excelDatas, enTete: true })
                        }
                    }}
                    cancel={() => {
                        this.setState({ isDialogVisible: false });
                        this.props.navigation.navigate('PersonnalisationImportContact2', { excelDatas: this.state.excelDatas, enTete: false })
                    }}
                />
                {displayLoading(this.state.isLoading2)}
                {this.state.isLoading ? displayLoading(this.state.isLoading) :
                    <View
                        style={[{
                            paddingVertical: 20,
                            flex: 1,
                            opacity: this.state.isLoading2 ? 0.5 : 1,
                        }, globalStyles.list_container]}>
                        <div
                            style={{
                                paddingLeft: '7%',
                                paddingRight: '7%',
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1
                            }}>
                            <View style={{ flex: 1 }}>

                                <View style={{ paddingBottom: 20, justifyContent: 'center', alignItems: 'center' }}>
                                    <CustomTitle style={{ fontSize: 25, textAlign: 'center' }}>
                                        Personnalisez votre Tankō
                                    </CustomTitle>
                                </View>
                                <View style={{ paddingVertical: 10 }}>
                                    <CustomText>Importez vos contacts existants à partir d'un fichier Excel.</CustomText>
                                </View>
                                <View style={{ paddingVertical: 10 }}>
                                    <CustomText>Vous pourrez importer vos contacts plus tard dans les paramètres.</CustomText>
                                </View>
                                <View
                                    style={{
                                        paddingTop: 30,
                                        alignSelf: 'center',
                                    }}
                                >
                                    <CustomButton
                                        title={"Importer mes contacts"}
                                        onPress={() => this.inputContactsFile.current.click()}
                                    />
                                    <input id='file2' type="file" ref={this.inputContactsFile} style={{ display: 'none' }} onChange={this._importContacts} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                </View>
                                <View style={{ position: 'absolute', right: 0, bottom: 20 }}>
                                    <Fab onClick={() => this.props.navigation.replace("Contacts")}
                                        color="primary"
                                        title="Suivant"
                                    >
                                        <ArrowForwardIcon style={{ fontSize: 30, color: 'white' }} />
                                    </Fab>
                                </View>
                            </View>
                            <View
                                style={{
                                    alignSelf: 'center',
                                }}
                            >
                                <CustomButton
                                    onPress={() => this.props.navigation.replace("Contacts")}
                                    title={"Ignorer cette étape pour l'instant"}
                                />
                            </View>
                        </div>
                    </View>}
                <NoNetworkNotification />

            </View >


        );
    }
}


const mapStateToProps = state => {
    return {
        language: state.setLanguage.language,
        users: state.setData.users,
        currentUser: state.setData.currentUser,
        isConnectedToNetwork: state.setConnection.isConnectedToNetwork,
        settings: state.setAccount.settings,

    };
};


const mapDispatchToProps = dispatch => {
    return {
        dispatch: action => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonnalisationImportContact);