import React from "react";
import { View, TouchableOpacity } from "react-native";
import { FiChevronRight } from "react-icons/fi";
import { Checkbox, Paper } from "@mui/material";
import IconLetter from "./IconLetter";
import { iconSize } from "../../styles/styles";

class CustomItemList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  /* shouldComponentUpdate(nextProps, nextState) {
         return nextProps.equalTest !== this.props.equalTest
     }*/

  _checkItem = () => {
    this.props.checkItem();
    this.forceUpdate();
  };

  render() {
    return (
      <View style={{ paddingHorizontal: 15, paddingVertical: 3, height: 70 }}>
        <Paper
          elevation={3}
          style={{ borderRadius: 10, width: "100%", height: "100%" }}
        >
          <TouchableOpacity
            style={{
              flex: 1,
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              paddingHorizontal: 12,
              paddingVertical: 12,
              flexDirection: "row",
              paddingLeft: this.props.deleteMode ? 0 : 12,
            }}
            onPress={
              this.props.deleteMode
                ? () => this._checkItem()
                : this.props.onPress
            }
          >
            <View
              style={{ flex: 6, flexDirection: "row", alignItems: "center" }}
            >
              {this.props.deleteMode ? (
                <Checkbox
                  checked={
                    this.props.isChecked ? this.props.isChecked() : false
                  }
                />
              ) : (
                <></>
              )}
              {this.props.letters !== undefined ? (
                <View style={{ flex: 1 }}>
                  <IconLetter letters={this.props.letters} />
                </View>
              ) : (
                <></>
              )}
              <View style={{ flex: 4 }}>{this.props.content}</View>
            </View>
            <View style={{ flex: 1, alignItems: "flex-end" }}>
              <FiChevronRight style={{ fontSize: iconSize, color: "grey" }} />
            </View>
          </TouchableOpacity>
        </Paper>
      </View>
    );
  }
}

export default CustomItemList;
