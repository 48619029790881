import React, { createRef } from "react";
import { connect } from "react-redux";
import ReactExport from "react-export-excel-xlsx-fix";
import moment from 'moment';
import { getElementPreListLabelById, getList } from "../../../utilities/utils";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class ExportToExcel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datas: [],
            isLoading: true
        }
        this.exportButton = createRef(null)

    };

    componentDidMount() {
        this._setContact()
    }


    _setContact() {
        let datas = []

        let champs = Object.values(JSON.parse(JSON.stringify(this.props.settings.modeleContact)))

        let dataModele = JSON.parse(JSON.stringify(this.props.contacts ? this.props.contacts : this.props.data))

        dataModele.forEach(data => {
            let keys = Object.keys(data)
            keys.forEach(key => {
                if (key !== 'id') {
                    let champ = champs.find(ch => ch.id === key);
                    if (champ === undefined) {
                        return
                    }

                    if (key === 'Responsable') {
                        let resp = this.props.users.find(user => user.id === data[key]);
                        if (resp !== undefined) {
                            data[key] = resp.name !== undefined ? resp.name : resp.identifiant
                        }
                    } else if (key === 'Date de création' || key === "Date de relance") {
                        data[key] = data[key] !== "" ? moment(data[key]).format('DD/MM/YYYY HH:mm') : ""
                    } else if (champ.type === 'Liste') {
                        data[key] = getElementPreListLabelById(getList(key, this.props), data[key])
                    }
                }
            })
            datas.push(data)
        })

        this.setState({ datas: datas, isLoading: false }, () => {
            this.exportButton.current.click();
            this.props.disableExportContact()
        })
    }


    render() {
        return (
            this.state.isLoading ? <></> : <div>
                <ExcelFile filename={"Contacts_Export_" + moment().format("DD_MM_YYYY")} element={
                    <div hidden ref={this.exportButton}></div>
                }>
                    <ExcelSheet data={this.state.datas} name="Contacts">
                        {this.props.settings.modeleContact.sort(function (a, b) {
                            if (a.order < b.order) {
                                return -1;
                            }
                            if (a.order > b.order) {
                                return 1;
                            }
                            return 0;
                        }).map(champ => {
                            return <ExcelColumn key={champ.id} label={champ.label} value={champ.id} />
                        })}
                    </ExcelSheet>
                </ExcelFile>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.setData.data,
        settings: state.setAccount.settings,
        lists: state.setData.lists,
        users: state.setData['users'],

    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch: action => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportToExcel);

