import React from "react";
import { View, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import Header from "../Headers/Header";
import CustomText from "../../CustomComponents/CustomText";
import CustomSelect from "../../CustomComponents/CustomSelect";
import {
  displayLoading,
  getAllResponsables,
  getContactById,
  getElementPreListLabelById,
  getList,
  handleBack,
  sendMail,
  sendMessage,
  setSnackbar,
  userIsGestionnaire,
} from "../../../utilities/utils";
import {
  globalStyles,
  labelColor,
  labelFontSize,
  textFontSize,
} from "../../../styles/styles";
import DialogAlert from "../../CustomComponents/DialogAlert";
import {
  addChampPersoToModeleContactToDatabase,
  addElementToPreListDatabase,
  deleteDataToDatabase,
  removeChampPersoToAllDataToDatabase,
  removeChampPersoToModeleContactToDatabase,
  removePreListDatabase,
  updateChampPersoToModeleContactToDatabase,
  updateDataToDatabase,
} from "../../../db/db";
import CustomButton from "../../CustomComponents/CustomButton";
import { IconButton, TextField } from "@mui/material";
import CustomDatePicker from "../../CustomComponents/CustomDatePicker";
import CustomTitle from "../../CustomComponents/CustomTitle";
import moment from "moment";
import SmsIcon from "@mui/icons-material/Sms";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DraggableList from "react-draggable-list";
import cx from "classnames";
import FloatingLabelInput from "../../CustomComponents/FloatingLabelInput";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { DEBUG } from "../../../api/constants";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

class ChampItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.item.value,
      infoLabel: "",
    };
  }

  render() {
    const { item, itemSelected, dragHandleProps } = this.props;
    const scale = itemSelected * 0.05 + 1;
    const dragged = itemSelected !== -1;

    return (
      <div
        className={cx("item", { dragged })}
        style={{
          transform: `scale(${scale})`,
        }}
      >
        <div
          className={
            item.tooManyContactMode || item.currentUserType === "read"
              ? ""
              : "dragHandle"
          }
          {...dragHandleProps}
          onTouchStart={(e) => {
            dragHandleProps.onTouchStart(e);
            item.changeScrolling(false);
          }}
          onTouchEnd={() => {
            item.changeScrolling(true);
          }}
        />
        <View style={{ flexDirection: "row" }}>
          <View style={{ flex: 1 }}>
            {item.type === "Texte" ? (
              <FloatingLabelInput
                noPadding
                label={item.label}
                onChange={(value) => {
                  this.setState({ value: value });
                  item.changeChampValue(value, item.id);
                }}
                value={this.state.value}
                disabled={
                  item.disabled ||
                  item.tooManyContactMode ||
                  item.currentUserType === "read"
                }
              />
            ) : item.type === "Date" ? (
              <CustomDatePicker
                noPadding
                label={item.label}
                value={this.state.value}
                onChange={(value) => {
                  this.setState({ value: value });
                  item.changeChampValue(value, item.id);
                }}
                setErrorLabel={(errorLabel) =>
                  this.setState({ infoLabel: errorLabel })
                }
                errorLabel={this.state.infoLabel}
                disabled={
                  item.disabled ||
                  item.tooManyContactMode ||
                  item.currentUserType === "read"
                }
              />
            ) : (
              <CustomSelect
                changeScrolling={item.changeScrolling}
                isClearable
                isSearchable
                noPadding
                label={item.label}
                value={this.state.value}
                onChange={(value) => {
                  this.setState({ value: value.id });
                  item.changeChampValue(value.id, item.id);
                }}
                options={item.options}
                helperText={this.state.infoLabel}
                setList={
                  item.openSetListDialog && !item.tooManyContactMode
                    ? () => item.openSetListDialog(item.id)
                    : null
                }
                onInputChange={(value) => {
                  this.setState({ [item.id + "Input"]: value });
                }}
                noOptionsAction={
                  this.state[item.id + "Input"] &&
                  this.state[item.id + "Input"].trim() !== "" ? (
                    <Button
                      style={{ flex: 1, fontFamily: "Quicksand-Bold" }}
                      onClick={() =>
                        item.addElementToChampList(
                          this.state[item.id + "Input"],
                          item.id
                        )
                      }
                      variant="text"
                    >
                      {"Ajouter " + this.state[item.id + "Input"]}
                    </Button>
                  ) : null
                }
                disabled={
                  item.disabled ||
                  item.tooManyContactMode ||
                  item.currentUserType === "read"
                }
                tooManyContactMode={item.tooManyContactMode}
              />
            )}
          </View>
          {(item.openDialogRemoveChamp || item.openDialogEditChamp) &&
          !item.tooManyContactMode &&
          item.currentUserType === "edit" ? (
            <View style={{ flex: 0.1 }}>
              <View style={{ position: "absolute", right: 0 }}>
                {item.openDialogRemoveChamp ? (
                  <IconButton
                    style={{ color: "grey" }}
                    title="Supprimer le champ"
                    size="small"
                    onClick={() => item.openDialogRemoveChamp(item.id)}
                  >
                    <DeleteIcon fontSize="medium" />
                  </IconButton>
                ) : (
                  <></>
                )}

                {item.openDialogEditChamp ? (
                  <IconButton
                    style={{ color: "grey" }}
                    title="Modifier le champ"
                    size="small"
                    onClick={() => item.openDialogEditChamp(item.id)}
                  >
                    <EditIcon fontSize="medium" />
                  </IconButton>
                ) : (
                  <></>
                )}
              </View>
            </View>
          ) : (
            <></>
          )}
        </View>
      </div>
    );
  }
}

class ContactCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contact: "",
      isLoading: true,
      isLoading2: false,
      isLoading3: true,
      responsableOptions: "",
      isDialogVisible: false,
      canCloseDialogVisible: true,
      isDialogVisible2: false,
      canCloseDialogVisible2: true,
      isDialogVisible3: false,
      canCloseDialogVisible3: true,
      isDialogVisible4: false,
      canCloseDialogVisible4: true,
      isDialogVisible5: false,
      canCloseDialogVisible5: true,
      isDialogVisible6: false,
      canCloseDialogVisible6: true,
      isDialogVisible7: false,
      canCloseDialogVisible7: true,
      isDialogVisible8: false,
      canCloseDialogVisible8: true,
      dateInfoLabel: "",
      champs: [],
      list: [],
      scrollEnabled: true,
      champTypeOptions: [
        { id: "Texte", label: "Texte" },
        { id: "Date", label: "Date" },
        { id: "Liste", label: "Liste" },
      ],
      champType: "Texte",
      champLabel: "",
      champLabelInfoLabel: "",
      selectedChampId: "",
      tooManyContactMode: false,
    };
  }

  closeDialog = () => {
    this.setState({ isDialogVisible: false });
  };

  closeDialog2 = () => {
    this.setState({ isDialogVisible2: false });
  };

  closeDialog3 = () => {
    this.setState({ isDialogVisible3: false });
    this.props.navigation.goBack();
  };

  closeDialog4 = () => {
    this.setState({ isDialogVisible4: false });
  };

  closeDialog5 = () => {
    this.setState({ isDialogVisible5: false });
  };

  closeDialog6 = () => {
    this.setState({ isDialogVisible6: false }, () => {
      this.setState({ champId2: false });
    });
  };

  closeDialog7 = () => {
    this.setState({ isDialogVisible7: false });
  };
  closeDialog8 = () => {
    this.setState({ isDialogVisible8: false });
  };

  componentDidMount() {
    this.unsubscribe = this.props.navigation.addListener("blur", () => {
      window.removeEventListener("popstate", this.listener);
    });

    this.unsubscribe2 = this.props.navigation.addListener("focus", () => {
      setTimeout(() => {
        window.addEventListener(
          "popstate",
          (this.listener = (event) => handleBack(event, this))
        );
      }, 1);
    });

    setTimeout(() => {
      let contact = getContactById(
        this.props.route.params.contactId,
        this.props.data
      );

      let champs = Object.values(
        JSON.parse(JSON.stringify(this.props.settings.modeleContact))
      );
      champs.forEach((ch) => {
        ch.value = contact[ch.id] === undefined ? "" : contact[ch.id];
      });

      const responsables = getAllResponsables(this.props.users, false);
      const mode =
        this.props.route &&
        this.props.route.params &&
        this.props.route.params.tooManyContacts === true;

      this.setState(
        {
          list: this._createList(
            champs.filter((ch) => ch.visible === true),
            responsables,
            mode
          ),
          champs: champs,
          responsableOptions: responsables,
          SecteurOptions: getList("Secteur", this.props),
          StatutOptions: getList("Statut", this.props),
          FonctionOptions: getList("Fonction", this.props),
          SociétéOptions: getList("Société", this.props),
          tooManyContactMode: mode,
        },
        () => {
          this.setState({ isLoading: false });
        }
      );
    }, 1);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) ||
      JSON.stringify(prevProps.lists) !== JSON.stringify(this.props.lists) ||
      prevProps.settings.modeleContact !== this.props.settings.modeleContact ||
      prevProps.users !== this.props.users
    ) {
      let contact = getContactById(
        this.props.route.params.contactId,
        this.props.data
      );
      if (contact === undefined) {
        this.setState({ isDialogVisible3: true });
        return;
      }
      this._reloadContact(contact);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.listener);

    if (this.unsubscribe) this.unsubscribe();
    if (this.unsubscribe2) this.unsubscribe2();
  }

  _addElementToChampList = (value, listId, dialog) => {
    if (this.state.isLoading2) {
      return;
    }

    this.setState({ isLoading2: true });

    if (
      getList(listId, this.props).some(
        (opt) => opt.label === value.toString().trim()
      )
    ) {
      setSnackbar("warning", "Cet élément existe déjà", this.props);

      this.setState({ isLoading2: false });
      return;
    }

    addElementToPreListDatabase(value.toString().trim(), listId, this.props)
      .then((key) => {
        if (dialog) {
          this.setState({ newValue: key, open: false });
          this[listId + "Ref"].current.blur();
        } else {
          this._changeChampValue(key, listId);
        }
        setSnackbar("success", "Elément créé avec succès", this.props);
      })
      .catch((error) => {
        setSnackbar(
          "error",
          error === "No network"
            ? "Erreur réseau"
            : "Une erreur est survenue. Veuillez réessayer plus tard",
          this.props
        );
        if (DEBUG) console.warn(error);
      })
      .finally(() => {
        setTimeout(() => this.setState({ isLoading2: false }), 100);
      });
  };

  _reloadContact(contact) {
    this.setState({ isLoading2: true });

    let champs = Object.values(
      JSON.parse(JSON.stringify(this.props.settings.modeleContact))
    );
    champs.forEach((ch) => {
      ch.value =
        (contact[ch.id] === undefined || contact[ch.id] === "") &&
        this.state.champs.find((chp) => chp.id === ch.id) !== undefined &&
        this.state.champs.find((chp) => chp.id === ch.id).value !== undefined
          ? this.state.champs.find((chp) => chp.id === ch.id).value
          : contact[ch.id] === undefined
          ? ""
          : contact[ch.id];
    });

    let responsables = getAllResponsables(this.props.users, false);
    const mode =
      this.props.route &&
      this.props.route.params &&
      this.props.route.params.tooManyContacts === true;

    this.setState({
      SecteurOptions: getList("Secteur", this.props),
      StatutOptions: getList("Statut", this.props),
      FonctionOptions: getList("Fonction", this.props),
      SociétéOptions: getList("Société", this.props),
      responsableOptions: responsables,
      list: this._createList(
        champs.filter((ch) => ch.visible === true),
        responsables,
        mode
      ),
      champs: champs,
      tooManyContactMode: mode,
      isLoading2: false,
    });
  }

  _copyTextToClipboard(text) {
    var textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    setSnackbar("info", "Copié dans le presse-papier", this.props);
  }

  _callNumber(number) {
    window.open(`tel:${number} `);
  }

  _deleteContact() {
    if (this.state.isLoading2) {
      return;
    }

    this.setState({ isLoading2: true });

    deleteDataToDatabase(this.props.route.params.contactId, this.props)
      .then(() => {
        setSnackbar("success", "Contact supprimé avec succès", this.props);
        this.props.navigation.goBack();
      })
      .catch((error) => {
        setSnackbar(
          "error",
          error === "No network"
            ? "Erreur réseau"
            : "Une erreur est survenue. Veuillez réessayer plus tard",
          this.props
        );
        if (DEBUG) console.warn(error);
      })
      .finally(() => {
        this.setState({ isDialogVisible7: false }, () => {
          setTimeout(() => {
            this.setState({ isLoading2: false });
          }, 100);
        });
      });
  }

  _updateContact = (noBack) => {
    if (this.state.isLoading2) {
      return;
    }

    this.setState({ isLoading2: true });

    if (this.state.dateInfoLabel !== "") {
      this.setState({ isLoading2: false });
      return;
    }

    let contactU = {};

    let champspersoFormatted = {};
    this.state.champs.forEach((e) => {
      champspersoFormatted[e.id] = e.value;
    });

    contactU = champspersoFormatted;
    contactU["Date de relance"] = contactU["Date de relance"]
      ? moment(contactU["Date de relance"]).format("DD/MM/YYYY HH:mm")
      : "";
    contactU["Date de création"] = contactU["Date de création"]
      ? moment(contactU["Date de création"]).format("DD/MM/YYYY HH:mm")
      : "";
    contactU.id = this.props.route.params.contactId;

    updateDataToDatabase(contactU, this.props)
      .then(() => {
        setSnackbar("success", "Contact modifié avec succès", this.props);
        if (!noBack) {
          this.props.navigation.goBack();
        }
      })
      .catch((error) => {
        setSnackbar(
          "error",
          error === "No network"
            ? "Erreur réseau"
            : "Une erreur est survenue. Veuillez réessayer plus tard",
          this.props
        );
        if (DEBUG) console.warn(error);
      })
      .finally(() => {
        setTimeout(() => {
          this.setState({ isLoading2: false, isDialogVisible6: false });
        }, 50);
      });
  };

  _onListChange = (newList) => {
    this.setState({ isLoading2: true, list: newList });
    setTimeout(() => {
      let pos = -1;
      let promises = [];
      newList.forEach((ch) => {
        pos++;
        promises.push(
          updateChampPersoToModeleContactToDatabase(
            "order",
            pos,
            ch.id,
            this.props
          )
        );
      });

      Promise.all(promises)
        .then(() => {
          setSnackbar("success", "Champ déplacé avec succès", this.props);
        })
        .catch((error) => {
          setSnackbar(
            "error",
            error === "No network"
              ? "Erreur réseau"
              : "Une erreur est survenue. Veuillez réessayer plus tard",
            this.props
          );
          if (DEBUG) console.warn(error);
        })
        .finally(() => {
          this.setState({ isLoading2: false });
        });
    }, 100);
  };

  _changeChampValue = (value, champId) => {
    let champs = [...this.state.champs];
    let index = champs.findIndex((ch) => ch.id === champId);
    champs[index]["value"] = value;

    this.setState({ champs: champs });
  };

  _changeScrolling = (enabled) => {
    this.setState({
      scrollEnabled:
        enabled === undefined ? !this.state.scrollEnabled : enabled,
    });
  };

  _createList(champs, responsables, mode) {
    let list = [];

    champs.forEach((champ) => {
      list.push({
        name: champ.id,
        id: champ.id,
        type: champ.type,
        label: champ.label,
        value: champ.value,
        order: champ.order,
        changeChampValue: this._changeChampValue,
        options:
          champ.type === "Liste"
            ? champ.id === "Responsable"
              ? responsables
              : getList(champ.id, this.props)
            : "",
        openSetListDialog:
          champ.id === "Responsable" ? null : this._openSetListDialog,
        changeScrolling: this._changeScrolling,
        addElementToChampList: this._addElementToChampList,
        disabled: champ.disabled,
        openDialogRemoveChamp: champ.custom
          ? this._openDialogRemoveChamp
          : null,
        openDialogEditChamp: champ.custom ? this._openDialogEditChamp : null,
        navigateTo:
          champ.id === "Responsable" &&
          userIsGestionnaire(this.props.currentUser)
            ? () => document.getElementById("utilisateursNav").click()
            : null,
        tooManyContactMode: mode,
        currentUserType: this.props.currentUser.type,
      });
    });

    list.sort(function (a, b) {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    });

    return list;
  }

  _openSetListDialog = (champId) => {
    this.setState({ isDialogVisible6: false });
    this.props.navigation.navigate("GestionListe", {
      listId: champId,
      listName: this.state.champs.find((ch) => ch.id === champId).label,
    });
  };

  _openDialogRemoveChamp = (champId) => {
    this.setState({ isDialogVisible2: true, selectedChampId: champId });
  };

  _openDialogEditChamp = (champId) => {
    let lab = this.state.champs.find((ch) => ch.id === champId).label;
    this.setState({
      isDialogVisible4: true,
      newChampLabel: lab,
      selectedChampId: champId,
      champLabel: lab,
      champId: champId,
      champLabelInfoLabel: "",
    });
  };

  _removeChamp() {
    if (this.state.isLoading2) {
      return;
    }
    this.setState({ isLoading2: true });

    removeChampPersoToModeleContactToDatabase(
      this.state.selectedChampId,
      this.props
    )
      .then(() => {
        removeChampPersoToAllDataToDatabase(
          this.state.selectedChampId,
          this.props
        ).then(() => {
          removePreListDatabase(this.state.selectedChampId, this.props).then(
            () => {
              setSnackbar("success", "Champ supprimé avec succès", this.props);
            }
          );
        });
      })
      .catch((error) => {
        setSnackbar(
          "error",
          error === "No network"
            ? "Erreur réseau"
            : "Une erreur est survenue. Veuillez réessayer plus tard",
          this.props
        );
        if (DEBUG) console.warn(error);
      })
      .finally(() => {
        this.setState({ isDialogVisible8: false }, () => {
          setTimeout(() => {
            this.setState({ isLoading2: false });
          }, 100);
        });
      });
  }

  _addChamp = () => {
    if (this.state.isLoading2) {
      return;
    }
    this.setState({ isLoading2: true, champLabelInfoLabel: "" });

    if (this.state.champLabel === "") {
      this.setState({
        champLabelInfoLabel: "Paramètre obligatoire",
        isLoading2: false,
      });
      return;
    }

    if (
      this.state.champs
        .map((ch) => ch.label)
        .includes(this.state.champLabel.trim())
    ) {
      this.setState({
        champLabelInfoLabel: "Ce champ existe déjà",
        isLoading2: false,
      });
      return;
    }

    let champ = {};
    champ["type"] = this.state.champType;
    champ["label"] = this.state.champLabel.trim();
    champ["value"] = "";
    champ["order"] = Math.max.apply(
      Math,
      this.state.champs.map(function (o) {
        return o.order + 1;
      })
    );
    champ["visible"] = true;
    champ["custom"] = true;

    addChampPersoToModeleContactToDatabase(champ, this.props)
      .then((key) => {
        if (champ["type"] === "Liste") {
          addElementToPreListDatabase("", key, this.props).then(() => {});
        }
        setSnackbar("success", "Champ ajouté avec succès", this.props);
      })
      .catch((error) => {
        setSnackbar(
          "error",
          error === "No network"
            ? "Erreur réseau"
            : "Une erreur est survenue. Veuillez réessayer plus tard",
          this.props
        );
        if (DEBUG) console.warn(error);
      })
      .finally(() => {
        this.setState(
          {
            isDialogVisible: false,
            champLabel: "",
            champLabelInfoLabel: "",
            champType: "Texte",
          },
          () => {
            setTimeout(() => {
              this.setState({ isLoading2: false });
            }, 100);
          }
        );
      });
  };

  _updateChampLabel() {
    if (this.state.isLoading2) {
      return;
    }
    this.setState({ isLoading2: true });

    if (this.state.newChampLabel === "") {
      this.setState({
        champLabelInfoLabel: "Paramètre obligatoire",
        isLoading2: false,
      });
      return;
    }

    if (
      this.state.champs
        .map((ch) => ch.label)
        .includes(this.state.newChampLabel.trim())
    ) {
      this.setState({
        champLabelInfoLabel: "Ce champ existe déjà",
        isLoading2: false,
      });
      return;
    }

    updateChampPersoToModeleContactToDatabase(
      "label",
      this.state.newChampLabel.trim(),
      this.state.selectedChampId,
      this.props
    )
      .then(() => {
        setSnackbar("success", "Champ modifié avec succès", this.props);
      })
      .catch((error) => {
        setSnackbar(
          "error",
          error === "No network"
            ? "Erreur réseau"
            : "Une erreur est survenue. Veuillez réessayer plus tard",
          this.props
        );
        if (DEBUG) console.warn(error);
      })
      .finally(() => {
        this.setState(
          {
            champLabel: this.state.newChampLabel,
            isDialogVisible4: false,
          },
          () => setTimeout(() => this.setState({ isLoading2: false }), 100)
        );
      });
  }

  render() {
    return (
      <View style={globalStyles.main_container}>
        <Header
          navigation={this.props.navigation}
          icon={
            <div>
              {this.state.tooManyContactMode ? (
                <></>
              ) : (
                <IconButton
                  color="secondary"
                  title="Enregistrer les modifications"
                  onClick={() => this._updateContact("noBack")}
                  size="medium"
                >
                  <SaveIcon fontSize={"medium"} />
                </IconButton>
              )}
              {this.props.currentUser.type === "read" ? (
                <></>
              ) : (
                <IconButton
                  color="secondary"
                  title="Supprimer le contact"
                  onClick={() => this.setState({ isDialogVisible5: true })}
                  size="medium"
                >
                  <DeleteIcon fontSize={"medium"} />
                </IconButton>
              )}
            </div>
          }
        >
          {"contact_title"}
        </Header>
        <DialogAlert
          isDialogVisible={this.state.isDialogVisible3}
          onClose={() => {
            this.closeDialog3();
          }}
          title={"Contact supprimé"}
          text={"Ce contact a été supprimé."}
          onlyOkButton={true}
          cancel={() => {
            this.closeDialog3();
          }}
        />

        <DialogAlert
          isDialogVisible={this.state.isDialogVisible5}
          onClose={() => {
            this.closeDialog5();
          }}
          title={"Supprimer un contact"}
          text={"Etes-vous sûr de vouloir supprimer ce contact ?"}
          confirm={() =>
            this.setState({ isDialogVisible5: false, isDialogVisible7: true })
          }
          cancel={() => {
            this.closeDialog5();
          }}
        />

        <DialogAlert
          isDialogVisible={this.state.isDialogVisible7}
          onClose={() => {
            this.closeDialog7();
          }}
          title={"Confirmation"}
          confirm={() => this._deleteContact()}
          cancel={() => {
            this.closeDialog7();
          }}
          content={
            <View>
              <WarningAmberIcon
                color="warning"
                fontSize={"large"}
                style={{
                  marginBottom: 7,
                  textAlign: "center",
                  alignSelf: "center",
                  justifyContent: "center",
                }}
              />
              <CustomText style={{ fontSize: textFontSize - 2 }}>
                Etes-vous certain de vouloir supprimer ce contact ? Il sera
                impossible de le récupérer.
              </CustomText>
            </View>
          }
        />

        <DialogAlert
          isDialogVisible={this.state.isDialogVisible2}
          onClose={() => {
            this.closeDialog2();
          }}
          title={"Supprimer un champ"}
          text={"Etes-vous sûr de vouloir supprimer ce champ ?"}
          confirm={() =>
            this.setState({ isDialogVisible2: false, isDialogVisible8: true })
          }
          cancel={() => {
            this.closeDialog2();
          }}
        />

        <DialogAlert
          isDialogVisible={this.state.isDialogVisible8}
          onClose={() => {
            this.closeDialog8();
          }}
          title={"Confirmation"}
          confirm={() => this._removeChamp()}
          cancel={() => {
            this.closeDialog8();
          }}
          content={
            <View>
              <WarningAmberIcon
                color="warning"
                fontSize={"large"}
                style={{
                  marginBottom: 7,
                  textAlign: "center",
                  alignSelf: "center",
                  justifyContent: "center",
                }}
              />
              <CustomText style={{ fontSize: textFontSize - 2 }}>
                Etes-vous certain de vouloir supprimer ce champ ? Il sera
                impossible de le récupérer.
              </CustomText>
            </View>
          }
        />

        <Dialog
          onBackdropClick={() => this.closeDialog()}
          open={this.state.isDialogVisible}
          onClose={() => this.closeDialog()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ fontFamily: "Quicksand-Regular" }}
        >
          <DialogTitle style={{ fontFamily: "Quicksand-Bold" }}>
            Ajouter un nouveau champ
          </DialogTitle>
          <DialogContent style={{ fontFamily: "Quicksand-Bold" }}>
            <View style={{ paddingBottom: 12, fontSize: textFontSize - 3 }}>
              <CustomText style={{ fontSize: textFontSize - 3 }}>
                Ce nouveau champ sera ajouté à tous vos contacts !
              </CustomText>
            </View>
            <CustomSelect
              label={"Type"}
              value={this.state.champType}
              onChange={(value) => this.setState({ champType: value.id })}
              options={this.state.champTypeOptions}
            />
            <FloatingLabelInput
              label="Nom du champ"
              onChange={(value) => this.setState({ champLabel: value })}
              value={this.state.champLabel}
              helperText={this.state.champLabelInfoLabel}
              maxLength={30}
            />
          </DialogContent>
          <DialogActions>
            <Button
              style={{ fontFamily: "Quicksand-Bold" }}
              onClick={() => this.closeDialog()}
              color="primary"
            >
              Annuler
            </Button>
            <Button
              style={{ fontFamily: "Quicksand-Bold" }}
              onClick={() => this._addChamp()}
              color="primary"
            >
              Valider
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          onBackdropClick={() => this.closeDialog4()}
          open={this.state.isDialogVisible4}
          onClose={() => this.closeDialog4()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ fontFamily: "Quicksand-Regular" }}
        >
          <DialogTitle style={{ fontFamily: "Quicksand-Bold" }}>
            Modifier un champ
          </DialogTitle>
          <DialogContent style={{ fontFamily: "Quicksand-Bold" }}>
            <FloatingLabelInput
              label="Nom du champ"
              onChange={(value) => this.setState({ newChampLabel: value })}
              value={this.state.newChampLabel}
              defaultValue={this.state.champLabel}
              helperText={this.state.champLabelInfoLabel}
              maxLength={30}
            />
            <DialogActions>
              <Button
                style={{ fontFamily: "Quicksand-Bold" }}
                onClick={() => this.closeDialog4()}
                color="primary"
              >
                Annuler
              </Button>
              <Button
                style={{ fontFamily: "Quicksand-Bold" }}
                onClick={() => this._updateChampLabel()}
                color="primary"
              >
                Valider
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

        <Dialog
          onBackdropClick={() => this.closeDialog6()}
          open={this.state.isDialogVisible6}
          onClose={() => this.closeDialog6()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ fontFamily: "Quicksand-Regular" }}
        >
          <DialogTitle style={{ fontFamily: "Quicksand-Bold" }}>
            {this.state.champId2 !== "" &&
            this.state.champs.find((ch) => ch.id === this.state.champId) !==
              undefined &&
            this.state.champs.find((ch) => ch.id === this.state.champId2) !==
              undefined
              ? "Modifier " +
                this.state.champs.find((ch) => ch.id === this.state.champId)
                  .label +
                " et " +
                this.state.champs.find((ch) => ch.id === this.state.champId2)
                  .label
              : this.state.champs.find((ch) => ch.id === this.state.champId) !==
                undefined
              ? "Modifier " +
                this.state.champs.find((ch) => ch.id === this.state.champId)
                  .label
              : ""}
          </DialogTitle>
          <DialogContent style={{ fontFamily: "Quicksand-Bold" }}>
            {this.state.select ? (
              <CustomSelect
                setRef={(ref) => (this[this.state.champId + "Ref"] = ref)}
                open={this.state.open}
                changeScrolling={this._changeScrolling}
                label={
                  this.state.champs.find(
                    (ch) => ch.id === this.state.champId
                  ) !== undefined
                    ? this.state.champs.find(
                        (ch) => ch.id === this.state.champId
                      ).label
                    : ""
                }
                value={this.state.newValue}
                onChange={(value) => this.setState({ newValue: value.id })}
                options={this.state[this.state.champId + "Options"]}
                isClearable
                isSearchable
                setList={() => this._openSetListDialog(this.state.champId)}
                onInputChange={(value) => {
                  this.setState({ statutInput: value });
                }}
                noOptionsAction={
                  this.state.statutInput &&
                  this.state.statutInput.trim() !== "" ? (
                    <Button
                      style={{ flex: 1, fontFamily: "Quicksand-Bold" }}
                      onClick={() =>
                        this._addElementToChampList(
                          this.state.statutInput,
                          this.state.champId,
                          "dialog"
                        )
                      }
                      variant="text"
                    >
                      {"Ajouter " + this.state.statutInput}
                    </Button>
                  ) : null
                }
              />
            ) : (
              <View>
                <FloatingLabelInput
                  noPadding={!this.state.champId2}
                  label={
                    this.state.champs.find(
                      (ch) => ch.id === this.state.champId
                    ) !== undefined
                      ? this.state.champs.find(
                          (ch) => ch.id === this.state.champId
                        ).label
                      : ""
                  }
                  onChange={(value) => this.setState({ newValue: value })}
                  value={this.state.newValue}
                  defaultValue={this.state.newValue}
                />
                {this.state.champId2 ? (
                  <FloatingLabelInput
                    noPadding
                    label={
                      this.state.champs.find(
                        (ch) => ch.id === this.state.champId2
                      ) !== undefined
                        ? this.state.champs.find(
                            (ch) => ch.id === this.state.champId2
                          ).label
                        : ""
                    }
                    onChange={(value) => this.setState({ newValue2: value })}
                    value={this.state.newValue2}
                    defaultValue={this.state.newValue2}
                  />
                ) : (
                  <></>
                )}
              </View>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              style={{ fontFamily: "Quicksand-Bold" }}
              onClick={() => this.closeDialog6()}
              color="primary"
            >
              Annuler
            </Button>
            <Button
              style={{ fontFamily: "Quicksand-Bold" }}
              onClick={() => {
                this._changeChampValue(this.state.newValue, this.state.champId);
                if (this.state.champId2) {
                  this._changeChampValue(
                    this.state.newValue2,
                    this.state.champId2
                  );
                }
                setTimeout(() => {
                  this._updateContact("noBack");
                }, 100);
              }}
              color="primary"
            >
              Valider
            </Button>
          </DialogActions>
        </Dialog>

        {displayLoading(this.state.isLoading2 || this.state.isLoading3)}
        {this.state.isLoading ? (
          displayLoading(this.state.isLoading)
        ) : (
          <View style={globalStyles.list_container}>
            <div
              id="ContactCardContainer"
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5%",
                paddingLeft: "7%",
                paddingRight: "7%",
                opacity: this.state.isLoading2
                  ? 0.5
                  : this.state.isLoading3
                  ? 0
                  : 1,
                overflowY: "scroll",
                paddingBottom: this.props.fab ? 80 : "5%",
                scrollbarWidth: "none",
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "column" }}
                className="noselect"
              >
                <View style={{ marginBottom: 15, flexDirection: "row" }}>
                  {!this.state.tooManyContactMode &&
                  this.props.currentUser.type === "edit" ? (
                    <IconButton
                      style={{ left: -10, alignSelf: "center" }}
                      title="Modifier le prénom et le nom"
                      size="small"
                      onClick={() =>
                        this.setState({
                          champId: "Prénom",
                          champId2: "Nom",
                          select: false,
                          newValue: this.state.champs.find(
                            (ch) => ch.id === "Prénom"
                          ).value,
                          newValue2: this.state.champs.find(
                            (ch) => ch.id === "Nom"
                          ).value,
                          isDialogVisible6: true,
                        })
                      }
                    >
                      <EditIcon fontSize="small" style={{ color: "grey" }} />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                  <div style={{ flexDirection: "row", flex: 1, flexWrap: 1 }}>
                    {this.state.champs.find((ch) => ch.id === "Prénom")
                      .value !== "" ? (
                      <CustomTitle textType="bold">
                        {
                          this.state.champs.find((ch) => ch.id === "Prénom")
                            .value
                        }
                      </CustomTitle>
                    ) : (
                      <CustomTitle
                        style={{ color: labelColor }}
                        textType="italic"
                      >
                        Prénom
                      </CustomTitle>
                    )}

                    {this.state.champs.find((ch) => ch.id === "Nom").value !==
                    "" ? (
                      <CustomTitle textType="bold">
                        {" " +
                          this.state.champs.find((ch) => ch.id === "Nom").value}
                      </CustomTitle>
                    ) : (
                      <CustomTitle
                        style={{ color: labelColor }}
                        textType="italic"
                      >
                        {" Nom"}
                      </CustomTitle>
                    )}
                  </div>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    paddingBottom: 15,
                    alignItems: "center",
                  }}
                >
                  {!this.state.tooManyContactMode &&
                  this.props.currentUser.type === "edit" ? (
                    <IconButton
                      style={{ left: -10 }}
                      title="Modifier la fonction"
                      size="small"
                      onClick={() =>
                        this.setState({
                          champId: "Fonction",
                          champId2: false,
                          select: true,
                          newValue: this.state.champs.find(
                            (ch) => ch.id === "Fonction"
                          ).value,
                          isDialogVisible6: true,
                        })
                      }
                    >
                      <EditIcon fontSize="small" style={{ color: "grey" }} />
                    </IconButton>
                  ) : (
                    <></>
                  )}

                  <View style={{ flex: 6 }}>
                    {getElementPreListLabelById(
                      this.state.FonctionOptions,
                      this.state.champs.find((ch) => ch.id === "Fonction").value
                    ) === "" ? (
                      <CustomText
                        textType="italic"
                        style={{ color: labelColor }}
                      >
                        Fonction
                      </CustomText>
                    ) : (
                      <></>
                    )}
                    <CustomText textType="bold">
                      {getElementPreListLabelById(
                        this.state.FonctionOptions,
                        this.state.champs.find((ch) => ch.id === "Fonction")
                          .value
                      )}
                    </CustomText>
                  </View>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    paddingBottom: 15,
                    alignItems: "center",
                  }}
                >
                  {!this.state.tooManyContactMode &&
                  this.props.currentUser.type === "edit" ? (
                    <IconButton
                      style={{ left: -10 }}
                      title="Modifier la société"
                      size="small"
                      onClick={() =>
                        this.setState({
                          champId: "Société",
                          champId2: false,
                          select: true,
                          newValue: this.state.champs.find(
                            (ch) => ch.id === "Société"
                          ).value,
                          isDialogVisible6: true,
                        })
                      }
                    >
                      <EditIcon fontSize="small" style={{ color: "grey" }} />
                    </IconButton>
                  ) : (
                    <></>
                  )}

                  <View style={{ flex: 6 }}>
                    {getElementPreListLabelById(
                      this.state.SociétéOptions,
                      this.state.champs.find((ch) => ch.id === "Société").value
                    ) === "" ? (
                      <CustomText
                        textType="italic"
                        style={{ color: labelColor }}
                      >
                        Société
                      </CustomText>
                    ) : (
                      <></>
                    )}
                    <CustomText textType="bold">
                      {getElementPreListLabelById(
                        this.state.SociétéOptions,
                        this.state.champs.find((ch) => ch.id === "Société")
                          .value
                      )}
                    </CustomText>
                  </View>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    paddingBottom: 15,
                    alignItems: "center",
                  }}
                >
                  {!this.state.tooManyContactMode &&
                  this.props.currentUser.type === "edit" ? (
                    <IconButton
                      style={{ left: -10 }}
                      title="Modifier le secteur"
                      size="small"
                      onClick={() =>
                        this.setState({
                          champId: "Secteur",
                          champId2: false,
                          select: true,
                          newValue: this.state.champs.find(
                            (ch) => ch.id === "Secteur"
                          ).value,
                          isDialogVisible6: true,
                        })
                      }
                    >
                      <EditIcon fontSize="small" style={{ color: "grey" }} />
                    </IconButton>
                  ) : (
                    <></>
                  )}

                  <View style={{ flex: 6 }}>
                    <CustomText textType="italic" style={{ color: labelColor }}>
                      Secteur
                    </CustomText>
                    <CustomText>
                      {getElementPreListLabelById(
                        this.state.SecteurOptions,
                        this.state.champs.find((ch) => ch.id === "Secteur")
                          .value
                      )}
                    </CustomText>
                  </View>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    paddingBottom: 15,
                    alignItems: "center",
                  }}
                >
                  {!this.state.tooManyContactMode &&
                  this.props.currentUser.type === "edit" ? (
                    <IconButton
                      style={{ left: -10 }}
                      title="Modifier le téléphone fixe"
                      size="small"
                      onClick={() =>
                        this.setState({
                          champId: "Téléphone fixe",
                          champId2: false,
                          select: false,
                          newValue: this.state.champs.find(
                            (ch) => ch.id === "Téléphone fixe"
                          ).value,
                          isDialogVisible6: true,
                        })
                      }
                    >
                      <EditIcon fontSize="small" style={{ color: "grey" }} />
                    </IconButton>
                  ) : (
                    <></>
                  )}

                  <View style={{ flex: 6 }}>
                    <CustomText textType="italic" style={{ color: labelColor }}>
                      Téléphone fixe
                    </CustomText>
                    <CustomText>
                      {
                        this.state.champs.find(
                          (ch) => ch.id === "Téléphone fixe"
                        ).value
                      }
                    </CustomText>
                  </View>
                  {this.state.champs.find((ch) => ch.id === "Téléphone fixe")
                    .value !== "" ? (
                    <View>
                      <View style={{ flex: 1 }} />
                      <View style={{ flex: 1 }}>
                        <IconButton
                          color="primary"
                          title="Appeler"
                          onClick={() =>
                            this._callNumber(
                              this.state.champs.find(
                                (ch) => ch.id === "Téléphone fixe"
                              ).value
                            )
                          }
                          size="medium"
                        >
                          <PhoneIcon fontSize={"medium"} />
                        </IconButton>
                      </View>
                    </View>
                  ) : (
                    <></>
                  )}
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    paddingBottom: 15,
                    alignItems: "center",
                  }}
                >
                  {!this.state.tooManyContactMode &&
                  this.props.currentUser.type === "edit" ? (
                    <IconButton
                      style={{ left: -10 }}
                      title="Modifier le téléphone portable"
                      size="small"
                      onClick={() =>
                        this.setState({
                          champId: "Portable",
                          select: false,
                          newValue: this.state.champs.find(
                            (ch) => ch.id === "Portable"
                          ).value,
                          isDialogVisible6: true,
                        })
                      }
                    >
                      <EditIcon fontSize="small" style={{ color: "grey" }} />
                    </IconButton>
                  ) : (
                    <></>
                  )}

                  <View style={{ flex: 6 }}>
                    <CustomText textType="italic" style={{ color: labelColor }}>
                      Portable
                    </CustomText>
                    <CustomText>
                      {
                        this.state.champs.find((ch) => ch.id === "Portable")
                          .value
                      }
                    </CustomText>
                  </View>
                  {this.state.champs.find((ch) => ch.id === "Portable")
                    .value !== "" ? (
                    <View style={{ flexDirection: "row" }}>
                      <View style={{ flex: 1 }}>
                        <IconButton
                          color="primary"
                          title="Appeler"
                          onClick={() =>
                            this._callNumber(
                              this.state.champs.find(
                                (ch) => ch.id === "Portable"
                              ).value
                            )
                          }
                          size="medium"
                        >
                          <PhoneIcon fontSize={"medium"} />
                        </IconButton>
                      </View>
                      <View style={{ flex: 1 }}>
                        <IconButton
                          color="primary"
                          title="Envoyer un sms"
                          onClick={() =>
                            sendMessage(
                              this.state.champs.find(
                                (ch) => ch.id === "Portable"
                              ).value
                            )
                          }
                          size="medium"
                        >
                          <SmsIcon fontSize={"medium"} />
                        </IconButton>
                      </View>
                    </View>
                  ) : (
                    <></>
                  )}
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    paddingBottom: 15,
                    alignItems: "center",
                  }}
                >
                  {!this.state.tooManyContactMode &&
                  this.props.currentUser.type === "edit" ? (
                    <IconButton
                      style={{ left: -10 }}
                      title="Modifier l'email"
                      size="small"
                      onClick={() =>
                        this.setState({
                          champId: "Email",
                          select: false,
                          newValue: this.state.champs.find(
                            (ch) => ch.id === "Email"
                          ).value,
                          isDialogVisible6: true,
                        })
                      }
                    >
                      <EditIcon fontSize="small" style={{ color: "grey" }} />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                  <View style={{ flex: 6 }}>
                    <CustomText textType="italic" style={{ color: labelColor }}>
                      Email
                    </CustomText>
                    <TouchableOpacity
                      onPress={() =>
                        this._copyTextToClipboard(
                          this.state.champs.find((ch) => ch.id === "Email")
                            .value
                        )
                      }
                    >
                      <CustomText>
                        {
                          this.state.champs.find((ch) => ch.id === "Email")
                            .value
                        }
                      </CustomText>
                    </TouchableOpacity>
                  </View>

                  {this.state.champs.find((ch) => ch.id === "Email").value
                    .length > 0 ? (
                    <View>
                      <View style={{ flex: 1 }} />

                      <View style={{ flex: 1 }}>
                        <IconButton
                          color="primary"
                          title="Envoyer un email"
                          onClick={() =>
                            sendMail(
                              this.state.champs.find((ch) => ch.id === "Email")
                                .value
                            )
                          }
                          size="medium"
                        >
                          <EmailIcon fontSize={"medium"} />
                        </IconButton>
                      </View>
                    </View>
                  ) : (
                    <></>
                  )}
                </View>

                <View
                  onLayout={() => {
                    this.textarea.scrollTop = this.textarea.scrollHeight;
                    setTimeout(() => {
                      this.setState({ isLoading3: false });
                    }, 100);
                  }}
                  style={{ paddingVertical: 10 }}
                >
                  <TextField
                    disabled={this.state.tooManyContactMode}
                    select={false}
                    label={"Observations"}
                    variant="outlined"
                    size="small"
                    multiline
                    maxRows={5}
                    InputLabelProps={{
                      style: {
                        color: "grey",
                        fontFamily: "Quicksand-Bold",
                        fontSize: labelFontSize,
                      },
                    }}
                    inputProps={{
                      enterKeyHint: "enter",
                      style: {
                        color: "black",
                        fontFamily: "Quicksand-Regular",
                        fontSize: textFontSize,
                      },
                    }}
                    onChange={(event) =>
                      this._changeChampValue(event.target.value, "Observations")
                    }
                    value={
                      this.state.champs.find((ch) => ch.id === "Observations")
                        .value
                    }
                    inputRef={(e) => (this.textarea = e)}
                  />
                </View>

                <CustomDatePicker
                  disabled={this.state.tooManyContactMode}
                  label="Date de relance"
                  value={
                    this.state.champs.find((ch) => ch.id === "Date de relance")
                      .value
                  }
                  onChange={(value) =>
                    this._changeChampValue(value, "Date de relance")
                  }
                  setErrorLabel={(errorLabel) =>
                    this.setState({ dateInfoLabel: errorLabel })
                  }
                  errorLabel={this.state.dateInfoLabel}
                />

                <CustomSelect
                  disabled={this.state.tooManyContactMode}
                  changeScrolling={this._changeScrolling}
                  label={"Statut"}
                  value={
                    this.state.champs.find((ch) => ch.id === "Statut").value
                  }
                  onChange={(value) =>
                    this._changeChampValue(value.id, "Statut")
                  }
                  options={this.state.StatutOptions}
                  isClearable
                  isSearchable
                  setList={
                    !this.state.tooManyContactMode
                      ? () => this._openSetListDialog("Statut")
                      : null
                  }
                  onInputChange={(value) => {
                    this.setState({ statutInput: value });
                  }}
                  noOptionsAction={
                    this.state.statutInput &&
                    this.state.statutInput.trim() !== "" ? (
                      <Button
                        style={{ flex: 1, fontFamily: "Quicksand-Bold" }}
                        onClick={() =>
                          this._addElementToChampList(
                            this.state.statutInput,
                            "Statut"
                          )
                        }
                        variant="text"
                      >
                        {"Ajouter " + this.state.statutInput}
                      </Button>
                    ) : null
                  }
                />

                <CustomSelect
                  disabled={
                    this.state.tooManyContactMode ||
                    this.props.currentUser.type === "read"
                  }
                  changeScrolling={this._changeScrolling}
                  label={"Responsable"}
                  value={
                    this.state.champs.find((ch) => ch.id === "Responsable")
                      .value
                  }
                  onChange={(value) =>
                    this._changeChampValue(value.id, "Responsable")
                  }
                  options={this.state.responsableOptions}
                  isClearable
                  isSearchable
                  navigateTo={
                    userIsGestionnaire(this.props.currentUser) &&
                    !this.state.tooManyContactMode
                      ? () => document.getElementById("utilisateursNav").click()
                      : false
                  }
                />

                <DraggableList
                  itemKey="name"
                  template={ChampItem}
                  list={this.state.list}
                  onMoveEnd={(newList) => this._onListChange(newList)}
                  container={() =>
                    document.getElementById("ContactCardContainer")
                  }
                />
                {this.state.tooManyContactMode ||
                this.props.currentUser.type === "read" ? (
                  <></>
                ) : (
                  <Button
                    style={{
                      fontFamily: "Quicksand-Bold",
                      alignSelf: "flex-start",
                    }}
                    onClick={() =>
                      this.setState({
                        champLabel: "",
                        champType: "Texte",
                        champLabelInfoLabel: "",
                        isDialogVisible: true,
                      })
                    }
                    variant="text"
                  >
                    Créer un nouveau champ
                  </Button>
                )}
                {this.state.tooManyContactMode ? (
                  <></>
                ) : (
                  <View
                    style={{
                      marginTop: 15,
                      alignSelf: "center",
                    }}
                  >
                    <CustomButton
                      title={"Enregistrer et fermer"}
                      onPress={() => this._updateContact()}
                    />
                  </View>
                )}
              </div>
            </div>
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.setData.data,
    users: state.setData["users"],
    currentUser: state.setData["currentUser"],
    isConnectedToNetwork: state.setConnection.isConnectedToNetwork,
    settings: state.setAccount.settings,
    lists: state.setData.lists,
    accountAccessKey: state.setAccount.accountAccessKey,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactCard);
