import React from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { styles } from './styles';
import { handleBack, sendMail } from '../../../utilities/utils';
import { globalStyles } from '../../../styles/styles';
import Header from '../Headers/Header';
import CustomText from '../../CustomComponents/CustomText';
import CustomTitle from '../../CustomComponents/CustomTitle';

class CGU extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.unsubscribe = this.props.navigation.addListener('blur', () => {
            window.removeEventListener('popstate', this.listener)

        })

        this.unsubscribe2 = this.props.navigation.addListener('focus', () => {
            setTimeout(() => {
                window.addEventListener('popstate', this.listener = (event) => handleBack(event, this));

            }, 1)

        })
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.listener)

        if (this.unsubscribe)
            this.unsubscribe()
        if (this.unsubscribe2)
            this.unsubscribe2()
    }

    render() {
        return (
            <View style={globalStyles.main_container}>
                <Header navigation={this.props.navigation}>
                    CGU
                </Header>
                <ScrollView
                    contentContainerStyle={{
                        flexGrow: 1
                    }}
                >
                    <View style={{ paddingHorizontal: '5%' }}>
                        <View style={styles.cgu_paragraphe}>
                            <CustomTitle style={styles.cgu_title} >
                                Introduction
                            </CustomTitle>
                            <CustomText style={styles.cgu_text}>
                                {`Les présentes conditions générales d'utilisation (CGU) ont pour objet l'encadrement juridique des modalités de mise à disposition de l'application web Tankō par PLANETE VERTE CONSULTING à l'adresse https://app.tanko.app et de définir les conditions d'accès et d'utilisation des services proposés par l'application.
Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment.`}
                            </CustomText>
                        </View>

                        <View style={styles.cgu_paragraphe}>
                            <CustomTitle style={styles.cgu_title} >
                                Editeur de l'application
                            </CustomTitle>
                            <CustomText style={styles.cgu_text} >
                                {`PLANETE VERTE CONSULTING - SARL au capital de 1 000,00 €
37 rue d'Amsterdam 75008 Paris
Responsable de publication : Jean-Patrick TEYSSAIRE
Téléphone : 01 81 51 71 10`}
                            </CustomText>
                            <TouchableOpacity style={{ flexDirection: 'row', flexWrap: 'wrap' }} onPress={() => sendMail("contact@tanko.app")}>
                                <CustomText style={styles.cgu_text}>
                                    {'E-mail : '}
                                </CustomText>
                                <CustomText style={styles.cgu_link} >
                                    contact@tanko.app
                                </CustomText>
                            </TouchableOpacity>
                            <CustomText style={styles.cgu_text} >
                                {`Numéro SIRET : 84425212200016`}
                            </CustomText>

                        </View>

                        <View style={styles.cgu_paragraphe}>
                            <CustomTitle style={styles.cgu_title} >
                                Hébergement
                            </CustomTitle>
                            <CustomText style={styles.cgu_text} >
                                Firebase
                            </CustomText>
                            <TouchableOpacity style={{ flexDirection: 'row', flexWrap: 'wrap' }} onPress={() => window.open("https://policies.google.com/?hl=fr")}>

                                <CustomText style={styles.cgu_link} >
                                    https://policies.google.com/?hl=fr
                                </CustomText>
                            </TouchableOpacity>
                        </View>

                        <View style={styles.cgu_paragraphe}>
                            <CustomTitle style={styles.cgu_title} >
                                Développement
                            </CustomTitle>
                            <CustomText style={styles.cgu_text} >
                                {`ARN Software représentée par M. ARNOULT Christopher`}
                            </CustomText>
                            <TouchableOpacity style={{ flexDirection: 'row', flexWrap: 'wrap' }} onPress={() => window.open("https://www.arntech.fr/arnsoftware")}>
                                <CustomText style={styles.cgu_link} >
                                    https://www.arntech.fr/arnsoftware
                                </CustomText>
                            </TouchableOpacity>
                            <TouchableOpacity style={{ flexDirection: 'row', flexWrap: 'wrap' }} onPress={() => sendMail("contact@arnsoftware.fr")}>
                                <CustomText style={styles.cgu_text}>
                                    {'E-mail : '}
                                </CustomText>
                                <CustomText style={styles.cgu_link} >
                                    contact@arnsoftware.fr
                                </CustomText>
                            </TouchableOpacity>
                        </View>

                        <View style={styles.cgu_paragraphe}>

                            <CustomTitle style={styles.cgu_title} >
                                Propriété intellectuelle
                            </CustomTitle>
                            <CustomText style={styles.cgu_text} >
                                {`Sauf mention contraire, tous les éléments accessibles sur l'application, incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de PLANETE VERTE CONSULTING à l’exception des marques, logos ou contenus appartenant à d’autres sociétés partenaires ou auteurs.

Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l’accord par écrit de PLANETE VERTE CONSULTING. Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle. Le non-respect de cette interdiction constitue une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur. En outre, les propriétaires des contenus copiés pourraient intenter une action en justice à l'encontre du contrefacteur.`}
                            </CustomText>

                        </View>

                        <View style={styles.cgu_paragraphe}>

                            <CustomTitle style={styles.cgu_title} >
                                Responsabilité
                            </CustomTitle>
                            <CustomText style={styles.cgu_text} >
                                {`Les sources des informations diffusées sur l'application Tankō sont réputées fiables mais l'application ne garantit pas qu'elle soit exempte de défauts, d'erreurs ou d'omissions.

Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré des mises à jour régulières, PLANTE VERTE CONSULTING ne peut être tenu responsable de la modification des dispositions administratives et juridiques survenant après la publication. De même, PLANTE VERTE CONSULTING ne peut être tenue responsable de l'utilisation et de l'interprétation de l'information contenue dans celle-ci.

PLANTE VERTE CONSULTING ne peut être tenue pour responsable d'éventuels virus qui pourraient infecter l'appareil ou tout matériel informatique de l'utilisateur, suite à une utilisation, à l'accès, ou au téléchargement provenant de cette application.

La responsabilité de PLANTE VERTE CONSULTING ne peut être engagée en cas de force majeure ou d'un fait imprévisible et insurmontable d'un tiers.`}
                            </CustomText>
                        </View>

                        <View style={styles.cgu_paragraphe}>

                            <CustomTitle style={styles.cgu_title} >
                                Liens hypertextes
                            </CustomTitle>
                            <CustomText style={styles.cgu_text} >
                                {`Des liens vers des sites internet ou des ressources disponibles sur internet peuvent être présents sur l'application Tankō. PLANETE VERTE CONSULTING ne dispose d’aucun moyen pour contrôler les sites en connexion avec ses applications.

PLANETE VERTE CONSULTING ne répond pas de la disponibilité de tels sites et sources externes, ni ne la garantit. PLANTE VERTE CONSULTING ne peut être tenue pour responsable de tout dommage, de quelque nature que ce soit, résultant du contenu de ces sites ou sources externes, et notamment des informations, produits ou services qu’ils proposent, ou de tout usage qui peut être fait de ces éléments. Les risques liés à cette utilisation incombent pleinement à l'utilisateur, qui doit se conformer à leurs conditions d’utilisation.`}
                            </CustomText>

                        </View>

                        <View style={styles.cgu_paragraphe}>
                            <CustomTitle style={styles.cgu_title} >
                                Confidentialité
                            </CustomTitle>
                            <TouchableOpacity onPress={() => this.props.navigation.navigate("PolitiqueConfidentialite")}>
                                <CustomText style={styles.cgu_link}>
                                    Nos règles de confidentialité sont décrites dans la Politique de confidentialité.
                                </CustomText>
                            </TouchableOpacity>
                        </View>
                    </View>
                </ScrollView>
            </View>
        );
    }
}

export default CGU;
