import React from "react";
import { View } from "react-native";
import CustomButton from "../../CustomComponents/CustomButton";
import Header from "../Headers/Header";
import { connect } from "react-redux";
import {
  displayLoading,
  handleBack,
  roles,
  setSnackbar,
  validatePassword,
} from "../../../utilities/utils";
import FloatingLabelInput from "../../CustomComponents/FloatingLabelInput";
import { createUserToDatabase, updateUserToDatabase } from "../../../db/db";
import { globalStyles } from "../../../styles/styles";
import { DEBUG } from "../../../api/constants";
import CustomSelect from "../../CustomComponents/CustomSelect";

const bcrypt = require("bcryptjs");

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoading2: false,
      user: {
        password: "",
        identifiant: "",
        role: roles.utilisateur.role,
        type: "read",
        color: "green",
      },
      nomInfoLabel: "",
      prenomInfoLabel: "",
      telephoneInfoLabel: "",
      emailInfoLabel: "",
      identifiantInfoLabel: "",
      passwordInfoLabel: "",
      secureTextEntry: true,
      mode: "creation",
      typeOptions: [
        { id: "read", label: "Lecteur" },
        { id: "edit", label: "Editeur" },
      ],
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.navigation.addListener("blur", () => {
      window.removeEventListener("popstate", this.listener);
    });

    this.unsubscribe2 = this.props.navigation.addListener("focus", () => {
      setTimeout(() => {
        window.addEventListener(
          "popstate",
          (this.listener = (event) => handleBack(event, this))
        );
      }, 1);
    });

    if (this.props.route?.params?.user && this.props.route.params.user) {
      let user = this.props.route.params.user;
      user.password = "";
      this.setState({ user: user, mode: "modification", isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.listener);

    if (this.unsubscribe) this.unsubscribe();
    if (this.unsubscribe2) this.unsubscribe2();
  }

  _validForm() {
    if (
      this.state.user.identifiant.length === 0 ||
      !validatePassword(this.state.user.password)
    ) {
      if (this.state.user.identifiant.length === 0) {
        this.setState({
          identifiantInfoLabel: "Paramètre obligatoire",
        });
      }

      if (!validatePassword(this.state.user.password)) {
        this.setState({
          passwordInfoLabel:
            "8 caractères minimum, au moins 1 majuscule et 1 chiffre",
        });
      }

      return false;
    }

    if (!this.props.isConnectedToNetwork) {
      setSnackbar("error", "Erreur réseau", this.props);
      return false;
    }

    return true;
  }

  _createUser() {
    if (this.state.isLoading2) {
      return;
    }
    this.setState({
      isLoading2: true,
      nomInfoLabel: "",
      prenomInfoLabel: "",
      telephoneInfoLabel: "",
      emailInfoLabel: "",
      identifiantInfoLabel: "",
      passwordInfoLabel: "",
    });

    if (!this._validForm()) {
      this.setState({ isLoading2: false });
      return;
    }

    setTimeout(() => {
      let user = { ...this.state.user };

      let salt = bcrypt.genSaltSync(14);
      let hash = bcrypt.hashSync(this.state.user.password, salt);

      user.password = hash;
      user.deleted = false;

      if (
        this.props.users.find(
          (e) => e.identifiant === this.state.user.identifiant
        ) !== undefined &&
        (this.props.route?.params?.user === undefined ||
          this.props.route.params.user.identifiant !==
            this.state.user.identifiant)
      ) {
        setSnackbar("error", "Cet identifiant est déjà utilisé", this.props);

        this.setState({ isLoading2: false });
        return;
      }

      if (this.state.mode === "creation") {
        createUserToDatabase(user, this.props)
          .then(() => {
            setSnackbar("success", "Utilisateur créé avec succès", this.props);
            this.props.navigation.goBack();
          })
          .catch((error) => {
            setSnackbar(
              "error",
              error === "No network"
                ? "Erreur réseau"
                : "Une erreur est survenue. Veuillez réessayer plus tard",
              this.props
            );
            if (DEBUG) console.warn(error);
            setTimeout(() => {
              this.setState({ isLoading2: false });
            }, 100);
          });
      } else {
        updateUserToDatabase(user, this.props)
          .then(() => {
            setSnackbar(
              "success",
              "Utilisateur modifié avec succès",
              this.props
            );
            this.props.navigation.goBack();
          })
          .catch((error) => {
            setSnackbar(
              "error",
              error === "No network"
                ? "Erreur réseau"
                : "Une erreur est survenue. Veuillez réessayer plus tard",
              this.props
            );
            if (DEBUG) console.warn(error);
            setTimeout(() => {
              this.setState({ isLoading2: false });
            }, 100);
          });
      }
    }, 100);
  }

  _secureTextEntryOnOff() {
    this.setState({ secureTextEntry: !this.state.secureTextEntry });
  }

  render() {
    return (
      <View style={{ flex: 1 }}>
        <Header navigation={this.props.navigation}>
          {this.state.mode === "creation"
            ? "Nouvel utilisateur"
            : "Modification utilisateur"}
        </Header>

        {displayLoading(this.state.isLoading2)}
        {this.state.isLoading ? (
          displayLoading(this.state.isLoading)
        ) : (
          <View style={globalStyles.list_container}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5%",
                paddingTop: 8,
                opacity: this.state.isLoading2 ? 0.5 : 1,
                overflowY: "scroll",
                scrollbarWidth: "none",
              }}
            >
              <View>
                <form onSubmit={(event) => event.preventDefault()}>
                  <div style={{ display: "none" }}>
                    <input
                      type="text"
                      id="PreventChromeAutocomplete"
                      name="PreventChromeAutocomplete"
                      autoComplete="address-level4"
                    />
                  </div>
                  <FloatingLabelInput
                    label="Identifiant"
                    onChange={(value) =>
                      this.setState((prevState) => ({
                        user: {
                          ...prevState.user,
                          identifiant: value,
                        },
                      }))
                    }
                    value={this.state.user.identifiant}
                    maxLength={40}
                    helperText={this.state.identifiantInfoLabel}
                  />

                  <FloatingLabelInput
                    autoComplete="new-password"
                    password
                    label={"Mot de passe"}
                    onChange={(value) =>
                      this.setState((prevState) => ({
                        user: {
                          ...prevState.user,
                          password: value,
                        },
                      }))
                    }
                    value={this.state.user.password}
                    helperText={this.state.passwordInfoLabel}
                    showPassword={!this.state.secureTextEntry}
                    type={!this.state.secureTextEntry ? "text" : "password"}
                    handleClickShowPassword={() => this._secureTextEntryOnOff()}
                    onSubmit={() => this._createUser()}
                    enterkeyhint="go"
                  />

                  <CustomSelect
                    label={"Type"}
                    value={this.state.user.type}
                    onChange={(value) =>
                      this.setState((prevState) => ({
                        user: {
                          ...prevState.user,
                          type: value.id,
                        },
                      }))
                    }
                    options={this.state.typeOptions}
                  />
                </form>
              </View>
              <View
                style={{
                  paddingVertical: 10,
                  alignSelf: "center",
                }}
              >
                <CustomButton
                  title={"Valider"}
                  onPress={() => this._createUser()}
                />
              </View>
            </div>
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.setLanguage.language,
    users: state.setData.users,
    isConnectedToNetwork: state.setConnection.isConnectedToNetwork,
    accountAccessKey: state.setAccount.accountAccessKey,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
