//PHP files url
export const ENDPOINT = "https://tanko.app/";

export const ADDRESS = ENDPOINT + "wp-content/tanko-webapp/";

//Requests timeout
export const TIMEOUT = 15000;

export const authorization = "Zc.:{H5Up6n8";

export const aesSecretKey =
  "NcRfTjWnZr4u7x!A%D*G-KaPdSgVkXp2s5v8y/B?E(H+MbQeThWmZq3t6w9z$C&F)J@NcRfUjXn2r5u8x!A%D*G-KaPdSgVkYp3s6v9y$B?E(H+MbQeThWmZq4t7w!z%";

export const DEBUG = false;

export const JWT_ENDPOINT =
  "https://us-central1-zinc-quest-363211.cloudfunctions.net/";
