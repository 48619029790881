import React from "react";
import { View, Dimensions } from "react-native";
import CustomText from "../components/CustomComponents/CustomText";
import { colors, globalStyles, primaryColor } from "../styles/styles";
import { isAndroid, isBrowser, isIOS } from "react-device-detect";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import { signOutDB } from "../db/db";
import firebase from "firebase/app";
import "firebase/functions";
import { DEBUG, TIMEOUT } from "../api/constants";
import Cookies from "js-cookie";
import localforage from "localforage";

export const roles = {
  gestionnaire: {
    role: "Administrateur",
  },
  utilisateur: {
    role: "Utilisateur",
  },
};

export const abonnementsList = [
  "administrator",
  "subscriber",
  "s2member_level1",
  "s2member_level2",
  "s2member_level3",
  "s2member_level4",
  "s2member_level5",
];

export const abonnements = [
  {
    label: "administrator",
    nb_users: 999999,
    nb_users_label: "illimité",
  },
  {
    label: "subscriber",
    nb_users: 999999,
    nb_users_label: "illimité",
    nb_contacts: 20,
    nb_contacts_label: "20",
  },
  {
    label: "s2member_level1",
    nb_users: 2,
    nb_users_label: "2",
  },
  {
    label: "s2member_level2",
    nb_users: 4,
    nb_users_label: "4",
  },
  {
    label: "s2member_level3",
    nb_users: 10,
    nb_users_label: "10",
  },
  {
    label: "s2member_level4",
    nb_users: 100,
    nb_users_label: "100",
  },
  {
    label: "s2member_level5",
    nb_users: 999999,
    nb_users_label: "illimité",
  },
];

export function getNbUsersLabelByAbonnement(abonnement) {
  const abo = abonnements.find((abo) => abo.label === abonnement);
  if (abo) {
    return abo.nb_users_label;
  } else {
    return "";
  }
}

export function getNbContactsLabelByAbonnement(abonnement) {
  const abo = abonnements.find((abo) => abo.label === abonnement);
  if (abo) {
    return abo.nb_contacts_label;
  } else {
    return "";
  }
}

export function getNbUsersByAbonnement(abonnement) {
  const abo = abonnements.find((abo) => abo.label === abonnement);
  if (abo) {
    return abo.nb_users;
  } else {
    return 9999999;
  }
}

export function getNbContactsByAbonnement(abonnement) {
  const abo = abonnements.find((abo) => abo.label === abonnement);
  if (abo) {
    return abo.nb_contacts;
  } else {
    return 9999999;
  }
}

export function getColor(colorNumber, props) {
  if (props.currentUser && props.currentUser.color) {
    let color = colors.find((color) => color.name === props.currentUser.color);
    return color === undefined ? primaryColor : color[colorNumber];
  } else {
    return primaryColor;
  }
}

export function hideKeyboard() {
  var field = document.createElement("input");
  field.setAttribute("type", "text");
  field.setAttribute(
    "style",
    "position:absolute; top: 0px; opacity: 0; -webkit-user-modify: read-write-plaintext-only; left:0px;"
  );
  document.body.appendChild(field);

  field.onfocus = function () {
    setTimeout(function () {
      field.setAttribute("style", "display:none;");
      setTimeout(function () {
        document.body.removeChild(field);
        document.body.focus();
      }, 14);
    }, 100);
  };
  field.focus();
}

export function handleBack(event, scope) {
  if (event.state && event.state.noBackExitsApp) {
    if (
      scope.props.scope?.state?.isDialogVisible8 &&
      scope.props.scope?.state?.canCloseDialogVisible8
    ) {
      scope.props.scope.props.closeDialog8();
    } else if (
      scope.props.scope?.state?.isDialogVisible7 &&
      scope.props.scope?.state?.canCloseDialogVisible7
    ) {
      scope.props.scope.props.closeDialog7();
    } else if (
      scope.props.scope?.state?.isDialogVisible6 &&
      scope.props.scope?.state?.canCloseDialogVisible6
    ) {
      scope.props.scope.props.closeDialog6();
    } else if (
      scope.props.scope?.state?.isDialogVisible5 &&
      scope.props.scope?.state?.canCloseDialogVisible5
    ) {
      scope.props.scope.props.closeDialog5();
    } else if (
      scope.props.scope?.state?.isDialogVisible4 &&
      scope.props.scope?.state?.canCloseDialogVisible4
    ) {
      scope.props.scope.props.closeDialog4();
    } else if (
      scope.props.scope?.state?.isDialogVisible3 &&
      scope.props.scope?.state?.canCloseDialogVisible3
    ) {
      scope.props.scope.props.closeDialog3();
    } else if (
      scope.props.scope?.state?.isDialogVisible2 &&
      scope.props.scope?.state?.canCloseDialogVisible2
    ) {
      scope.props.scope.props.closeDialog2();
    } else if (
      scope.props.scope?.state?.isDialogVisible &&
      scope.props.scope?.state?.canCloseDialogVisible
    ) {
      scope.props.scope.props.closeDialog();
    } else if (
      scope.state.isDialogVisible8 &&
      scope.state.canCloseDialogVisible8
    ) {
      scope.closeDialog8();
    } else if (
      scope.state.isDialogVisible7 &&
      scope.state.canCloseDialogVisible7
    ) {
      scope.closeDialog7();
    } else if (
      scope.state.isDialogVisible6 &&
      scope.state.canCloseDialogVisible6
    ) {
      scope.closeDialog6();
    } else if (
      scope.state.isDialogVisible5 &&
      scope.state.canCloseDialogVisible5
    ) {
      scope.closeDialog5();
    } else if (
      scope.state.isDialogVisible4 &&
      scope.state.canCloseDialogVisible4
    ) {
      scope.closeDialog4();
    } else if (
      scope.state.isDialogVisible3 &&
      scope.state.canCloseDialogVisible3
    ) {
      scope.closeDialog3();
    } else if (
      scope.state.isDialogVisible2 &&
      scope.state.canCloseDialogVisible2
    ) {
      scope.closeDialog2();
    } else if (
      scope.state.isDialogVisible &&
      scope.state.canCloseDialogVisible
    ) {
      scope.closeDialog();
    } else if (
      (scope.state.isDialogVisible && !scope.state.canCloseDialogVisible) ||
      (scope.state.isDialogVisible2 && !scope.state.canCloseDialogVisible2) ||
      (scope.state.isDialogVisible3 && !scope.state.canCloseDialogVisible3) ||
      (scope.state.isDialogVisible4 && !scope.state.canCloseDialogVisible4) ||
      (scope.state.isDialogVisible5 && !scope.state.canCloseDialogVisible5) ||
      (scope.state.isDialogVisible6 && !scope.state.canCloseDialogVisible6)
    ) {
    } else if (scope.state.forbiddenBack) {
    } else {
      scope.props.navigation.goBack();
    }
    window.history.pushState({ noBackExitsApp: true }, "");
  }
}

export function validateEmail(email) {
  let regex =
    /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

  return regex.test(email);
}

export function getSendinblueTemplatesId(accountAccessKey, props) {
  return promiseTimeout(
    TIMEOUT,
    "getSendinblueTemplatesId",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      firebase
        .functions()
        .httpsCallable("getSendinblueTemplates")({
          accountAccessKey: accountAccessKey,
        })
        .then((response) => {
          let data = JSON.parse(response.data);
          if (!data instanceof Object) {
            JSON.parse(data);
          }

          if (data.message) {
            reject(data.message);
          } else {
            let data = JSON.parse(response.data);

            if (Object.keys(data).length === 0) {
              resolve([]);
            }

            let templatesOptions = data.templates.map((temp) => {
              return { id: temp.id, label: temp.name };
            });

            resolve(templatesOptions);
          }
        })
        .catch((error) => {
          if (DEBUG) console.warn(error);
          reject(error);
        });
    })
  );
}

export function sendMailWithSendinblue(
  address,
  templateId,
  accountAccessKey,
  props
) {
  return promiseTimeout(
    TIMEOUT,
    "sendMailWithSendinblue",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      let addresses = address.map((add) => {
        return { email: add };
      });
      firebase
        .functions()
        .httpsCallable("sendSendinblueMailWithTemplate")({
          accountAccessKey: accountAccessKey,
          dest: addresses,
          templateId: templateId,
        })
        .then((response) => {
          if (response.data === "success") {
            resolve(addresses.length);
          } else {
            reject(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  );
}

export function sendMail(address) {
  window.open(`mailto:${address}`);
}

export function sendMessage(numero) {
  window.open(`sms:${numero}`);
}

export function getList(id, props, tri) {
  const list = props.lists.find((list) => list.id === id);

  return list !== undefined ? list.list : tri ? undefined : [];
}

export function getAllValueFromChamp(champId, contacts) {
  let returnList = [];
  contacts.forEach((contact) => {
    const valToAdd =
      contact[champId] instanceof Date
        ? moment(contact[champId]).format("DD/MM/YYYY")
        : contact[champId];
    if (valToAdd !== "" && !returnList.includes(valToAdd))
      returnList.push(valToAdd);
  });

  return returnList;
}

export function validatePassword(password) {
  if (password.length < 8) {
    return false;
  }
  var strongRegex = new RegExp("^(?=.*[A-Z])(?=.*[0-9])");

  return strongRegex.test(password);
}

export function getElementPreListIdByLabel(list, listTemp, label) {
  let elem = list.find((ch) => ch.label === label);
  if (elem === undefined) {
    elem = listTemp.find((ch) => ch.label === label);
  }
  return elem !== undefined ? elem.id : "";
}

export function getElementPreListLabelById(list, id, returnLabel) {
  let elem = list.find((ch) => ch.id === id);
  return elem !== undefined
    ? elem.label
    : id === "Aucun"
    ? id
    : returnLabel
    ? returnLabel
    : "";
}

export function equalsIgnoringCase(text, other) {
  return text.localeCompare(other, undefined, { sensitivity: "base" }) === 0;
}

export function isDoublon(contact, contacts) {
  return contacts.some(
    (c) =>
      equalsIgnoringCase(c.Nom, contact.Nom) &&
      equalsIgnoringCase(c.Prénom, contact.Prénom) &&
      contact.Email !== "" &&
      equalsIgnoringCase(c.Email, contact.Email)
  );
}

export function setSnackbar(severity, message, props) {
  let snackbar = {};
  if (message.slice(-1) !== "." && message.slice(-1) !== "!") {
    snackbar.message = message + ".";
  } else {
    snackbar.message = message;
  }
  snackbar.severity = severity;
  snackbar.time = 10000;

  const action = { type: "SET_SNACKBAR", value: snackbar };
  props.dispatch(action);
}

export function userIsGestionnaire(user) {
  return user.role === roles.gestionnaire.role;
}

export function getContactById(id, contacts) {
  return contacts.find((element) => element.id === id);
}

export function parseDate(date) {
  return moment(date, "DD/MM/YYYY");
}

export function getContactNextRelanceByUser(relances, user, contacts) {
  let ret = null;
  relances.forEach((relance) => {
    let list = getAllContactsByRelance(
      relance,
      getAllContactsByResponsable(user, contacts)
    ).sort(function (a, b) {
      return (
        new Date(a["Date de relance"]).getTime() -
        new Date(b["Date de relance"]).getTime()
      );
    });

    list.forEach((contact) => {
      if (ret) return;
      if (moment() < contact["Date de relance"]) {
        ret = contact;
      }
    });
  });

  return ret;
}

export function getAllContactsByRelance(relance, contacts) {
  let returnList = [];
  let diff;

  contacts.forEach((element) => {
    if (relance.time === 999999) {
      if (element["Date de relance"] === "") {
        returnList.push(element);
      }
    } else {
      if (
        element["Date de relance"] instanceof Date &&
        !isNaN(element["Date de relance"])
      ) {
        diff = dateDiff(
          moment(
            element["Date de relance"],
            "DD/MM/YYYY HH:mm",
            "fr",
            true
          ).startOf("day"),
          moment().startOf("day").toDate()
        );
        if (getRelanceText(diff) === relance.text) {
          returnList.push(element);
        }
      }
    }
  });

  returnList.sort(function (a, b) {
    return (
      moment(b["Date de relance"], "DD/MM/YYYY HH:mm", "fr", true).date() -
      moment(a["Date de relance"], "DD/MM/YYYY HH:mm", "fr", true).date()
    );
  });

  return returnList;
}

export function getAllContactsByChamp(champId, champValue, contacts, list) {
  let returnList = [];
  contacts.forEach((element) => {
    let val =
      element[champId] instanceof Date
        ? moment(element[champId]).format("DD/MM/YYYY")
        : element[champId];
    if (val === champValue) {
      returnList.push(element);
    } else if (
      champValue === "Aucun" &&
      (val === "" || !list.map((s) => (s.id ? s.id : s)).includes(val))
    ) {
      returnList.push(element);
    }
  });

  returnList.sort(function (a, b) {
    if (
      a["Nom"]
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "") >
      b["Nom"]
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    ) {
      return 1;
    }

    if (
      a["Nom"]
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "") <
      b["Nom"]
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    ) {
      return -1;
    }
    return 0;
  });

  return returnList;
}

export function getAllContactsByResponsable(responsable, contacts) {
  if (responsable === "Tous") {
    return contacts;
  }

  return contacts.filter((e) => e["Responsable"] === responsable);
}

export function getAllRelanceByResponsable(responsable, contacts) {
  let returnList = [];
  let relanceFuture = [];
  let relancePast = [];
  let relanceWithoutDate = [];
  let diff;
  let currentDate = moment().startOf("day").toDate();

  getAllContactsByResponsable(responsable, contacts).forEach((element) => {
    if (
      element["Date de relance"] instanceof Date &&
      !isNaN(element["Date de relance"])
    ) {
      diff = dateDiff(
        moment(
          element["Date de relance"],
          "DD/MM/YYYY HH:mm",
          "fr",
          true
        ).startOf("day"),
        currentDate
      );

      if (diff <= 0) {
        relanceFuture.push(element);
      } else {
        relancePast.push(element);
      }
    } else {
      relanceWithoutDate.push(element);
    }
  });

  relanceFuture.sort(function (a, b) {
    if (
      a["Nom"]
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "") >
      b["Nom"]
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    ) {
      return 1;
    }

    if (
      a["Nom"]
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "") <
      b["Nom"]
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    ) {
      return -1;
    }
    return 0;
  });

  relancePast.sort(function (a, b) {
    if (
      a["Nom"]
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "") >
      b["Nom"]
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    ) {
      return 1;
    }

    if (
      a["Nom"]
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "") <
      b["Nom"]
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    ) {
      return -1;
    }
    return 0;
  });

  relanceWithoutDate.sort(function (a, b) {
    if (
      a["Nom"]
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "") >
      b["Nom"]
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    ) {
      return 1;
    }

    if (
      a["Nom"]
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "") <
      b["Nom"]
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    ) {
      return -1;
    }
    return 0;
  });

  returnList.push(relanceFuture);
  returnList.push(relancePast);
  returnList.push(relanceWithoutDate);

  return returnList;
}

export function getAllResponsables(responsables, allInclude) {
  let responsableList = [];

  responsableList = responsables
    .filter((r) => r.deleted === false)
    .map((e) => {
      return { id: e.id, label: e.name ? e.name : e.identifiant };
    });

  responsableList.sort(function (a, b) {
    if (
      a["label"]
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "") >
      b["label"]
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    ) {
      return 1;
    }

    if (
      a["label"]
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "") <
      b["label"]
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    ) {
      return -1;
    }
    return 0;
  });

  if (allInclude) {
    responsableList = [{ id: "Tous", label: "Tous" }].concat(responsableList);
  }

  return responsableList;
}

export function sortDate(a, b) {
  return b.label.getTime() - a.label.getTime();
}

export function sortText(a, b) {
  if (
    a.label
      .toUpperCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "") >
    b.label
      .toUpperCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
  ) {
    return 1;
  }

  if (
    a.label
      .toUpperCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "") <
    b.label
      .toUpperCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
  ) {
    return -1;
  }
  return 0;
}

export function getAllChampByContacts(champId, contacts, list) {
  if (list === null) {
    return [];
  }
  let returnList = [];
  list.forEach((value) => {
    let valueLabel = value.label ? value.label : value;
    if (valueLabel instanceof Date) {
      valueLabel = moment(valueLabel).format("DD/MM/YYYY");
    }
    let contactss = getAllContactsByChamp(
      champId,
      value.id ? value.id : valueLabel,
      contacts,
      list
    );
    if (contactss.length > 0)
      returnList.push({
        value: value,
        id: value.id ? value.id : value,
        label: valueLabel,
        contacts: contactss,
        length: contactss.length,
      });
  });

  if (returnList.length > 0 && returnList[0].label instanceof Date) {
    returnList.sort(sortDate);
  } else {
    returnList.sort(sortText);
  }

  let emptyChampContact = getAllContactsByChamp(
    champId,
    "Aucun",
    contacts,
    list
  );
  if (emptyChampContact.length > 0) {
    returnList.push({
      value: "Aucun",
      id: "Aucun",
      label: "Aucun",
      contacts: emptyChampContact,
      length: emptyChampContact.length,
    });
  }

  return returnList;
}

export function getAllContactsNotDeleted(currentContacts, contacts) {
  let returnList = [];
  currentContacts.forEach((element) => {
    if (contacts.find((con) => con.id === element.id) !== undefined) {
      returnList.push(element);
    }
  });

  return returnList;
}

export function signOut(props, force) {
  signOutDB(props);

  let action = { type: "SET_CURRENT_USER", value: {} };
  props.dispatch(action);

  action = { type: "SET_DATA", value: [] };
  props.dispatch(action);

  action = { type: "DATA_IS_IMPORTING", value: false };
  props.dispatch(action);

  action = { type: "SET_USERS", value: [] };
  props.dispatch(action);

  action = { type: "SET_LISTS", value: [] };
  props.dispatch(action);

  resetStorage(true);

  props.navigation.replace("SignInLoadingScreen", {
    force: force !== undefined ? force : false,
  });
}

export function signOutFromSignInLoadingScreen(props, firstTime) {
  signOutDB(props);

  let action = { type: "SET_CURRENT_USER", value: {} };
  props.dispatch(action);

  action = { type: "SET_DATA", value: [] };
  props.dispatch(action);

  action = { type: "DATA_IS_IMPORTING", value: false };
  props.dispatch(action);

  action = { type: "SET_USERS", value: [] };
  props.dispatch(action);

  action = { type: "SET_LISTS", value: [] };
  props.dispatch(action);

  resetStorage(true);

  props.navigation.replace("SignIn", {
    firstTime: firstTime !== undefined ? firstTime : false,
  });
}

export function superSignOut(props) {
  signOutDB(props);

  let action = { type: "SET_DATA", value: [] };
  props.dispatch(action);

  action = { type: "DATA_IS_IMPORTING", value: false };
  props.dispatch(action);

  action = { type: "SET_USERS", value: [] };
  props.dispatch(action);

  action = { type: "SET_CURRENT_USER", value: {} };
  props.dispatch(action);

  action = { type: "SET_LISTS", value: [] };
  props.dispatch(action);

  action = { type: "SET_LANGUAGE", value: "fr" };
  props.dispatch(action);

  action = { type: "SET_CONNECTION", value: true };
  props.dispatch(action);

  action = { type: "SET_ACCOUNT_ACCESS_KEY", value: "" };
  props.dispatch(action);

  action = { type: "SET_ACCOUNT_ID", value: "" };
  props.dispatch(action);

  action = { type: "SET_ACCOUNT_ABONNEMENT", value: "" };
  props.dispatch(action);

  action = { type: "SET_ACCOUNT_ABONNEMENT_EOT_TIME", value: "" };
  props.dispatch(action);

  action = { type: "SET_ACCOUNT_SETTINGS", value: {} };
  props.dispatch(action);

  action = { type: "SET_ACCOUNT_ICON", value: "" };
  props.dispatch(action);

  action = { type: "SET_ACCOUNT_DELETED", value: false };
  props.dispatch(action);

  resetStorage(true);

  props.navigation.replace("SuperSignIn");
}

export function getRelanceText(diff) {
  let text = "";
  if (diff === 0) {
    text = "Aujourd'hui";
  } else if (diff < 0) {
    if (-diff > 14) {
      text = "Plus de 2 semaines";
    } else if (-diff > 7) {
      text = "Plus de 1 semaine";
    } else if (-diff === 1) {
      text = "Demain";
    } else {
      text = "Dans " + -diff + " jours";
    }
  } else {
    if (diff > 14) {
      text = "Expiré plus de 2 semaines";
    } else if (diff > 7) {
      text = "Expiré plus de 1 semaine";
    } else if (diff === 1) {
      text = "Expiré hier";
    } else {
      text = "Expiré il y a " + diff + " jours";
    }
  }
  return text;
}

export function displayLoading(isLoading, message) {
  if (isLoading) {
    return (
      <View style={globalStyles.loading_container}>
        <div
          className="noselect"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary2" />
          {message ? (
            <CustomText style={{ marginTop: 30 }}>{message}</CustomText>
          ) : (
            <></>
          )}
        </div>
      </View>
    );
  }
}

export function renderNoNetworkNotification() {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CustomText>Erreur réseau.</CustomText>
      <CustomText>Vérifiez votre connexion internet.</CustomText>
    </View>
  );
}

export function dateDiff(date1, date2) {
  return Math.round((date2 - date1) / (1000 * 60 * 60 * 24));
}

export function getRealHeight() {
  return Dimensions.get("window").height;
}

export function getRealWidth() {
  if (isBrowser && !isAndroid && !isIOS) {
    return 414;
  } else {
    return Dimensions.get("window").width;
  }
}

export function isTablet() {
  if (
    !(isBrowser && !isAndroid && !isIOS) &&
    Dimensions.get("window").width >= 600
  ) {
    return true;
  } else {
    return false;
  }
}

export function promiseTimeout(ms, name, promise) {
  let timeout = new Promise((resolve, reject) => {
    let id = setTimeout(() => {
      clearTimeout(id);
      reject("Timed out : " + name);
    }, ms);
  });

  return Promise.race([promise, timeout]);
}

export function promiseTimeoutNoError(ms, name, promise) {
  let timeout = new Promise((resolve, reject) => {
    let id = setTimeout(() => {
      clearTimeout(id);
      resolve("");
    }, ms);
  });

  return Promise.race([promise, timeout]);
}

export async function getCurrentToken() {
  const user = await localforage.getItem("user");
  try {
    return JSON.parse(user);
  } catch (err) {
    return "";
  }
}

export function setCurrentToken(token) {
  localforage.setItem("user", JSON.stringify(token));
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export function resetStorage(soft) {
  try {
    deleteAllCookies();
    localforage.clear();
    Cookies.remove("refresh_token");

    if (!soft) {
      window.indexedDB.databases().then((dbs) => {
        dbs.forEach((db) => {
          window.indexedDB.deleteDatabase(db.name);
        });
      });
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }
  } catch (err) {
    console.warn(err);
  }
}

export function excelIndexToCol(column) {
  var temp,
    letter = "";
  while (column > 0) {
    temp = (column - 1) % 26;
    letter = String.fromCharCode(temp + 65) + letter;
    column = (column - temp - 1) / 26;
  }
  return letter;
}
