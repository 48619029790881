import React from 'react';
import { connect } from 'react-redux';
import { View } from 'react-native';
import Header from '../Headers/Header';
import CustomList from '../../CustomComponents/CustomList';
import { abonnements, displayLoading, getNbUsersByAbonnement, getNbUsersLabelByAbonnement, handleBack, setSnackbar, signOut } from '../../../utilities/utils';
import UsersItem from './UsersItem';
import CustomText from '../../CustomComponents/CustomText';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import SettingsIcon from '@mui/icons-material/Settings';
import { Fab, IconButton } from '@mui/material';
import { updateUserToDatabase } from '../../../db/db';
import DialogAlert from '../../CustomComponents/DialogAlert';
import { floatingLabelTextFontsize, globalStyles, textFontSize } from '../../../styles/styles';
import DoneIcon from '@mui/icons-material/Done';
import LogoutIcon from '@mui/icons-material/Logout';
import { DEBUG } from '../../../api/constants';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isLoading2: false,
            isDialogVisible: false,
            canCloseDialogVisible: true,
            isDialogVisible2: false,
            canCloseDialogVisible2: true,
            isDialogVisible3: false,
            canCloseDialogVisible3: true,
            isDialogVisible4: false,
            canCloseDialogVisible4: true,
            isDialogVisible5: false,
            canCloseDialogVisible5: true,
            users: [],
            selectedUser: {},
            tooManyUsersMode: false,
            listHeight: 0,
            listWidth: 0,
            reload: 0
        };
    }

    closeDialog = () => {
        this.setState({ isDialogVisible: false })
    }

    closeDialog2 = () => {
        this.setState({ isDialogVisible2: false })
    }

    closeDialog3 = () => {
        this.setState({ isDialogVisible3: false })
    }

    closeDialog4 = () => {
        this.setState({ isDialogVisible4: false })
    }


    closeDialog5 = () => {
        this.setState({ isDialogVisible5: false })
    }

    componentDidMount() {

        this.unsubscribe = this.props.navigation.addListener('blur', () => {
            window.removeEventListener('popstate', this.listener)
        })

        this.unsubscribe2 = this.props.navigation.addListener('focus', () => {
            setTimeout(() => {
                window.addEventListener('popstate', this.listener = (event) => handleBack(event, this));
            }, 1)
        })


        this.setState({ tooManyUsersMode: this.props.route?.params?.tooManyUsers !== undefined, forbiddenBack: this.props.route?.params?.tooManyUsers === true, isDialogVisible3: this.props.route?.params?.tooManyUsers !== undefined })
        this._loadUsers()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.users !== this.props.users) {
            this._loadUsers()
        }
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.listener)

        if (this.unsubscribe)
            this.unsubscribe()
        if (this.unsubscribe2)
            this.unsubscribe2()
    }


    _loadUsers() {
        this.setState({ isLoading: true })

        let users = this.props.users.filter(e => !e.deleted)
        this.setState({ users: users, reload: new Date().getTime(), isLoading: false })
    }

    _deleteUser() {
        if (this.state.isLoading2) {
            return;
        }

        this.setState({ isLoading2: true })

        let user = this.props.users.find(e => e.identifiant === this.state.selectedUser.identifiant)

        user.deleted = true

        updateUserToDatabase(user, this.props).then(() => {
            setSnackbar('success', 'Utilisateur supprimé avec succès', this.props)
        }).catch((error) => {
            setSnackbar('error', error === "No network" ? "Erreur réseau" : 'Une erreur est survenue. Veuillez réessayer plus tard', this.props)
            if (DEBUG) console.warn(error)
        }).finally(() => {
            this.setState({ isLoading2: false, isDialogVisible5: false })
        })
    }


    _displayDetailForUser = (userId, userName) => {
        if (this.state.tooManyUsersMode) {
            return;
        }
        this.props.navigation.navigate('ResponsableSubList', {
            userId: userId,
            userName: userName
        });
    };

    _renderItem = (item) => {
        return (
            <UsersItem
                user={item}
                navigation={this.props.navigation}
                displayDetailForUser={this._displayDetailForUser}
                openDeleteDialog={(user) => this.setState({ isDialogVisible: true, selectedUser: user })}
                editUser={(user) => this.props.navigation.navigate("AddUser", { user: user })}
            />
        );
    };

    _addUser() {
        if (this.state.users.length === getNbUsersByAbonnement(this.props.abonnement)) {
            this.setState({ isDialogVisible2: true })
        } else {
            this.props.navigation.navigate('AddUser')
        }
    }

    _displayList() {
        if (this.state.users.length === 0) {
            return (
                <View
                    style={{
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingHorizontal: '5%'
                    }}
                >
                    <CustomText>Aucun utilisateur enregistré.</CustomText>
                </View>
            );
        } else {
            return (
                <View key={this.state.reload} onLayout={(event) => this.setState({ listHeight: event.nativeEvent.layout.height, listWidth: event.nativeEvent.layout.width })} style={{ flex: 1 }}>
                    <View style={{ padding: 12 }}>
                        <CustomText textType='bold' style={{ fontSize: floatingLabelTextFontsize }}>{this.props.abonnement === abonnements.find(abo => abo.label === "subscriber").label ? "Utilisateurs : " + this.state.users.length : "Utilisateurs : " + this.state.users.length + " / " + getNbUsersLabelByAbonnement(this.props.abonnement)} </CustomText>
                    </View>
                    <CustomList
                        listWidth={this.state.listWidth}
                        listHeight={this.state.listHeight}
                        datas={this.state.users}
                        renderItem={this._renderItem}
                        addButton={!this.state.tooManyUsersMode}
                    />
                </View>
            );
        }
    }

    render() {
        return (
            <View style={globalStyles.main_container}>
                <div id="userContainer" style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                    <Header
                        headerId="userHeader"
                        //navigation={this.props.navigation.canGoBack() ? this.props.navigation : false}
                        iconLeft={this.props.settings.icone}
                        icon={
                            <div>
                                {this.state.tooManyUsersMode ?
                                    <IconButton
                                        color='secondary'
                                        title='Déconnexion'
                                        onClick={() => {
                                            this.setState({ isDialogVisible4: true })
                                        }}
                                        size='medium'
                                    >
                                        <LogoutIcon
                                            fontSize={'medium'}
                                        />
                                    </IconButton> : <></>}
                                {!this.state.tooManyUsersMode ?
                                    <IconButton
                                        color='secondary'
                                        title="Paramètres"
                                        onClick={() => this.props.navigation.navigate("Settings")}
                                        size='medium'
                                    >
                                        <SettingsIcon
                                            fontSize={'medium'}
                                        />
                                    </IconButton> : <></>}
                            </div>
                        }
                    >
                        mon_equipe_title
                    </Header>
                    <DialogAlert
                        isDialogVisible={this.state.isDialogVisible}
                        onClose={() => {
                            this.closeDialog()
                        }}
                        title={"Supprimer un utilisateur"}
                        text={'Etes-vous sûr de vouloir supprimer cet utilisateur ?'}
                        confirm={() => this.setState({ isDialogVisible: false, isDialogVisible5: true })}
                        cancel={() => {
                            this.closeDialog()
                        }}
                    />

                    <DialogAlert
                        isDialogVisible={this.state.isDialogVisible5}
                        onClose={() => {
                            this.closeDialog5()
                        }}
                        title={"Confirmation"}
                        confirm={() => this._deleteUser()}
                        cancel={() => {
                            this.closeDialog5()
                        }}
                        content={<View>
                            <WarningAmberIcon
                                color='warning'
                                fontSize={'large'}
                                style={{ marginBottom: 7, textAlign: 'center', alignSelf: 'center', justifyContent: 'center' }}
                            />
                            <CustomText
                                style={{ fontSize: textFontSize - 2 }}
                            >
                                Etes-vous certain de vouloir supprimer cet utilisateur ? Il sera impossible de le récupérer.
                            </CustomText>
                        </View>}
                    />

                    <DialogAlert
                        isDialogVisible={this.state.isDialogVisible2}
                        onClose={() => {
                            this.closeDialog2()
                        }}
                        title={"Impossible d'ajouter un utilisateur"}
                        text={"Votre abonnement ne vous permet pas d'ajouter un utilisateur supplémentaire. Veuillez mettre à jour votre abonnement afin d'augmenter le nombre maximum d'utilisateur autorisé."}
                        validText="Accéder au site"
                        cancelText="Ok"
                        confirm={() => {
                            window.open("https://www.tanko.app");
                            this.setState({ isDialogVisible2: false });

                        }}
                        cancel={() => {
                            this.closeDialog2()
                        }}
                    />
                    <DialogAlert
                        isDialogVisible={this.state.isDialogVisible3}
                        onClose={() => {
                            this.closeDialog3()
                        }}
                        title={"Nombre d'utilisateurs maximum dépassé"}
                        text={
                            "La limite du nombre d'utilisateur autorisé dans votre abonnement est dépassée. Veuillez supprimer des utilisateurs ou mettre à jour votre abonnement afin de continuer à utiliser l'application."
                        }
                        validText="Accéder au site"
                        cancelText="Ok"

                        confirm={() => {
                            window.open("https://www.tanko.app");
                            this.setState({ isDialogVisible3: false });

                        }}
                        cancel={() => {
                            this.closeDialog3()
                        }}
                    />
                    <DialogAlert
                        isDialogVisible={this.state.isDialogVisible4}
                        onClose={() => {
                            this.closeDialog4()
                        }}
                        title={'Deconnexion'}
                        text={'Etes-vous sûr de vouloir vous déconnecter ?'}
                        confirm={() => signOut(this.props)}
                        cancel={() => {
                            this.closeDialog4()
                        }}
                    />
                    {displayLoading(this.state.isLoading2)}
                    {this.state.isLoading ? displayLoading(this.state.isLoading) :
                        <View style={globalStyles.list_container}>
                            {this._displayList()}
                            {this.state.tooManyUsersMode && this.props.users.filter(us => us.deleted === false).length <= getNbUsersByAbonnement(this.props.abonnement) ?

                                <View style={{ position: 'absolute', right: 15, bottom: 15 }}>
                                    <Fab onClick={() => this.props.navigation.goBack()} color="primary" title="Valider" >
                                        <DoneIcon style={{ fontSize: 25, color: 'white' }} />
                                    </Fab>
                                </View> : <></>}

                            {this.state.tooManyUsersMode ? <></> :
                                <View style={{ position: 'absolute', right: 15, bottom: 15 }}>
                                    <Fab onClick={() => this._addUser()} color="primary" title="Ajouter un utilisateur" >
                                        <PersonAddAlt1Icon style={{ fontSize: 25, color: 'white' }} />
                                    </Fab>
                                </View>}
                        </View>}
                </div>
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        users: state.setData.users,
        isConnectedToNetwork: state.setConnection.isConnectedToNetwork,
        accountAccessKey: state.setAccount.accountAccessKey,
        abonnement: state.setAccount.abonnement,
        currentUser: state.setData['currentUser'],
        settings: state.setAccount.settings
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch: action => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(
    Users
);
