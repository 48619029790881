import React from 'react';
import { connect } from 'react-redux';
import ImportContact from '../Contact/ImportContact';
import { handleBack } from '../../../utilities/utils';

class PersonnalisationImportContact2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isLoading2: false,
        };
    }

    componentDidMount() {
        this.unsubscribe = this.props.navigation.addListener('blur', () => {
            window.removeEventListener('popstate', this.listener)

        })

        this.unsubscribe2 = this.props.navigation.addListener('focus', () => {
            setTimeout(() => {
                window.addEventListener('popstate', this.listener = (event) => handleBack(event, this));

            }, 1)
        })
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.listener)

        if (this.unsubscribe)
            this.unsubscribe()
        if (this.unsubscribe2)
            this.unsubscribe2()
    }

    render() {
        return (
            <ImportContact
                ws2={this.props.route.params.ws2}
                excelDatas={this.props.route.params.excelDatas}
                enTete={this.props.route.params.enTete}
                navigation={this.props.navigation}
                headerTitle='Importation de contacts'
                noNetworkNotification
            >
            </ImportContact>
        );
    }
}

const mapStateToProps = state => {
    return {
        language: state.setLanguage.language,
        users: state.setData.users,
        currentUser: state.setData.currentUser,
        isConnectedToNetwork: state.setConnection.isConnectedToNetwork,
        settings: state.setAccount.settings,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch: action => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonnalisationImportContact2);