import React from 'react';
import { Text, StyleSheet } from 'react-native';
import I18n from '../../i18n/i18n';
import { textFontSize } from '../../styles/styles';

class CustomText extends React.Component {

  render() {
    switch (this.props.textType) {
      case 'bold':
        this.textStyle = styles.bold;
        break;
      case 'light':
        this.textStyle = styles.light;
        break;
      case 'medium':
        this.textStyle = styles.medium;
        break;
      case 'regular':
        this.textStyle = styles.regular;
        break;
      case 'semi_bold':
        this.textStyle = styles.semi_bold;
        break;
      default:
        this.textStyle = styles.regular;
        break;
    }
    return (
      <Text
        {...this.props}
        style={[this.textStyle, styles.font, this.props.style]}
      >
        {this.props.textType === 'italic' ? <i>{I18n.t(this.props.children).substring(0, 8) === '[missing' ? this.props.children : I18n.t(this.props.children)}</i> :
          I18n.t(this.props.children).substring(0, 8) === '[missing' ? this.props.children : I18n.t(this.props.children)}
      </Text>
    );
  }
}

const styles = StyleSheet.create({
  bold: {
    fontFamily: 'Quicksand-Bold',
  },
  light: {
    fontFamily: 'Quicksand-Light',
  },
  medium: {
    fontFamily: 'Quicksand-Medium',
  },
  regular: {
    fontFamily: 'Quicksand-Regular',
  },
  semi_bold: {
    fontFamily: 'Quicksand-SemiBold',
  },
  font: {
    fontSize: textFontSize,
    color: 'black'
  },
});


export default (CustomText);
