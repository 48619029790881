import React from 'react';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import {
  createStackNavigator,
  CardStyleInterpolators,
} from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Relance from '../components/App/Relance/Relance';
import Contact from '../components/App/Contact/Contact';
import ContactCard from '../components/App/Contact/ContactCard';
import ResponsableListByResponsable
  from '../components/App/Responsable/ResponsableListByResponsable';
import {
  browserBackgroundColor,
} from '../styles/styles';
import UpdateContact from '../components/App/Contact/UpdateContact';
import ResponsableSubList from '../components/App/Responsable/ResponsableSubList';
import RelanceListByRelance from '../components/App/Relance/RelanceListByRelance';
import SignIn from '../components/App/SignIn/SignIn';
import SignInLoadingScreen from '../components/App/SignIn/SignInLoadingScreen';
import Settings from '../components/App/Settings/Settings';
import Users from '../components/App/Gestionnaire/Users';
import AddUser from '../components/App/Gestionnaire/AddUser';
import CustomTabBar from './CustomTabBar';
import SuperSignInLoadingScreen from '../components/App/SuperSignIn/SuperSignInLoadingScreen';
import SuperSignIn from '../components/App/SuperSignIn/SuperSignIn';
import ImportContactSettings from '../components/App/Settings/ImportContactSettings';
import PersonnalisationIcone from '../components/App/SignIn/PersonnalisationIcone';
import PersonnalisationImportContact from '../components/App/SignIn/PersonnalisationImportContact';
import PersonnalisationImportContact2 from '../components/App/SignIn/PersonnalisationImportContact2';
import CGU from '../components/App/About/CGU';
import PolitiqueConfidentialite from '../components/App/About/PolitiqueConfidentialite';
import GestionListe from '../components/App/GestionListe/GestionListe';
import PersonnalisationSendinblue from '../components/App/SignIn/PersonnalisationSendinblue';


export default function Navigation(props) {
  const MyTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: browserBackgroundColor,
    },
  };

  return (

    <NavigationContainer theme={MyTheme}>
      {HomeStackScreen(props)}
    </NavigationContainer>

  );
}

const HomeStack = createStackNavigator();
const HomeStackScreen = (props) => (
  <HomeStack.Navigator initialRouteName="SuperSignInLoadingScreen">

    <HomeStack.Screen
      name="SuperSignInLoadingScreen"
      component={SuperSignInLoadingScreen}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />

    <HomeStack.Screen
      name="SuperSignIn"
      component={SuperSignIn}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />


    <HomeStack.Screen
      name="SignInLoadingScreen"
      component={SignInLoadingScreen}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />
    <HomeStack.Screen
      name="SignIn"
      component={SignIn}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />

    <HomeStack.Screen
      name="CGU"
      component={CGU}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />

    <HomeStack.Screen
      name="PolitiqueConfidentialite"
      component={PolitiqueConfidentialite}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />

    {props.currentUser?.identifiant ?
      <>
        <HomeStack.Screen
          name="Personnalisation"
          component={PersonnalisationStackScreen}
          options={{
            header: () => null,
            title: 'Tankō',
          }}
        />

        <HomeStack.Screen
          name="Utilisateurs"
          component={Users}
          options={{
            header: () => null,
            title: 'Tankō',
          }}
        />

        <HomeStack.Screen
          name="TooManyContacts"
          component={Contact}
          options={{
            header: () => null,
            title: 'Tankō',
          }}
        />

        <HomeStack.Screen
          name="TooManyUsers"
          component={Users}
          options={{
            header: () => null,
            title: 'Tankō',
          }}
        />

        <HomeStack.Screen
          name="ContactCard"
          component={ContactCard}
          options={{
            header: () => null,
            title: 'Tankō',
          }}
        />

        <HomeStack.Screen
          name="Contacts"
          options={{
            header: () => null,
            title: 'Tankō',
          }}
        >
          {() => (
            BottomTabs()
          )}
        </HomeStack.Screen>

      </>
      : <></>}
  </HomeStack.Navigator>
);

const PersonnalisationStack = createStackNavigator();
const PersonnalisationStackScreen = () => (
  <PersonnalisationStack.Navigator
    initialRouteName="PersonnalisationIcone"
    screenOptions={{
      cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
    }}
  >
    <PersonnalisationStack.Screen
      name="PersonnalisationIcone"
      component={PersonnalisationIcone}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />

    <PersonnalisationStack.Screen
      name="PersonnalisationSendinblue"
      component={PersonnalisationSendinblue}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />

    <PersonnalisationStack.Screen
      name="PersonnalisationImportContact"
      component={PersonnalisationImportContact}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />
    <PersonnalisationStack.Screen
      name="PersonnalisationImportContact2"
      component={PersonnalisationImportContact2}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />
    <PersonnalisationStack.Screen
      name="GestionListe"
      component={GestionListe}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />
  </PersonnalisationStack.Navigator>
);


const RelanceStack = createStackNavigator();
const RelanceStackScreen = () => (
  <RelanceStack.Navigator
    initialRouteName="Relance"
    screenOptions={{
      cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
    }}
  >
    <RelanceStack.Screen
      name="Relance"
      component={Relance}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />
    <RelanceStack.Screen
      name="RelanceListByRelance"
      component={RelanceListByRelance}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />
    <RelanceStack.Screen
      name="ContactCard"
      component={ContactCard}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />

    <RelanceStack.Screen
      name="UpdateContact"
      component={UpdateContact}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />


    <RelanceStack.Screen
      name="Settings"
      component={SettingsStackScreen}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />
    <RelanceStack.Screen
      name="GestionListe"
      component={GestionListe}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />

  </RelanceStack.Navigator>
);


const SettingsStack = createStackNavigator();
const SettingsStackScreen = () => (
  <SettingsStack.Navigator
    initialRouteName="Settings"
    screenOptions={{
      cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
    }}
  >
    <SettingsStack.Screen
      name="Settings"
      component={Settings}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />
    <SettingsStack.Screen
      name="ImportContactSettings"
      component={ImportContactSettings}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />

    <SettingsStack.Screen
      name="GestionListe"
      component={GestionListe}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />
    <SettingsStack.Screen
      name="CGU"
      component={CGU}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />

    <SettingsStack.Screen
      name="PolitiqueConfidentialite"
      component={PolitiqueConfidentialite}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />
  </SettingsStack.Navigator>
);

const ContactStack = createStackNavigator();
const ContactStackScreen = () => (
  <ContactStack.Navigator
    initialRouteName="Contact"
    screenOptions={{
      cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
    }}
  >
    <ContactStack.Screen
      name="Contact"
      component={Contact}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />
    <ContactStack.Screen
      name="ContactCard"
      component={ContactCard}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />


    <ContactStack.Screen
      name="UpdateContact"
      component={UpdateContact}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />
    <ContactStack.Screen
      name="Settings"
      component={SettingsStackScreen}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />

    <ContactStack.Screen
      name="GestionListe"
      component={GestionListe}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />

  </ContactStack.Navigator>
);


const UtilisateurStack = createStackNavigator();
const UtilisateurStackScreen = () => (
  <UtilisateurStack.Navigator
    initialRouteName="Utilisateur"
    screenOptions={{
      cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
    }}
  >
    <UtilisateurStack.Screen
      name="Utilisateur"
      component={Users}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />
    <UtilisateurStack.Screen
      name="AddUser"
      component={AddUser}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />
    <UtilisateurStack.Screen
      name="ResponsableSubList"
      component={ResponsableSubList}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />

    <UtilisateurStack.Screen
      name="ResponsableListByResponsable"
      component={ResponsableListByResponsable}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />
    <UtilisateurStack.Screen
      name="ContactCard"
      component={ContactCard}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />

    <UtilisateurStack.Screen
      name="GestionListe"
      component={GestionListe}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />
    <UtilisateurStack.Screen
      name="UpdateContact"
      component={UpdateContact}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />


    <UtilisateurStack.Screen
      name="Contact"
      component={Contact}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />


    <UtilisateurStack.Screen
      name="Settings"
      component={SettingsStackScreen}
      options={{
        header: () => null,
        title: 'Tankō',
      }}
    />
  </UtilisateurStack.Navigator>
);



const Tab = createBottomTabNavigator();
const BottomTabs = () => {

  return (
    <Tab.Navigator tabBar={props => <CustomTabBar {...props} />}
      backBehavior='none'
      initialRouteName='Contact'
      tabBarOptions={{
        keyboardHidesTabBar: true
      }}
    >


      <Tab.Screen
        name="Contact"
        component={ContactStackScreen}

      />

      <Tab.Screen
        name="Utilisateurs"
        component={UtilisateurStackScreen}

      />
      <Tab.Screen
        name="Relance"
        component={RelanceStackScreen}

      />
    </Tab.Navigator>
  );
};
