import React from 'react';
import { View } from 'react-native';
import {
  floatingLabelTextFontsize,
  labelFontSize,
  textFontSize
} from '../../styles/styles';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

class FloatingLabelInput extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props)
  }

  render() {
    return (
      <View style={{ paddingBottom: this.props.noPadding ? 0 : 10, paddingTop: 10, justifyContent: 'center' }}>
        <TextField
          autoComplete={this.props.autoComplete ? this.props.autoComplete : 'nope'}
          defaultValue={this.props.defaultValue}
          className='noselect'
          label={this.props.label}
          variant={this.props.variant ? this.props.variant : "outlined"}
          size='small'
          type={this.props.type}
          InputProps={{
            className: 'noselect',
            endAdornment: (
              this.props.password ?
                <InputAdornment position="end">
                  <IconButton
                    onClick={this.props.handleClickShowPassword}
                    onMouseDown={this.props.handleMouseDownPassword}
                    edge="end"
                    style={{ color: 'black' }}
                  >
                    {this.props.showPassword ? <VisibilityOff titleAccess='Cacher' /> : <Visibility titleAccess='Afficher' />}
                  </IconButton>
                </InputAdornment> : null
            )
          }}
          InputLabelProps={{ className: 'noselect', style: { color: 'grey', fontFamily: 'Quicksand-Bold', fontSize: labelFontSize } }}
          inputProps={{ autoCapitalize: this.props.autoCapitalize ? this.props.autoCapitalize : 'on', enterKeyHint: this.props.enterkeyhint, style: { color: 'black', fontFamily: 'Quicksand-Regular', fontSize: textFontSize }, maxLength: this.props.maxLength }}
          FormHelperTextProps={{ style: { color: 'red', fontFamily: 'Quicksand-Regular', fontSize: floatingLabelTextFontsize } }}
          onChange={event => this.props.onChange(event.target.value)}
          helperText={this.props.helperText}
          disabled={this.props.disabled}
          value={this.props.value}
          error={this.props.helperText !== undefined && this.props.helperText !== ''}
          onKeyPress={(e) => {
            if (e.key === "Enter" && this.props.onSubmit) {
              this.props.onSubmit()
            }
          }}
        />
      </View>
    );
  }
}


export default (FloatingLabelInput);
