import React from 'react';
import { connect } from 'react-redux';
import { List } from "react-virtualized";

class CustomList extends React.Component {
  render() {
    return (
      <List
        style={{ paddingBottom: this.props.addButton ? 80 : 0 }}
        width={this.props.listWidth}
        height={this.props.listHeight}
        rowRenderer={(dat) => {
          return (

            <div key={this.props.datas[dat.index].id} style={dat.style} className="post">
              {this.props.renderItem(this.props.datas[dat.index])}
            </div>
          )
        }}
        rowCount={this.props.datas.length}
        rowHeight={this.props.rowHeight ? this.props.rowHeight : 70}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.setData.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: action => {
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomList);
