import React from "react";
import { connect } from "react-redux";
import { Dimensions } from "react-native";
import Navigation from "../../navigation/Navigation";
import I18n from "../../i18n/i18n";
import { isBrowser, isAndroid, isIOS } from "react-device-detect";
import NetInfo from "@react-native-community/netinfo";
import "react-notifications/lib/notifications.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DialogAlert from "../CustomComponents/DialogAlert";
import {
  displayLoading,
  getColor,
  resetStorage,
  setSnackbar,
} from "../../utilities/utils";
import { app } from "../../db/dbInit";
import firebase from "firebase/app";
import "firebase/messaging";
import { SnackbarProvider } from "notistack";
import Snackbar from "../CustomComponents/Snackbar";
import Slide from "@mui/material/Slide";
import { textFontSize } from "../../styles/styles";

class AppContainer extends React.Component {
  constructor(props) {
    super(props);
    I18n.locale = this.props.language;

    this.state = {
      width: Dimensions.get("window").width,
      height: Dimensions.get("window").height,
      isDialogVisible: false,
      canCloseDialogVisible: true,
      isDialogVisible2: false,
      canCloseDialogVisible2: false,
      isLoading: false,
      rotation: false,
      theme: createTheme({
        typography: {
          allVariants: {
            fontFamily: "Quicksand-Regular",
            //fontSize: textFontSize
          },
        },
        status: {
          danger: "#e53e3e",
        },
        palette: {
          primary: {
            main: getColor("color1", this.props),
          },
          primary2: {
            main: getColor("color2", this.props),
          },
          primary3: {
            main: getColor("color3", this.props),
          },
          secondary: {
            main: "#fff",
          },
          neutral: {
            main: "#64748B",
            contrastText: "#fff",
          },
        },
      }),
    };
  }

  _handleConnection = () => {
    if (navigator.onLine) {
      fetch("https://www.google.com/", {
        mode: "no-cors",
      })
        .then(() => {
          const action = { type: "SET_CONNECTION", value: true };
          this.props.dispatch(action);
        })
        .catch(() => {
          const action = { type: "SET_CONNECTION", value: false };
          this.props.dispatch(action);
        });
    } else {
      const action = { type: "SET_CONNECTION", value: false };
      this.props.dispatch(action);
    }
  };

  componentDidMount() {
    if (firebase.messaging.isSupported()) {
      firebase.messaging(app).onMessage((res) => {
        setSnackbar(
          "info",
          res.notification.title + " " + res.notification.body,
          this.props
        );
      });
    }

    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", getColor("color3", this.props));

    window.history.pushState({ noBackExitsApp: true }, "");

    let action = { type: "SET_VERSION_GUARD", value: true };
    this.props.dispatch(action);

    action = { type: "SET_ACCOUNT_DELETED", value: false };
    this.props.dispatch(action);

    action = { type: "DATA_IS_IMPORTING", value: false };
    this.props.dispatch(action);

    action = { type: "SET_SNACKBAR", value: "" };
    this.props.dispatch(action);

    if (isBrowser && !isAndroid && !isIOS) {
      this._handleConnection();
      this.handleConnectionInterval = setInterval(() => {
        this._handleConnection();
      }, 10000);
    } else {
      this.unsubscribe = NetInfo.addEventListener((state) => {
        if (state.type !== "vpn") {
          const action = { type: "SET_CONNECTION", value: state.isConnected };
          this.props.dispatch(action);
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.deleted && this.props.deleted && this.props.id !== "") {
      this.setState({ isDialogVisible: true });
    }

    if (prevProps.isVersionValid && !this.props.isVersionValid) {
      this.setState({ isDialogVisible2: true });
    }

    if (prevProps.version !== "" && prevProps.version !== this.props.version) {
      this.setState({ isDialogVisible2: true });
    }

    if (
      (this.props.currentUser &&
        this.props.currentUser.color &&
        prevProps.currentUser.color !== this.props.currentUser.color) ||
      (prevProps.currentUser.color && !this.props.currentUser.color)
    ) {
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", getColor("color3", this.props));

      const theme = createTheme({
        typography: {
          allVariants: {
            fontFamily: "Quicksand-Regular",
            //fontSize: textFontSize
          },
        },
        status: {
          danger: "#e53e3e",
        },
        palette: {
          primary: {
            main: getColor("color1", this.props),
          },
          primary2: {
            main: getColor("color2", this.props),
          },
          primary3: {
            main: getColor("color3", this.props),
          },
          secondary: {
            main: "#fff",
          },
          neutral: {
            main: "#64748B",
            contrastText: "#fff",
          },
        },
      });
      this.setState({ theme: theme });
    }
  }

  componentWillUnmount() {
    if (this.getAbonnementInterval) {
      clearInterval(this.getAbonnementInterval);
    }
    if (this.handleConnectionInterval) {
      clearInterval(this.handleConnectionInterval);
    }
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    const action = { type: "SET_DATA", value: [] };
    this.props.dispatch(action);
  }

  _displayPage() {
    if (isBrowser && !isAndroid && !isIOS) {
      return (
        <div
          className="marvel-device iphone8plus black"
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto",
          }}
        >
          <div className="top-bar"></div>
          <div className="sleep"></div>
          <div className="volume"></div>
          <div className="camera"></div>
          <div className="sensor"></div>
          <div className="speaker"></div>
          <div className="screen" style={{ flex: 1, display: "flex" }}>
            {Navigation(this.props)}
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto",
            flex: 1,
            display: "flex",
          }}
        >
          {Navigation(this.props)}
        </div>
      );
    }
  }

  closeDialog = () => {
    this.setState({ isDialogVisible: false });
  };

  render() {
    return (
      <ThemeProvider theme={this.state.theme}>
        {displayLoading(this.state.isLoading)}
        <DialogAlert
          onClose={() => {}}
          isDialogVisible={this.state.isDialogVisible2}
          title={"Nouvelle version disponible"}
          text={
            "Une nouvelle version est disponible. Veuillez recharger l'application et vous reconnecter."
          }
          cancelText="Recharger"
          onlyOkButton={true}
          cancel={() => {
            this.setState({ isDialogVisible2: false, isLoading: true });
            setTimeout(() => {
              resetStorage();
              window.location.reload(true);
            }, 300);
          }}
        />
        <DialogAlert
          onClose={() => this.closeDialog()}
          isDialogVisible={this.state.isDialogVisible}
          title={"Compte supprimé"}
          text={
            "Ce compte n'existe plus. Vous devez créer un compte avant de pouvoir utiliser l'application."
          }
          validText="Accéder au site"
          cancelText="Ok"
          confirm={() => {
            window.open("https://www.tanko.app");
            this.setState({ isDialogVisible: false });
          }}
          cancel={() => {
            this.closeDialog();
          }}
        />

        <SnackbarProvider
          style={{ fontFamily: "Quicksand-Regular", fontSize: textFontSize }}
          anchorOrigin={{
            vertical: "top",
            horizontal: isBrowser && !isAndroid && !isIOS ? "right" : "center",
          }}
          TransitionComponent={(props) => (
            <Slide {...props} direction={"left"} />
          )}
        >
          <Snackbar />
          <div style={{ opacity: this.state.isLoading ? 0.5 : 1 }}>
            {this._displayPage()}
          </div>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.setLanguage.language,
    isConnectedToNetwork: state.setConnection.isConnectedToNetwork,
    isVersionValid: state.setVersionGuard.isVersionValid,
    version: state.setVersionGuard.version,
    currentUser: state.setData.currentUser,
    user: state.setData.user,
    settings: state.setAccount.settings,
    id: state.setAccount.id,
    deleted: state.setAccount.deleted,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
