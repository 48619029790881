import I18n from 'i18n-js';
import en from './en.json';
import fr from './fr.json';

I18n.fallbacks = true;

I18n.translations = {
  en,
  fr,
};

I18n.locale = 'fr';

export default I18n;
