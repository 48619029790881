import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CustomText from './CustomText';
import { textFontSize } from '../../styles/styles';

class DialogAlert extends React.Component {
  render() {
    return (
      <Dialog
        hidden={this.props.hidden}
        onBackdropClick={this.props.noBackDrop === true ? () => { } : () => this.props.onClose()}
        open={this.props.isDialogVisible}
        onClose={() => this.props.onClose()}
        style={{ fontFamily: 'Quicksand-Regular', color: 'black' }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

      >
        <DialogTitle
          style={{ fontFamily: 'Quicksand-Bold' }}
        >
          {this.props.title}
        </DialogTitle>
        <DialogContent style={{ fontFamily: 'Quicksand-Bold' }}>
          {this.props.content ? this.props.content :
            <CustomText
              style={{ fontSize: textFontSize - 2 }}
            >
              {this.props.text}
            </CustomText>}

        </DialogContent>
        {this.props.onlyOkButton
          ? <DialogActions>
            <Button
              style={{ fontFamily: 'Quicksand-Bold', color: 'black' }}
              onClick={() => this.props.cancel()}
              color="primary"
            >
              {this.props.cancelText ? this.props.cancelText : 'Ok'}

            </Button>
          </DialogActions>
          : <DialogActions>
            <Button
              style={{ fontFamily: 'Quicksand-Bold' }}
              onClick={() => this.props.cancel()}
            >
              {this.props.cancelText ? this.props.cancelText : 'Annuler'}

            </Button>
            <Button
              style={{ fontFamily: 'Quicksand-Bold' }}
              onClick={() => this.props.confirm()}

            >
              {this.props.validText ? this.props.validText : 'Valider'}
            </Button>
          </DialogActions>}
      </Dialog>
    );
  }
}

export default DialogAlert;
