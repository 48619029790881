import React, { createRef } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import Header from "../Headers/Header";
import {
  displayLoading,
  getColor,
  handleBack,
  promiseTimeout,
  setSnackbar,
  signOut,
  userIsGestionnaire,
} from "../../../utilities/utils";
import { connect } from "react-redux";
import {
  colors,
  globalStyles,
  smallTextFontSize,
  textFontSize,
} from "../../../styles/styles";
import I18n from "../../../i18n/i18n";
import {
  updateIconeToDatabase,
  updateSendinblueApiKeyToDatabase,
  updateUserToDatabase,
  version,
} from "../../../db/db";
import DialogAlert from "../../CustomComponents/DialogAlert";
import CustomButton from "../../CustomComponents/CustomButton";
import { CirclePicker } from "react-color";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import firebase from "firebase/app";
import "firebase/storage";
import * as XLSX from "xlsx";
import CustomText from "../../CustomComponents/CustomText";
import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton } from "@mui/material";
import { aesSecretKey, DEBUG, TIMEOUT } from "../../../api/constants";
import DialogInput from "../../CustomComponents/DialogInput";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

var CryptoJS = require("crypto-js");

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoading2: false,
      isDialogVisible: false,
      canCloseDialogVisible: true,
      isDialogVisible2: false,
      canCloseDialogVisible2: true,
      isDialogVisible4: false,
      canCloseDialogVisible4: false,
      isDialogVisible5: false,
      canCloseDialogVisible5: true,
      isDialogVisible6: false,
      canCloseDialogVisible6: true,
      excelDatas: [],
      helperText: "",
      sendinblueApiKey: "",
      color: getColor("color1", this.props),
    };

    this.inputContactsFile = createRef(null);
    this.inputIconFile = createRef(null);
  }

  closeDialog = () => {
    this.setState({
      isDialogVisible: false,
      color: getColor("color1", this.props),
    });
  };

  closeDialog2 = () => {
    this.setState({ isDialogVisible2: false });
  };

  closeDialog5 = () => {
    this.setState({ isDialogVisible5: false });
  };

  closeDialog6 = () => {
    this.setState({ isDialogVisible6: false });
  };

  componentDidMount() {
    this.unsubscribe = this.props.navigation.addListener("blur", () => {
      window.removeEventListener("popstate", this.listener);
    });

    this.unsubscribe2 = this.props.navigation.addListener("focus", () => {
      setTimeout(() => {
        window.addEventListener(
          "popstate",
          (this.listener = (event) => handleBack(event, this))
        );
      }, 1);
    });
    this._initXlsxReader();
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.listener);

    if (this.unsubscribe) this.unsubscribe();
    if (this.unsubscribe2) this.unsubscribe2();
  }

  _changePrimaryColor() {
    if (this.state.isLoading2) {
      return;
    }

    this.setState({ isLoading2: true });

    let user = { ...this.props.currentUser };
    user.color = colors.find((color) => color.color1 === this.state.color).name;

    updateUserToDatabase(user, this.props)
      .then(() => {
        setSnackbar("success", "Thème modifié avec succès", this.props);
      })
      .catch((error) => {
        setSnackbar(
          "error",
          error === "No network"
            ? "Erreur réseau"
            : "Une erreur est survenue. Veuillez réessayer plus tard",
          this.props
        );
        if (DEBUG) console.warn(error);
      })
      .finally(() => {
        this.setState({ isDialogVisible: false }, () => {
          setTimeout(() => {
            this.setState({ isLoading2: false });
          }, 100);
        });
      });
  }

  _uploadIcone = (event) => {
    if (this.state.isLoading2) {
      return;
    }

    this.setState({ isLoading2: true });

    if (
      !(
        event &&
        event.target &&
        event.target.files &&
        event.target.files[0] &&
        event.target.files[0].type
      )
    ) {
      setSnackbar(
        "error",
        "Une erreur est survenue. Veuillez réessayer ultérieurement",
        this.props
      );
      this.setState({ isLoading2: false });
      return;
    }

    if (
      event.target.files[0].type !== "image/jpeg" &&
      event.target.files[0].type !== "image/jpg" &&
      event.target.files[0].type !== "image/png" &&
      event.target.files[0].type !== "image/svg+xml"
    ) {
      setSnackbar(
        "error",
        "Fichier incorrect. Veuillez choisir un fichier png, jpg ou svg",
        this.props
      );
      this.setState({ isLoading2: false });
      return;
    }

    let scope = this;

    promiseTimeout(
      TIMEOUT,
      "firebase.storage",
      new Promise(function (resolve, reject) {
        if (!scope.props.isConnectedToNetwork) {
          reject("No network");
        }

        const ref = firebase
          .storage()
          .ref()
          .child(scope.props.accountAccessKey + "/icone");
        ref
          .put(event.target.files[0])
          .then(() => {
            ref
              .getDownloadURL()
              .then((url) => {
                resolve(url);
              })
              .catch((err2) => {
                if (DEBUG) console.warn(err2);
                reject();
              });
          })
          .catch((err) => {
            if (DEBUG) console.warn(err);
            reject();
          });
      })
    )
      .then((url) => {
        updateIconeToDatabase(url, scope.props)
          .then(() => {
            setSnackbar("success", "Icône importée avec succès", scope.props);
          })
          .catch((err) => {
            setSnackbar(
              "error",
              err === "No network"
                ? "Erreur réseau"
                : "Une erreur est survenue. Veuillez réessayer plus tard",
              scope.props
            );
          })
          .finally(() => {
            scope.setState({ isLoading2: false });
          });
      })
      .catch((error) => {
        setSnackbar(
          "error",
          error === "No network"
            ? "Erreur réseau"
            : "Une erreur est survenue. Veuillez réessayer plus tard",
          scope.props
        );
        if (DEBUG) console.warn(error);
      })
      .finally(() => {
        setTimeout(() => {
          scope.setState({ isLoading2: false });
          document.getElementById("file").value = "";
        }, 100);
      });
  };

  _importContacts = (event) => {
    if (this.state.isLoading2) {
      return;
    }

    this.setState({ isLoading2: true });

    if (
      !(
        event &&
        event.target &&
        event.target.files &&
        event.target.files[0] &&
        event.target.files[0].type
      )
    ) {
      setSnackbar(
        "error",
        "Fichier incorrect. Veuillez choisir un fichier excel",
        this.props
      );
      this.setState({ isLoading2: false });
      return;
    }

    if (
      event.target.files[0].type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      event.target.files[0].type !== "application/vnd.ms-excel"
    ) {
      document.getElementById("file2").value = "";

      setSnackbar(
        "error",
        "Fichier incorrect. Veuillez choisir un fichier excel",
        this.props
      );

      this.setState({ isLoading2: false });
      return;
    }

    let file = event.target.files[0];
    this.reader.readAsBinaryString(file);
    document.getElementById("file2").value = "";
  };

  _initXlsxReader() {
    this.reader = new FileReader();

    this.reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary", cellDates: true });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      const data = XLSX.utils.sheet_to_json(ws, {
        defval: "",
        header: 1,
        blankrows: false,
        dateNF: "dd/mm/yyyy",
        raw: false,
      });

      var ws2 = XLSX.utils.sheet_to_html(ws);

      document.getElementById("file2").value = "";
      this.setState({ isLoading2: false });
      if (data.length > 0) {
        this.setState({ excelDatas: data, ws2: ws2, isDialogVisible4: true });
      } else {
        setSnackbar(
          "warning",
          "Le fichier que vous avez sélectionné ne contient pas de données",
          this.props
        );
      }
    };
  }

  _acceptCookies() {
    if (this.state.isLoading2) {
      return;
    }

    this.setState({ isLoading2: true });

    let user = { ...this.props.currentUser };
    user.cookies = !user.cookies;

    updateUserToDatabase(user, this.props)
      .then(() => {
        setSnackbar(
          "success",
          user.cookies
            ? "Cookies acceptés avec succès"
            : "Cookies refusés avec succès",
          this.props
        );
      })
      .catch((error) => {
        setSnackbar(
          "error",
          error === "No network"
            ? "Erreur réseau"
            : "Une erreur est survenue. Veuillez réessayer plus tard",
          this.props
        );
        if (DEBUG) console.warn(error);
      })
      .finally(() => {
        setTimeout(() => {
          this.setState({ isLoading2: false });
        }, 100);
      });
  }

  _setSendinblueApiKey() {
    if (this.state.isLoading2) {
      return;
    }

    this.setState({ isLoading2: true, helperText: "" });

    if (this.state.sendinblueApiKey.toString().trim() === "") {
      this.setState({ helperText: "Paramètre obligatoire", isLoading2: false });
      return;
    }

    setTimeout(() => {
      let hash = CryptoJS.AES.encrypt(
        this.state.sendinblueApiKey,
        aesSecretKey
      ).toString();

      updateSendinblueApiKeyToDatabase(hash, this.props)
        .then(() => {
          setSnackbar(
            "success",
            "Clé API Brevo (ex Sendinblue) enregistrée avec succès",
            this.props
          );
        })
        .catch((err) => {
          if (DEBUG) console.warn(err);
          setSnackbar(
            "error",
            err === "No network"
              ? "Erreur réseau"
              : "Une erreur est survenue. Veuillez réessayer plus tard",
            this.props
          );
        })
        .finally(() => {
          this.setState({ isDialogVisible5: false }, () => {
            setTimeout(() => {
              this.setState({ isLoading2: false });
            }, 100);
          });
        });
    }, 100);
  }

  render() {
    return (
      <View style={globalStyles.main_container}>
        <Header
          icon={
            <div>
              <IconButton
                color="secondary"
                title="Déconnexion"
                onClick={() => {
                  this.setState({ isDialogVisible2: true });
                }}
                size="medium"
              >
                <LogoutIcon fontSize={"medium"} />
              </IconButton>
            </div>
          }
          navigation={this.props.navigation}
        >
          {I18n.t("settings_title")}
        </Header>

        <Dialog
          onBackdropClick={() => this.closeDialog()}
          open={this.state.isDialogVisible}
          onClose={() => this.closeDialog()}
          style={{ fontFamily: "Quicksand-Regular", color: "black" }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ fontFamily: "Quicksand-Bold" }}>
            Thème
          </DialogTitle>
          <DialogContent
            style={{
              fontFamily: "Quicksand-Regular",
              color: "black",
              padding: 20,
            }}
          >
            <View>
              <CirclePicker
                colors={colors.map((color) => color.color1)}
                onChangeComplete={(color, event) => {
                  this.setState({ color: color.hex });
                }}
                color={this.state.color}
              ></CirclePicker>
            </View>
          </DialogContent>
          <DialogActions
            style={{ fontFamily: "Quicksand-Regular", color: "black" }}
          >
            <Button
              style={{ fontFamily: "Quicksand-Bold" }}
              onClick={() => this.closeDialog()}
            >
              Annuler
            </Button>
            <Button
              style={{ fontFamily: "Quicksand-Bold" }}
              onClick={() => this._changePrimaryColor()}
            >
              Valider
            </Button>
          </DialogActions>
        </Dialog>

        <DialogAlert
          isDialogVisible={this.state.isDialogVisible2}
          onClose={() => {
            this.closeDialog2();
          }}
          title={"Deconnexion"}
          text={"Etes-vous sûr de vouloir vous déconnecter ?"}
          confirm={() => signOut(this.props)}
          cancel={() => {
            this.closeDialog2();
          }}
        />

        <DialogAlert
          noBackDrop={true}
          isDialogVisible={this.state.isDialogVisible4}
          onClose={() => {}}
          title={"En-tête de colonne"}
          text={
            "Votre fichier Excel contient-il des noms de colonnes ? Si vous répondez oui, l'importation de votre fichier commencera à la ligne 2 pour ne pas considérer ces noms de colonnes comme des données."
          }
          validText="Oui"
          cancelText="Non"
          confirm={() => {
            this.setState({ isDialogVisible4: false });
            if (this.state.excelDatas.length === 1) {
              setSnackbar(
                "warning",
                "Le fichier que vous avez sélectionné ne contient pas de données",
                this.props
              );
            } else {
              this.props.navigation.navigate("ImportContactSettings", {
                excelDatas: this.state.excelDatas,
                ws2: this.state.ws2,
                enTete: true,
              });
            }
          }}
          cancel={() => {
            this.setState({ isDialogVisible4: false });
            this.props.navigation.navigate("ImportContactSettings", {
              excelDatas: this.state.excelDatas,
              ws2: this.state.ws2,
              enTete: false,
            });
          }}
        />

        <DialogInput
          isDialogVisible={this.state.isDialogVisible5}
          onClose={() => this.closeDialog5()}
          title={"Ajouter une clé API Brevo (ex Sendinblue)"}
          placeholder={"Clé API"}
          defaultValue={""}
          onChange={(value) => this.setState({ sendinblueApiKey: value })}
          confirm={() => this._setSendinblueApiKey()}
          cancel={() => this.closeDialog5()}
          helperText={this.state.helperText}
          extraContent={
            <View style={{ paddingBottom: 12, fontSize: textFontSize - 3 }}>
              <Text>
                <CustomText style={{ fontSize: textFontSize - 3 }}>
                  Vous trouverez cette information dans la section "SMTP & API"
                  de votre profil Brevo !{" "}
                </CustomText>
                <IconButton
                  style={{ padding: 0 }}
                  title="Ouvrir le lien vers Brevo (ex Sendinblue)"
                  onClick={() => {
                    window.open("https://app.brevo.com/settings/keys/api");
                  }}
                  size="small"
                >
                  <OpenInNewIcon fontSize={"small"} />
                </IconButton>
              </Text>
            </View>
          }
        />

        {displayLoading(this.state.isLoading2)}
        {this.state.isLoading ? (
          displayLoading(this.state.isLoading)
        ) : (
          <View style={globalStyles.form_container}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                padding: "5%",
                opacity: this.state.isLoading2 ? 0.5 : 1,
                overflowY: "scroll",
                scrollbarWidth: "none",
              }}
            >
              <View
                style={{
                  flexGrow: 1,
                }}
              >
                <View
                  style={{
                    paddingVertical: 15,
                  }}
                >
                  <CustomButton
                    title={"Modifier mon thème"}
                    onPress={() => this.setState({ isDialogVisible: true })}
                  />
                </View>

                {userIsGestionnaire(this.props.currentUser) ? (
                  <View
                    style={{
                      paddingVertical: 15,
                    }}
                  >
                    <CustomButton
                      onPress={() => this.inputIconFile.current.click()}
                      title={
                        this.props.settings &&
                        this.props.settings.icone === false
                          ? "Importer mon logo"
                          : "Modifier mon logo"
                      }
                    />
                    <input
                      id="file"
                      type="file"
                      ref={this.inputIconFile}
                      style={{ display: "none" }}
                      onChange={this._uploadIcone}
                      accept=".png, .jpg, .jpeg, .svg"
                    />
                  </View>
                ) : (
                  <></>
                )}

                {userIsGestionnaire(this.props.currentUser) ? (
                  <View
                    style={{
                      paddingVertical: 15,
                    }}
                  >
                    <CustomButton
                      onPress={() =>
                        this.setState({
                          sendinblueApiKey: "",
                          isDialogVisible5: true,
                        })
                      }
                      title={
                        this.props.settings &&
                        this.props.settings.sendinblueApiKey
                          ? "Modifier ma clé API Brevo"
                          : "Ajouter une clé API Brevo"
                      }
                    />
                  </View>
                ) : (
                  <></>
                )}

                {this.props.currentUser.type === "read" ? (
                  <></>
                ) : (
                  <View
                    style={{
                      paddingVertical: 15,
                    }}
                  >
                    <CustomButton
                      title={"Importer mes contacts"}
                      onPress={() => this.inputContactsFile.current.click()}
                    />
                    <input
                      id="file2"
                      type="file"
                      ref={this.inputContactsFile}
                      style={{ display: "none" }}
                      onChange={this._importContacts}
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                  </View>
                )}
                {userIsGestionnaire(this.props.currentUser) ? (
                  <View
                    style={{
                      paddingVertical: 15,
                    }}
                  >
                    <CustomButton
                      title={"Gérer mon compte"}
                      onPress={() => {
                        window.open("https://www.tanko.app/espace-membre");
                      }}
                    />
                  </View>
                ) : (
                  <></>
                )}

                <View
                  style={{
                    paddingVertical: 15,
                  }}
                >
                  <CustomButton
                    title={"Aide"}
                    onPress={() => {
                      window.open("https://www.tanko.app/documentation");
                    }}
                  />
                </View>

                {/* <View
                style={{
                  paddingVertical: 15,
                }}
              >
                <CustomButton
                  title={this.props.settings.cookies ? "Refuser les cookies facultatifs" : "Accepter les cookies facultatifs"}
                  onPress={() => { this._acceptCookies() }}
                />
              </View> */}
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  style={{ flex: 1 }}
                  onPress={() => this.props.navigation.navigate("CGU")}
                >
                  <CustomText
                    style={{
                      fontSize: smallTextFontSize,
                      textAlign: "center",
                      color: "blue",
                      textDecorationLine: "underline",
                    }}
                  >
                    CGU
                  </CustomText>
                </TouchableOpacity>
                <View style={{ flex: 1 }}>
                  <CustomText
                    style={{ fontSize: smallTextFontSize, textAlign: "center" }}
                    tradOff
                  >
                    {"v " + version}
                  </CustomText>
                </View>
                <TouchableOpacity
                  style={{ flex: 1 }}
                  onPress={() =>
                    this.props.navigation.navigate("PolitiqueConfidentialite")
                  }
                >
                  <CustomText
                    style={{
                      fontSize: smallTextFontSize,
                      textAlign: "center",
                      color: "blue",
                      textDecorationLine: "underline",
                    }}
                  >
                    Politique de confidentialité
                  </CustomText>
                </TouchableOpacity>
              </View>
            </div>
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.setData.data,
    users: state.setData["users"],
    currentUser: state.setData["currentUser"],
    isConnectedToNetwork: state.setConnection.isConnectedToNetwork,
    accountAccessKey: state.setAccount.accountAccessKey,
    settings: state.setAccount.settings,
    abonnement: state.setAccount.abonnement,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
