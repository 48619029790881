import React from 'react';
import { connect } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import { getColor } from '../../utilities/utils';

class IconLetter extends React.Component {

  render() {
    return (
      <Avatar sx={{ bgcolor: getColor("color2", this.props) }}>{this.props.letters.toString().toUpperCase()}</Avatar>

    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.setData.currentUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: action => {
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IconLetter);