import { StyleSheet } from 'react-native';
import { isTablet } from '../utilities/utils';

export const primaryColor = '#3448E8';
export const labelColor = 'grey';
export const appBackgroundColor = 'white';
export const browserBackgroundColor = 'white';

export const colors = [
  {
    name: "black",
    color1: "#2b2b2b",
    color2: "#1e2223",
    color3: "#555e5f",
    color4: "#3b4142"
  },
  {
    name: "green",
    color1: "#009fa0",
    color2: "#006464",
    color3: "#5da58c",
    color4: "#358579"
  },
  {
    name: "blue",
    color1: "#004298",
    color2: "#0067b5",
    color3: "#2b9ad2",
    color4: "#0081c3"
  },
  {
    name: "purple",
    color1: "#4e2b8b",
    color2: "#6766b0",
    color3: "#afa6ce",
    color4: "#8c87bf"
  },
  {
    name: "pink",
    color1: "#fe2d68",
    color2: "#fb7494",
    color3: "#f5a6b9",
    color4: "#f88fa7"
  },
  {
    name: "orange",
    color1: "#ff8f00",
    color2: "#d88233",
    color3: "#c6b6a4",
    color4: "#cf9d70"
  },
]


export const globalStyles = StyleSheet.create({
  main_container: {
    flex: 1,
  },
  form_container: {
    flex: 1,
    paddingLeft: isTablet() ? '20%' : '10%',
    paddingRight: isTablet() ? '20%' : '10%',

  },
  list_container: {
    flex: 1,
    paddingLeft: isTablet() ? '10%' : '0%',
    paddingRight: isTablet() ? '10%' : '0%',
  },
  loading_container: {
    position: 'absolute',
    flex: 1,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 999
  },
});

export const textFontSize = 16;
export const smallTextFontSize = 11;
export const floatingLabelTextFontsize = 13
export const titleFontSize = 20;
export const labelFontSize = 13;

export const iconSize = 24;
export const smallIconSize = 20;
export const headerIconSize = 18;

export const focusedBottomBarIconSize = 18;
export const bottomBarIconSize = 13;
export const focusedBottomBarTextSize = 13;
export const bottomBarTextSize = 11;
