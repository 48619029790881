import React from 'react';
import { View } from 'react-native';
import Header from '../Headers/Header';
import {
  dateDiff,
  displayLoading,
  getAllContactsByRelance,
  getAllContactsByResponsable,
  getAllResponsables,
  getContactNextRelanceByUser,
  getRelanceText,
  handleBack,
  signOut,
  userIsGestionnaire,
} from '../../../utilities/utils';
import { connect } from 'react-redux';
import CustomList from '../../CustomComponents/CustomList';
import { globalStyles, labelColor, textFontSize } from '../../../styles/styles';
import CustomText from '../../CustomComponents/CustomText';
import DialogAlert from '../../CustomComponents/DialogAlert';
import I18n from '../../../i18n/i18n';
import moment from 'moment';
import CustomSelect from '../../CustomComponents/CustomSelect';
import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CustomItemList from '../../CustomComponents/CustomItemList';

class Relance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      relanceList: [],
      selectedUser: '',
      selectedUserOptions: [],
      isDialogVisible2: false,
      canCloseDialogVisible2: true,
      isDialogVisible4: false,
      canCloseDialogVisible4: false,
      listHeight: 0,
      listWidth: 0,
      reload: 0,
    };
  }

  closeDialog2 = () => {
    this.setState({ isDialogVisible2: false })
  }

  componentDidMount() {

    this.unsubscribe = this.props.navigation.addListener('blur', () => {
      window.removeEventListener('popstate', this.listener)
    })

    this.unsubscribe2 = this.props.navigation.addListener('focus', () => {
      setTimeout(() => {
        window.addEventListener('popstate', this.listener = (event) => handleBack(event, this));
      }, 1)
    })

    this._loadRelanceList();


    setInterval(() => {
      this._loadRelanceList();
    }, 60000 * 3)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data || dateDiff(this.date, moment().startOf("day").toDate()) !== 0 || prevState.selectedUser !== this.state.selectedUser || prevProps.users !== this.props.users) {
      this._loadRelanceList();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.listener)

    if (this.unsubscribe)
      this.unsubscribe()
    if (this.unsubscribe2)
      this.unsubscribe2()
  }

  _compare(a, b) {
    if (a.time >= 0 && b.time >= 0 && a.time <= b.time) {
      return -1;
    }

    if (a.time >= 0 && b.time >= 0 && a.time >= b.time) {
      return 1;
    }

    if (a.time <= 0 && b.time <= 0 && a.time <= b.time) {
      return 1;
    }

    if (a.time <= 0 && b.time <= 0 && a.time >= b.time) {
      return -1;
    }

    if (a.time <= 0 && b.time >= 0 && a.time <= b.time) {
      return -1;
    }

    if (a.time <= 0 && b.time >= 0 && a.time >= b.time) {
      return 1;
    }

    if (a.time >= 0 && b.time <= 0 && a.time >= b.time) {
      return 1;
    }

    if (a.time >= 0 && b.time <= 0 && a.time <= b.time) {
      return -1;
    }

    return 0;
  }

  _compare2(a, b) {
    if (a.time >= 0 && b.time >= 0 && a.time <= b.time) {
      return 1;
    }

    if (a.time >= 0 && b.time >= 0 && a.time >= b.time) {
      return -1;
    }

    if (a.time <= 0 && b.time <= 0 && a.time <= b.time) {
      return -1;
    }

    if (a.time <= 0 && b.time <= 0 && a.time >= b.time) {
      return 1;
    }

    if (a.time <= 0 && b.time >= 0 && a.time <= b.time) {
      return 1;
    }

    if (a.time <= 0 && b.time >= 0 && a.time >= b.time) {
      return -1;
    }

    if (a.time >= 0 && b.time <= 0 && a.time >= b.time) {
      return -1;
    }

    if (a.time >= 0 && b.time <= 0 && a.time <= b.time) {
      return 1;
    }

    return 0;
  }

  _loadRelanceList() {
    let relanceListFuture = [];
    let relanceListPass = [];
    let relanceList = [];
    let diff;
    let exist;
    let withoutDate = false;
    let id = -1;
    let relanceText = ''
    let contactsByReps = getAllContactsByResponsable(
      this.state.selectedUser === '' ? this.props.currentUser.id : this.state.selectedUser,
      this.props.data
    );

    let selectedUserOptions = getAllResponsables(this.props.users, true)

    this.date = moment().startOf('day').toDate()

    this.props.data.forEach(element => {

      if (element['Responsable'] === (this.state.selectedUser === '' ? this.props.currentUser.id : this.state.selectedUser) || this.state.selectedUser === 'Tous') {
        exist = false;

        if (element['Date de relance'] instanceof Date && !isNaN(element['Date de relance'])) {

          diff = dateDiff(moment(element["Date de relance"], "DD/MM/YYYY HH:mm", 'fr', true).startOf('day'), this.date);

          relanceText = getRelanceText(diff)
          if (diff > 0) {
            relanceListPass.forEach(elem => {
              if (elem.text === relanceText) {
                exist = true;
              }
            });
            if (!exist) {
              id++;
              relanceListPass.push({
                id: id,
                text: relanceText,
                time: diff,
                length: getAllContactsByRelance(
                  { text: relanceText, time: diff },
                  contactsByReps
                ).length,
              });
            }
          } else {
            relanceListFuture.forEach(elem => {
              if (elem.text === relanceText) {
                exist = true;
              }
            });
            if (!exist) {
              id++;
              relanceListFuture.push({
                id: id,
                text: relanceText,
                time: diff,
                length: getAllContactsByRelance(
                  { text: relanceText, time: diff },
                  contactsByReps
                ).length,
              });
            }
          }
        } else if (!withoutDate && element['Date de relance'] === '') {
          withoutDate = true;
        }
      }
    });

    relanceListPass.sort(this._compare2);
    relanceListFuture.sort(this._compare);

    if (relanceListFuture.length > 0) {
      let contact = getContactNextRelanceByUser(relanceListFuture, this.state.selectedUser === '' ? this.props.currentUser.id : this.state.selectedUser, this.props.data)
      id++;
      if (contact)
        relanceList.push({
          id: id,
          text: contact['Date de relance'].getHours() === 0 ? 'Prochaine : ' + moment(contact['Date de relance']).format('DD/MM') : 'Prochaine : ' + moment(contact['Date de relance']).format('DD/MM HH:mm'),
          contact: contact,
        });
    }
    relanceList = relanceList.concat(relanceListPass.concat(relanceListFuture));

    if (withoutDate) {
      id++;
      relanceList.push({
        id: id,
        text: 'Sans relance',
        time: 999999,
        length: getAllContactsByRelance(
          { text: 'Sans relance', time: 999999 },
          contactsByReps
        ).length,
      });
    }

    this.setState({ relanceList: relanceList, selectedUserOptions: selectedUserOptions, reload: new Date().getTime(), isLoading: false }, () => {
      this.setState({})
    });
  }

  _renderItem = (relance) => {
    return (
      <CustomItemList
        content={relance.contact ?
          <View>
            <CustomText textType='bold' numberOfLines={1}>{relance.text}</CustomText>
            <CustomText style={{ color: labelColor, fontSize: textFontSize - 1 }} numberOfLines={1}>
              {relance.contact['Prénom'] !== '' ? relance.contact['Prénom'] + ' ' : ''}
              {relance.contact['Nom']}
            </CustomText>
          </View> :
          <CustomText textType='bold' numberOfLines={1}>{relance.text}</CustomText>}
        onPress={() => this._displayDetailForRelance(relance)}
        letters={relance.contact ? (relance.contact['Prénom'] !== undefined && relance.contact['Nom'] !== undefined ? relance.contact['Prénom'].substring(0, 1) +
          relance.contact['Nom'].substring(0, 1) : "") : relance.length}
      ///equalTest={relance.text}
      />
    );
  };

  _displayDetailForRelance = relance => {

    if (relance.contact) {
      this.props.navigation.navigate('ContactCard', {
        contactId: relance.contact.id
      });
    } else {
      this.props.navigation.navigate('RelanceListByRelance', {
        relance: relance,
        responsable: this.state.selectedUser === '' ? this.props.currentUser.id : this.state.selectedUser
      });
    }
  };


  render() {
    return (
      <View style={globalStyles.main_container}>
        <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
          <Header
            headerId="relanceHeader"
            iconLeft={this.props.settings.icone}
            icon={
              <div>
                <IconButton
                  id='settings'
                  color='secondary'
                  title="Paramètres"
                  onClick={() => this.props.navigation.navigate("Settings")}
                  size='medium'
                >
                  <SettingsIcon
                    fontSize={'medium'}
                  />
                </IconButton>
              </div>
            }
          >
            {I18n.t('relance_title')}
          </Header>

          <DialogAlert
            isDialogVisible={this.state.isDialogVisible2}
            onClose={() => {
              this.closeDialog2()
            }}
            title={'Abonnement bientôt expiré'}
            text={
              "Votre abonnement arrive à échéance dans " + this.props.abonnementEotTime + " jour(s). Veuillez mettre à jour votre abonnement afin de continuer à utiliser toutes les fonctionnalités de l'application ou contactez votre Administrateur."
            }
            validText="Accéder au site"
            cancelText="Ok"
            confirm={() => {
              window.open("https://www.tanko.app");
              this.setState({ isDialogVisible2: false });
            }}
            cancel={() => {
              this.closeDialog2()
            }}
          />


          <DialogAlert
            isDialogVisible={this.state.isDialogVisible4}
            onClose={() => { }}
            title={"Nombre d'utilisateurs maximum dépassé"}
            text={
              "La limite du nombre d'utilisateur autorisé dans votre abonnement est dépassée. Veuillez mettre à jour votre abonnement afin de continuer à utiliser l'application ou contactez votre Administrateur."
            }
            validText="Accéder au site"
            cancelText={"Se déconnecter"}
            confirm={() => {
              window.open("https://www.tanko.app");
            }}
            cancel={() => {
              this.setState({ isDialogVisible4: false });
              signOut(this.props)
            }}
          />
          {this.state.isLoading ? displayLoading(this.state.isLoading) :
            <View style={[{ paddingTop: 8 }, globalStyles.list_container]}>
              <View style={{ paddingHorizontal: '5%' }}>
                <CustomSelect
                  label={"Responsable"}
                  value={this.state.selectedUser === '' ? this.props.currentUser.id : this.state.selectedUser}
                  onChange={(value) => this.setState({ selectedUser: value.id })}
                  options={this.state.selectedUserOptions}
                  isSearchable
                  navigateTo={userIsGestionnaire(this.props.currentUser) ? () => document.getElementById("utilisateursNav").click() : false}
                />

              </View>

              {this.state.relanceList.length === 0 ?
                <View
                  style={{
                    flex: 1,
                    alignItems: 'center',
                    paddingHorizontal: '5%',
                    paddingTop: '5%'
                  }}
                >
                  <CustomText>Vous n'avez pas de relances pour le moment.</CustomText>
                </View>
                :
                <View key={this.state.reload} onLayout={(event) => this.setState({ listHeight: event.nativeEvent.layout.height, listWidth: event.nativeEvent.layout.width })} style={{ flex: 1 }}>
                  <CustomList
                    listWidth={this.state.listWidth}
                    listHeight={this.state.listHeight}
                    datas={this.state.relanceList}
                    renderItem={this._renderItem}
                  />
                </View>
              }
            </View>

          }
        </div>
      </View >
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.setData.data,
    users: state.setData['users'],
    currentUser: state.setData['currentUser'],
    accountAccessKey: state.setAccount.accountAccessKey,

    settings: state.setAccount.settings,
    deleted: state.setAccount.deleted,
    isConnectedToNetwork: state.setConnection.isConnectedToNetwork,
    isVersionValid: state.setVersionGuard.isVersionValid,
    abonnementEotTime: state.setAccount.abonnementEotTime,
    abonnement: state.setAccount.abonnement,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: action => {
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Relance);
