import React from 'react';
import { View } from 'react-native';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import elevations from 'react-native-elevation';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { connect } from 'react-redux';
import { userIsGestionnaire } from '../utilities/utils';
import { StackActions } from '@react-navigation/native';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import NoNetworkNotification from '../components/CustomComponents/NoNetworkNotification';
import NotificationsIcon from '@mui/icons-material/Notifications';

function CustomTabBar(props) {
    const [value, setValue] = React.useState(0);

    return (
        <View>
            <View>
                <NoNetworkNotification />
            </View>
            <View style={[elevations[9], { paddingBottom: getComputedStyle(document.documentElement).getPropertyValue("--sab") }]}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue)
                    }}
                >
                    <BottomNavigationAction
                        id={'contactNav'}
                        disabled={props.dataIsImporting}
                        sx={{ minWidth: 0, paddingLeft: 0, paddingRight: 0 }}
                        onClick={() => {
                            if (value === 0) {
                                props.navigation.dispatch(StackActions.popToTop());
                            } else {
                                props.navigation.navigate("Contact")
                            }
                        }}
                        label="Contacts"
                        icon={

                            <ListAltIcon />
                        }
                    />

                    {userIsGestionnaire(props.currentUser) ?
                        <BottomNavigationAction
                            disabled={props.dataIsImporting}
                            id={'utilisateursNav'}
                            sx={{ minWidth: 0, paddingLeft: 0, paddingRight: 0 }}
                            onClick={() => {
                                if (value === 1) {
                                    props.navigation.dispatch(StackActions.popToTop());
                                } else {
                                    props.navigation.navigate("Utilisateurs")
                                }
                            }}
                            label="Mon équipe"
                            icon={

                                <PeopleAltIcon />
                            }
                        />
                        : null}

                    <BottomNavigationAction
                        id={'relanceNav'}
                        disabled={props.dataIsImporting}
                        sx={{ minWidth: 0, paddingLeft: 0, paddingRight: 0 }}
                        onClick={() => {
                            if (value === 2) {
                                props.navigation.dispatch(StackActions.popToTop());
                            } else {
                                props.navigation.navigate("Relance")
                            }
                        }}
                        label="Relances"
                        icon={<NotificationsIcon />}
                    />
                </BottomNavigation>
            </View >
        </View>
    );
}

const mapStateToProps = state => {
    return {
        data: state.setData.data,
        currentUser: state.setData['currentUser'],
        settings: state.setAccount.settings,
        dataIsImporting: state.setData.dataIsImporting,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch: action => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomTabBar)

