import React from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import { getAllContactsByRelance, getAllContactsByResponsable, getElementPreListLabelById, getList } from '../../../utilities/utils';
import ContactList from '../Contact/ContactList';
import { globalStyles, labelColor, textFontSize } from '../../../styles/styles';
import CustomItemList from '../../CustomComponents/CustomItemList';
import CustomText from '../../CustomComponents/CustomText';
import moment from 'moment';

class RelanceListByRelance extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
        }
    }

    _displayDetailForContact(contactId) {
        this.props.navigation.navigate('ContactCard', {
            contactId: contactId,
        });
    }


    _renderItem = (item) => {
        return (
            <CustomItemList
                content={<View>
                    <CustomText textType='bold' numberOfLines={1}>
                        {item['Prénom'] !== '' ? item['Prénom'] + ' ' : ''}
                        {item['Nom']}
                    </CustomText>
                    {item['Société'] !== '' ? <CustomText style={{ color: labelColor, fontSize: textFontSize - 1 }} numberOfLines={1}>
                        {getElementPreListLabelById(getList("Société", this.props), item['Société'])}
                    </CustomText> : <></>}
                    <CustomText
                        numberOfLines={1}
                        style={{ color: labelColor, fontSize: textFontSize - 1 }}
                    >
                        {item['Date de relance'] === "" || item['Date de relance'].getHours() === 0 ? '' : moment(item['Date de relance']).format('HH:mm')}
                    </CustomText>
                </View>}
                onPress={() => this._displayDetailForContact(item.id)}
                letters={item['Prénom'].substring(0, 1) +
                    item['Nom'].substring(0, 1)}
            />
        )
    }

    render() {
        return (
            <View style={globalStyles.main_container}>
                <ContactList
                    contacts={getAllContactsByRelance(this.props.route.params.relance, getAllContactsByResponsable(this.props.route.params.responsable, this.props.data))}
                    navigation={this.props.navigation}
                    noTri
                    headerTitle={this.props.route.params.relance.text}
                    headerBack
                    renderItem={this._renderItem}
                    relance
                />
            </View>
        );
    }
}


const mapStateToProps = state => {
    return {
        data: state.setData.data,
        lists: state.setData.lists,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch: action => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RelanceListByRelance);

