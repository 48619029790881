import {
  abonnementsList,
  setCurrentToken,
  setSnackbar,
  signOut,
} from "../utilities/utils";
import { TIMEOUT, ADDRESS, DEBUG } from "./constants";
import firebase from "firebase/app";
import "firebase/functions";
import Cookies from "js-cookie";

export function accountSignIn(accessKey, props, loadingOff, createDB) {
  const url = ADDRESS + "check_access_key.php";
  const controller = new AbortController();
  const { signal } = controller;

  fetch(url, {
    signal: signal,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessKey,
    },
  })
    .then((response) => response.json())
    .then((responseJson) => {
      if (!responseJson) {
        loadingOff();
        setSnackbar(
          "error",
          "Une erreur est survenue. Veuillez réessayer ultérieurement.",
          props
        );
        return;
      }
      if (responseJson === "Code inexistant") {
        loadingOff();
        setSnackbar("error", "Code d'accès non valide.", props);
      } else if (responseJson.user_id && responseJson.user_email) {
        let action = { type: "SET_ACCOUNT_ID", value: responseJson.user_id };
        props.dispatch(action);

        createDB(responseJson.user_email, responseJson.user_name);
      } else {
        loadingOff();
        setSnackbar(
          "error",
          "Une erreur est survenue. Veuillez réessayer ultérieurement.",
          props
        );
      }
    })
    .catch((error2) => {
      if (DEBUG) console.warn("accountSignIn : " + error2);
      loadingOff();
      setSnackbar(
        "error",
        "Une erreur est survenue. Veuillez réessayer ultérieurement.",
        props
      );
    });

  setTimeout(() => {
    controller.abort();
  }, TIMEOUT);
}

export function userGetAbonnement(props, token, alreadyExecuted = false) {
  const url = ADDRESS + "get_abonnement.php";
  const controller = new AbortController();
  const { signal } = controller;

  fetch(url, {
    signal: signal,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({
      userId: props.id,
    }),
  })
    .then((response) => {
      if (response.status === 403 && !alreadyExecuted) {
        firebase
          .functions()
          .httpsCallable("refreshJWT")({ token: Cookies.get("refresh_token") })
          .then((res) => {
            const response = res.data;
            if (response.code === "success") {
              setCurrentToken(response.accessToken);
              userGetAbonnement(props, response.accessToken, true);
            } else {
              signOut(props, "force");
              setSnackbar("warning", "Votre session a expiré !", props);
            }
          })
          .catch((err) => {
            console.warn("err", err);
            //signOut(props)
            // setSnackbar('warning', "Votre session a expiré !", props)
          });
      } else if (response.status === 200) {
        return response.json();
      } else {
        signOut(props, "force");
        setSnackbar("warning", "Votre session a expiré !", props);
      }
    })
    .then((responseJson) => {
      if (responseJson) {
        if (responseJson === "Utilisateur supprimé") {
          let action = { type: "SET_ACCOUNT_DELETED", value: true };
          props.dispatch(action);
          return;
        }

        if (responseJson.tc_capabilities) {
          let abonnement = "";

          abonnementsList.forEach((r) => {
            if (responseJson.tc_capabilities.includes(r)) {
              abonnement = r;
            }
          });

          if (props.abonnement !== abonnement) {
            let action = { type: "SET_ACCOUNT_ABONNEMENT", value: abonnement };
            props.dispatch(action);
          }
        }

        let eotTime = -1;
        if (
          responseJson.tc_s2member_auto_eot_time !== undefined &&
          responseJson.tc_s2member_auto_eot_time !== "" &&
          !isNaN(responseJson.tc_s2member_auto_eot_time)
        ) {
          eotTime = Math.ceil(
            (responseJson.tc_s2member_auto_eot_time * 1000 -
              new Date().getTime()) /
              1000 /
              (3600 * 24)
          );
        }

        if (props.eotTime !== eotTime) {
          let action2 = {
            type: "SET_ACCOUNT_ABONNEMENT_EOT_TIME",
            value: eotTime,
          };
          props.dispatch(action2);
        }
      }
    })
    .catch((error2) => {
      if (DEBUG) console.warn("userGetAbonnement : " + error2);
    });

  setTimeout(() => {
    controller.abort();
  }, TIMEOUT);
}
