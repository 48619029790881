import React from "react";
import {
  displayLoading,
  renderNoNetworkNotification,
  resetStorage,
  setSnackbar,
  superSignOut,
} from "../../../utilities/utils";
import { connect } from "react-redux";
import {
  checkIfDBExists,
  countVisit,
  getVersion,
  updateLastConnectionToDatabase,
  version,
} from "../../../db/db";
import { View } from "react-native";
import CustomText from "../../CustomComponents/CustomText";
import Header from "../Headers/Header";
import CustomButton from "../../CustomComponents/CustomButton";
import firebase from "firebase/app";
import "firebase/auth";
import { authorization, DEBUG } from "../../../api/constants";

class SuperSignInLoadingScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
    };
  }

  componentDidMount() {
    this._bootstrapAsync();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isConnectedToNetwork && this.props.isConnectedToNetwork) {
      this._bootstrapAsync();
    }
  }

  _bootstrapAsync() {
    getVersion(this.props)
      .then((vers) => {
        const action = { type: "SET_VERSION", value: vers };
        this.props.dispatch(action);

        countVisit(this.props)
          .then(() => {})
          .catch((errr) => {
            if (DEBUG) console.warn(errr);
          });
        if (this.props.accountAccessKey !== "") {
          firebase
            .auth()
            .signInWithEmailAndPassword(
              this.props.accountAccessKey + "@firebase.com",
              this.props.accountAccessKey + authorization
            )
            .then(() => {
              checkIfDBExists(this.props.accountAccessKey, this.props)
                .then((res) => {
                  if (res) {
                    updateLastConnectionToDatabase(this.props)
                      .then(() => {})
                      .catch((error2) => {
                        if (DEBUG) console.warn(error2);
                      })
                      .finally(() => {
                        this.props.navigation.replace("SignInLoadingScreen");
                      });
                  } else {
                    superSignOut(this.props);
                  }
                })
                .catch((error) => {
                  if (DEBUG) console.warn(error);
                  setSnackbar(
                    "error",
                    error === "No network"
                      ? "Erreur réseau"
                      : "Une erreur est survenue. Veuillez réessayer plus tard",
                    this.props
                  );
                  if (error !== "No network") this.setState({ error: true });
                });
            })
            .catch((err) => {
              if (DEBUG) console.warn(err);
              setSnackbar(
                "error",
                "Une erreur est survenue. Veuillez réessayer plus tard",
                this.props
              );
              this.setState({ error: true });
            });
        } else {
          this.props.navigation.replace("SuperSignIn");
        }
      })
      .catch((err) => {
        if (DEBUG) console.warn(err);
        setSnackbar(
          "error",
          err === "No network"
            ? "Erreur réseau"
            : "Une erreur est survenue. Veuillez réessayer plus tard",
          this.props
        );
        if (err !== "No network") this.setState({ error: true });
      });
  }

  render() {
    return (
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Header preventColorChange />
        <View style={{ flex: 10 }}>
          {this.state.error ? (
            <View
              style={{
                alignSelf: "center",
                justifyContent: "center",
                marginTop: 50,
                padding: 25,
              }}
            >
              <CustomText style={{ textAlign: "center", marginBottom: 50 }}>
                Une erreur est survenue, essayez de recharger l'application. Si
                l'erreur persiste, veuillez contacter le support de
                l'application.
              </CustomText>
              <CustomButton
                title={"Recharger l'application"}
                onPress={() => {
                  resetStorage();
                  window.location.reload(true);
                }}
              />
            </View>
          ) : this.props.isConnectedToNetwork ? (
            displayLoading(true, "Chargement de l'application...")
          ) : (
            renderNoNetworkNotification()
          )}
        </View>
        <View style={{ flex: 1 }}>
          <CustomText style={{ textAlign: "center" }}>
            {"v " + version}
          </CustomText>
        </View>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.setData.currentUser,
    language: state.setLanguage.language,
    isConnectedToNetwork: state.setConnection.isConnectedToNetwork,
    accountAccessKey: state.setAccount.accountAccessKey,
    users: state.setData.users,
    version: state.setVersionGuard.version,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperSignInLoadingScreen);
