import React from 'react';
import { View } from 'react-native';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import { floatingLabelTextFontsize, labelFontSize, textFontSize } from '../../styles/styles';

class CustomDatePicker extends React.Component {
    render() {
        return (
            <div>
                <View style={{ paddingBottom: this.props.noPadding ? 0 : 10, paddingTop: 10, justifyContent: 'center' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"fr"}>
                        <DateTimePicker
                            disabled={this.props.disabled}
                            label={this.props.label}
                            value={this.props.value}
                            onChange={(datePicked) => {
                                let date = datePicked && datePicked['$d'] ? datePicked['$d'] : ""

                                if (date && (date < moment("01/01/1900") || !(date instanceof Date) || isNaN(date))) {
                                    this.props.setErrorLabel("Date invalide")
                                } else {
                                    this.props.setErrorLabel("")
                                }
                                this.props.onChange(date)
                            }}
                            desktopModeMediaQuery=""
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    helperText={this.props.errorLabel}
                                    size='small'
                                    value={this.props.value}
                                    error={this.props.errorLabel !== undefined && this.props.errorLabel !== ''}
                                    InputLabelProps={{ ...params.InputLabelProps, style: { color: 'grey', fontFamily: 'Quicksand-Bold', fontSize: labelFontSize } }}
                                    inputProps={{ ...params.inputProps, style: { color: 'black', fontFamily: 'Quicksand-Regular', fontSize: textFontSize }, placeholder: "JJ/MM/AAAA hh:mm" }}
                                    FormHelperTextProps={{ style: { color: 'red', fontFamily: 'Quicksand-Regular', fontSize: floatingLabelTextFontsize } }}
                                />}
                        />
                    </LocalizationProvider>
                </View>
            </div>

        );
    }
}


export default (CustomDatePicker);
