import React from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import { getAllRelanceByResponsable } from '../../../utilities/utils';
import ContactList from '../Contact/ContactList';
import { globalStyles } from '../../../styles/styles';

class ResponsableListByResponsable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            contacts: []
        }
    }

    componentDidMount() {
        this._loadContacts()
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this._loadContacts()
        }
    }


    _loadContacts() {
        let relances = getAllRelanceByResponsable(this.props.route.params.userId, this.props.data)[this.props.route.params.relance.id]
        this.setState({ contacts: relances })
    }


    render() {
        return (
            <View style={globalStyles.main_container}>
                <ContactList
                    contacts={this.state.contacts}
                    navigation={this.props.navigation}
                    headerTitle={this.props.route.params.relance.text + " : " + (this.props.route.params.userName ? this.props.route.params.userName : this.props.users.find(user => user.id === this.props.route.params.userId).identifiant)}
                    headerBack
                />
            </View>
        );
    }
}


const mapStateToProps = state => {
    return {
        data: state.setData.data,
        lists: state.setData.lists,
        users: state.setData['users'],

    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch: action => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponsableListByResponsable);

