import React from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import Header from '../Headers/Header';
import CustomSelect from '../../CustomComponents/CustomSelect';
import {
  displayLoading,
  getAllResponsables,
  getList,
  getRealWidth,
  handleBack,
  setSnackbar,
  userIsGestionnaire,
} from '../../../utilities/utils';
import {
  globalStyles, textFontSize,
} from '../../../styles/styles';
import DialogAlert from '../../CustomComponents/DialogAlert';
import { addChampPersoToModeleContactToDatabase, addElementToPreListDatabase, createDataToDatabase, deleteDataToDatabase, removeChampPersoToAllDataToDatabase, removeChampPersoToModeleContactToDatabase, removePreListDatabase, updateChampPersoToModeleContactToDatabase } from '../../../db/db';
import CustomButton from '../../CustomComponents/CustomButton';
import { IconButton } from '@mui/material';
import CustomDatePicker from '../../CustomComponents/CustomDatePicker';
import moment from 'moment';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DraggableList from 'react-draggable-list';
import FloatingLabelInput from '../../CustomComponents/FloatingLabelInput';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import CustomText from '../../CustomComponents/CustomText';
import { DEBUG } from '../../../api/constants';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

class ChampItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.item.value,
      infoLabel: ''
    };
  }

  render() {
    const { item } = this.props;
    return (
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          {item.type === 'Texte' ?
            <FloatingLabelInput
              noPadding
              label={item.label}
              onChange={value => {
                this.setState({ value: value })
                item.changeChampValue(value, item.id)
              }}
              value={this.state.value}
              disabled={item.disabled}
            />
            : item.type === 'Date' ?
              <CustomDatePicker
                noPadding
                label={item.label}
                value={this.state.value}
                onChange={(value) => {
                  this.setState({ value: value })
                  item.changeChampValue(value, item.id)
                }}
                setErrorLabel={(errorLabel) => this.setState({ infoLabel: errorLabel })}
                errorLabel={this.state.infoLabel}
                disabled={item.disabled}
              />
              : <CustomSelect
                changeScrolling={item.changeScrolling}
                isClearable
                isSearchable
                noPadding
                label={item.label}
                value={this.state.value}
                onChange={(value) => {
                  this.setState({ value: value.id })
                  item.changeChampValue(value.id, item.id)
                }}
                options={item.options}
                helperText={this.state.infoLabel}
                navigateTo={item.navigateTo}
                setList={item.openSetListDialog ? () => item.openSetListDialog(item.id) : null}
                onInputChange={(value) => {
                  this.setState({ [item.id + 'Input']: value })
                }}
                noOptionsAction={this.state[item.id + 'Input'] && this.state[item.id + 'Input'].trim() !== "" ?
                  <Button
                    style={{ flex: 1, fontFamily: 'Quicksand-Bold' }}
                    onClick={() => item.addElementToChampList(this.state[item.id + 'Input'], item.id)}
                    variant="text"
                  >
                    {"Ajouter " + this.state[item.id + 'Input']}
                  </Button> : null
                }
                disabled={item.disabled}
              />}
        </View>
        {item.openDialogRemoveChamp || item.openDialogEditChamp ?
          <View style={{ flex: 0.1 }}>
            <View style={{ position: 'absolute', right: 0 }}>
              {item.openDialogRemoveChamp ?
                <IconButton
                  style={{ color: 'grey' }}
                  title="Supprimer le champ"
                  size='small'
                  onClick={() => item.openDialogRemoveChamp(item.id)}
                >
                  <DeleteIcon fontSize='medium' />
                </IconButton> : <></>}


              {item.openDialogEditChamp ?
                <IconButton
                  style={{ color: 'grey' }}
                  title="Modifier le champ"
                  size='small'
                  onClick={() => item.openDialogEditChamp(item.id)}
                >
                  <EditIcon fontSize='medium' />
                </IconButton> : <></>}
            </View>
          </View> : <></>}
      </View>

    );
  }
}

class UpdateContact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contact: '',
      isLoading: true,
      isLoading2: false,
      isLoading3: false,
      isDialogVisible: false,
      canCloseDialogVisible: true,
      isDialogVisible2: false,
      canCloseDialogVisible2: true,
      isDialogVisible4: false,
      canCloseDialogVisible4: true,
      isDialogVisible5: false,
      canCloseDialogVisible5: true,
      dateInfoLabel: '',
      champs: [],
      selectedChampsTemp: [],
      list: [],
      scrollEnabled: true,
      champTypeOptions: [{ id: 'Texte', label: 'Texte' }, { id: 'Date', label: 'Date' }, { id: 'Liste', label: 'Liste' }],
      champType: 'Texte',
      champLabel: '',
      champLabelInfoLabel: '',
      selectedChampId: '',
      helperText: '',
    };
  }

  closeDialog = () => {
    this.setState({ isDialogVisible: false })
  }

  closeDialog2 = () => {
    this.setState({ isDialogVisible2: false })
  }

  closeDialog4 = () => {
    this.setState({ isDialogVisible4: false })
  }

  closeDialog5 = () => {
    this.setState({ isDialogVisible5: false })
  }



  componentDidMount() {
    this.unsubscribe = this.props.navigation.addListener('blur', () => {
      window.removeEventListener('popstate', this.listener)
    })

    this.unsubscribe2 = this.props.navigation.addListener('focus', () => {
      setTimeout(() => {
        window.addEventListener('popstate', this.listener = (event) => handleBack(event, this));
      }, 1)
    })

    setTimeout(() => {

      let contact = {
        'Adresse': '',
        'Code postal': '',
        'Date de relance': '',
        'Date de création': moment().toDate(),
        'Email': '',
        'Fonction': '',
        'Nom': '',
        'Observations': '',
        'Pays': '',
        'Prénom': '',
        'Responsable': this.props.currentUser.id,
        'Saisi par': this.props.currentUser.name ? this.props.currentUser.name : this.props.currentUser.identifiant,
        'Secteur': '',
        'Société': '',
        'Statut': '',
        'Téléphone fixe': '',
        'Portable': '',
        'Ville': '',
      }

      let champs = Object.values(JSON.parse(JSON.stringify(this.props.settings.modeleContact)))
      champs.forEach(ch => {
        ch.value = contact[ch.id] === undefined ? "" : contact[ch.id]
      })

      let responsables = getAllResponsables(this.props.users, false)

      this.setState({
        list: this._createList(champs, responsables),
        champs: champs,
        secteurOptions: getList("Secteur", this.props),
        statutOptions: getList("Statut", this.props),
        fonctionOptions: getList("Fonction", this.props),
        sociétéOptions: getList("Société", this.props),
      }, () => {
        this.setState({ isLoading: false })
      });
    }, 100)
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.lists) !== JSON.stringify(this.props.lists) || prevProps.settings.modeleContact !== this.props.settings.modeleContact || prevProps.users !== this.props.users) {
      setTimeout(() => {
        if (this.state.changeValue) {
          this._changeChampValue(this.state.changeValue.key, this.state.changeValue.champId);
          this.setState({ changeValue: false, scrollEnabled: true })
        }
        this._reloadContact();
      }, 100)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.listener)

    if (this.unsubscribe)
      this.unsubscribe()
    if (this.unsubscribe2)
      this.unsubscribe2()
  }

  _addElementToChampList = (value, listId) => {
    if (this.state.isLoading2) {
      return;
    }

    this.setState({ isLoading2: true });

    if (getList(listId, this.props).some(opt => opt.label === value.toString().trim())) {
      setSnackbar('warning', 'Cet élément existe déjà', this.props)

      this.setState({ isLoading2: false });
      return;
    }

    addElementToPreListDatabase(value.toString().trim(), listId, this.props).then((key) => {
      setSnackbar('success', 'Elément créé avec succès', this.props)
      this.setState({ changeValue: { key: key, champId: listId } })
    }).catch(error => {
      setSnackbar('error', error === "No network" ? "Erreur réseau" : 'Une erreur est survenue. Veuillez réessayer plus tard', this.props)
      if (DEBUG) console.warn(error)
    }).finally(() => {
      setTimeout(() => this.setState({ isLoading2: false }), 100);
    });
  };

  _reloadContact() {
    this.setState({ isLoading2: true })

    let champs = Object.values(JSON.parse(JSON.stringify(this.props.settings.modeleContact)))
    champs.forEach(ch => {
      ch.value = this.state.champs.find(chp => chp.id === ch.id) !== undefined && this.state.champs.find(chp => chp.id === ch.id).value !== undefined ? this.state.champs.find(chp => chp.id === ch.id).value : ""
    })

    let responsables = getAllResponsables(this.props.users, false);

    this.setState({
      secteurOptions: getList("Secteur", this.props),
      statutOptions: getList("Statut", this.props),
      fonctionOptions: getList("Fonction", this.props),
      sociétéOptions: getList("Société", this.props),
      list: this._createList(champs, responsables),
      champs: champs,
      reload: new Date(),
      scrollEnabled: true,
      isLoading2: false
    });
  }

  _copyTextToClipboard(text) {
    var textField = document.createElement('textarea')
    textField.innerText = text
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()

    setSnackbar('info', 'Copié dans le presse-papier', this.props)
  }


  _callNumber(number) {
    window.open(`tel:${number} `);
  }

  _sendMessage() {
    const url = `sms:${this.state.champs.find(ch => ch.id === "Portable").value} `;
    window.open(url);
  }

  _deleteContact() {
    if (this.state.isLoading2) {
      return;
    }

    this.setState({ isLoading2: true });

    deleteDataToDatabase(this.props.route.params.contactId, this.props).then(() => {
      setSnackbar('success', 'Contact supprimé avec succès', this.props)
      this.props.navigation.goBack()
    }).catch((error) => {
      setSnackbar('error', error === "No network" ? "Erreur réseau" : 'Une erreur est survenue. Veuillez réessayer plus tard', this.props)
      if (DEBUG) console.warn(error)
    }).finally(() => {
      this.setState({ isDialogVisible: false }, () => {
        setTimeout(() => {
          this.setState({ isLoading2: false })
        }, 100)
      })
    })
  }

  _updateContact = () => {
    if (this.state.isLoading2) {
      return;
    }

    this.setState({ isLoading2: true });

    if (this.state.dateInfoLabel !== '') {
      this.setState({ isLoading2: false });
      return
    }

    let contactU = {}

    let champspersoFormatted = {}
    this.state.champs.forEach(e => {
      champspersoFormatted[e.id] = e.value
    })

    contactU = champspersoFormatted
    contactU["Date de relance"] = contactU["Date de relance"] ? moment(contactU["Date de relance"]).format('DD/MM/YYYY HH:mm') : ""
    contactU["Date de création"] = contactU["Date de création"] ? moment(contactU["Date de création"]).format('DD/MM/YYYY HH:mm') : ""

    createDataToDatabase(contactU, this.props).then(() => {
      setSnackbar('success', 'Contact créé avec succès', this.props)
      this.props.navigation.goBack()
    }).catch((error) => {
      setTimeout(() => {
        this.setState({ isLoading2: false })
      }, 100)
      setSnackbar('error', error === "No network" ? "Erreur réseau" : 'Une erreur est survenue. Veuillez réessayer plus tard', this.props)
      if (DEBUG) console.warn(error)
    })
  };

  _onListChange = (newList) => {
    this.setState({ isLoading2: true, list: newList })
    setTimeout(() => {
      let pos = -1

      newList.forEach(ch => {
        pos++
        updateChampPersoToModeleContactToDatabase('order', pos, ch.id, this.props).then(() => {
          setSnackbar('success', 'Champ déplacé avec succès', this.props)
        }).catch((error) => {
          setSnackbar('error', error === "No network" ? "Erreur réseau" : 'Une erreur est survenue. Veuillez réessayer plus tard', this.props)
          if (DEBUG) console.warn(error)
        }).finally(() => {

        })

      })
      this.setState({ isLoading2: false })
    }, 100)
  }

  _changeChampValue = (value, champId) => {

    let champs = [...this.state.champs];
    let index = champs.findIndex(ch => ch.id === champId)
    champs[index]['value'] = value;

    this.setState({ champs: champs })
  }

  _changeScrolling = (enabled) => {
    this.setState({ scrollEnabled: enabled === undefined ? !this.state.scrollEnabled : enabled })
  }

  _createList(champs, responsables) {
    let list = []

    champs.forEach(champ => {
      list.push({
        name: champ.id,
        id: champ.id,
        type: champ.type,
        label: champ.label,
        value: champ.value,
        order: champ.order,
        changeChampValue: this._changeChampValue,
        options: champ.type === 'Liste' ? (champ.id === 'Responsable') ? responsables : getList(champ.id, this.props) : '',
        openSetListDialog: champ.id === "Responsable" ? null : this._openSetListDialog,
        changeScrolling: this._changeScrolling,
        addElementToChampList: this._addElementToChampList,
        disabled: champ.disabled,
        openDialogRemoveChamp: champ.custom ? this._openDialogRemoveChamp : null,
        openDialogEditChamp: champ.custom ? this._openDialogEditChamp : null,
        navigateTo: champ.id === "Responsable" && userIsGestionnaire(this.props.currentUser) ? () => document.getElementById("utilisateursNav").click() : null
      })
    })

    list.sort(function (a, b) {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    })

    return list;
  }

  _openSetListDialog = (champId) => {
    this.props.navigation.navigate("GestionListe", { listId: champId, listName: this.state.champs.find(ch => ch.id === champId).label })
  }


  _openDialogRemoveChamp = (champId) => {
    this.setState({ isDialogVisible2: true, selectedChampId: champId })
  }

  _openDialogEditChamp = (champId) => {
    let lab = this.state.champs.find(ch => ch.id === champId).label
    this.setState({ isDialogVisible4: true, newChampLabel: lab, selectedChampId: champId, champLabel: lab, champId: champId })
  }

  _removeChamp() {
    if (this.state.isLoading2) {
      return
    }
    this.setState({ isLoading2: true })

    removeChampPersoToModeleContactToDatabase(this.state.selectedChampId, this.props).then(() => {
      removeChampPersoToAllDataToDatabase(this.state.selectedChampId, this.props).then(() => {
        removePreListDatabase(this.state.selectedChampId, this.props).then(() => {
          setSnackbar('success', 'Champ supprimé avec succès', this.props)
        })
      })

    }).catch((error) => {
      setSnackbar('error', error === "No network" ? "Erreur réseau" : 'Une erreur est survenue. Veuillez réessayer plus tard', this.props)
      if (DEBUG) console.warn(error)
    }).finally(() => {
      this.setState({ isDialogVisible5: false }, () => {
        setTimeout(() => {
          this.setState({ isLoading2: false })
        }, 100)
      })
    })
  }

  _addChamp = () => {
    if (this.state.isLoading2) {
      return
    }
    this.setState({ isLoading2: true, champLabelInfoLabel: '' })

    if (this.state.champLabel === '') {
      this.setState({ champLabelInfoLabel: 'Paramètre obligatoire', isLoading2: false })
      return
    }

    if (this.state.champs.map(ch => ch.label).includes(this.state.champLabel)) {
      this.setState({ champLabelInfoLabel: 'Ce champ existe déjà', isLoading2: false })
      return
    }

    let champ = {}
    champ['type'] = this.state.champType
    champ['label'] = this.state.champLabel
    champ['value'] = ''
    champ['order'] = Math.max.apply(Math, this.state.champs.map(function (o) { return o.order + 1; }))
    champ['visible'] = true
    champ['custom'] = true

    addChampPersoToModeleContactToDatabase(champ, this.props).then((key) => {
      if (champ['type'] === 'Liste') {
        addElementToPreListDatabase('', key, this.props).then(() => {

        })
      }
      setSnackbar('success', 'Champ ajouté avec succès', this.props)
    }).catch((error) => {
      setSnackbar('error', error === "No network" ? "Erreur réseau" : 'Une erreur est survenue. Veuillez réessayer plus tard', this.props)
      if (DEBUG) console.warn(error)
    }).finally(() => {
      this.setState({ isDialogVisible: false, champLabel: '', champLabelInfoLabel: '', champType: 'Texte' }, () => {
        setTimeout(() => {
          this.setState({ isLoading2: false })
        }, 100)
      })

    })
  }

  _updateChampLabel() {
    if (this.state.isLoading2) {
      return;
    }
    this.setState({ isLoading2: true });

    updateChampPersoToModeleContactToDatabase('label', this.state.newChampLabel, this.state.selectedChampId, this.props).then(() => {
      setSnackbar('success', 'Champ modifié avec succès', this.props)
    }).catch(error => {
      setSnackbar('error', error === "No network" ? "Erreur réseau" : 'Une erreur est survenue. Veuillez réessayer plus tard', this.props)
      if (DEBUG) console.warn(error)
    }).finally(() => {
      this.setState({
        champLabel: this.state.newChampLabel,
        isDialogVisible4: false
      }, () => setTimeout(() => this.setState({ isLoading2: false }), 100));
    });
  }


  render() {
    return (
      <View style={globalStyles.main_container}>
        <Header
          navigation={this.props.navigation}
        >
          Nouveau contact
        </Header>

        <DialogAlert
          isDialogVisible={this.state.isDialogVisible2}
          onClose={() => {
            this.closeDialog2()
          }}
          title={"Supprimer un champ"}
          text={'Etes-vous sûr de vouloir supprimer ce champ ?'}
          confirm={() => this.setState({ isDialogVisible2: false, isDialogVisible5: true })}
          cancel={() => {
            this.closeDialog2()
          }}
        />

        <DialogAlert
          isDialogVisible={this.state.isDialogVisible5}
          onClose={() => {
            this.closeDialog5()
          }}
          title={"Confirmation"}
          confirm={() => this._removeChamp()}
          cancel={() => {
            this.closeDialog5()
          }}
          content={<View>
            <WarningAmberIcon
              color='warning'
              fontSize={'large'}
              style={{ marginBottom: 7, textAlign: 'center', alignSelf: 'center', justifyContent: 'center' }}
            />
            <CustomText
              style={{ fontSize: textFontSize - 2 }}
            >
              Etes-vous certain de vouloir supprimer ce champ ? Il sera impossible de le récupérer.
            </CustomText>
          </View>}
        />

        <Dialog
          onBackdropClick={() => this.closeDialog()}
          open={this.state.isDialogVisible}
          onClose={() => this.closeDialog()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ fontFamily: 'Quicksand-Regular' }}
          PaperProps={{ style: { width: getRealWidth() * 0.75 } }}
        >
          <DialogTitle
            style={{ fontFamily: 'Quicksand-Bold' }}
          >
            Ajouter un nouveau champ
          </DialogTitle>
          <DialogContent
            style={{ fontFamily: 'Quicksand-Bold' }}
          >
            <View style={{ paddingBottom: 12, fontSize: textFontSize - 3 }}>
              <CustomText style={{ fontSize: textFontSize - 3 }}>Ce nouveau champ sera ajouté à tous vos contacts !</CustomText>
            </View>
            <CustomSelect
              label={"Type"}
              value={this.state.champType}
              onChange={(value) => this.setState({ champType: value.id })}
              options={this.state.champTypeOptions}
            />
            <FloatingLabelInput
              label="Nom du champ"
              onChange={value => this.setState({ champLabel: value })}
              value={this.state.champLabel}
              helperText={this.state.champLabelInfoLabel}
              maxLength={30}
            />
          </DialogContent>
          <DialogActions>
            <Button
              style={{ fontFamily: 'Quicksand-Bold' }}
              onClick={() => this.closeDialog()}
              color="primary"
            >
              Annuler
            </Button>
            <Button
              style={{ fontFamily: 'Quicksand-Bold' }}
              onClick={() => this._addChamp()}
              color="primary"
            >
              Valider
            </Button>
          </DialogActions>
        </Dialog>



        <Dialog
          onBackdropClick={() => this.closeDialog4()}
          open={this.state.isDialogVisible4}
          onClose={() => this.closeDialog4()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ fontFamily: 'Quicksand-Regular' }}

        >
          <DialogTitle
            style={{ fontFamily: 'Quicksand-Bold' }}
          >
            Modifier un champ
          </DialogTitle>
          <DialogContent
            style={{ fontFamily: 'Quicksand-Bold', }}
          >

            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ flex: 1 }}>
                <FloatingLabelInput
                  defaultValue={this.state.champLabel}
                  onChange={newChampLabel => this.setState({ newChampLabel: newChampLabel })}
                  value={this.state.newChampLabel}
                  variant='standard'
                  maxLength={30}
                />
              </View>

              <View>
                <IconButton
                  color='primary'
                  title="Valider"
                  onClick={() => this._updateChampLabel()}
                  size='small'
                  style={{ marginLeft: 7 }}
                >
                  <DoneIcon
                    fontSize={'small'}
                  />
                </IconButton>
              </View>
              <View>
                <IconButton
                  color='primary'
                  title="Annuler"
                  onClick={() => this.closeDialog4()}
                  size='small'
                  style={{ marginLeft: 7 }}
                >

                  <CloseIcon
                    fontSize={'small'}
                  />
                </IconButton>
              </View>
            </View>


          </DialogContent>
        </Dialog>



        {displayLoading(this.state.isLoading2 || this.state.isLoading3)}
        {this.state.isLoading
          ? displayLoading(this.state.isLoading)
          :
          <View style={globalStyles.list_container}>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '5%',
                paddingLeft: '7%',
                paddingRight: '7%',
                opacity: this.state.isLoading2 ? 0.5 : this.state.isLoading3 ? 0 : 1,
                overflowY: 'scroll',
                paddingBottom: this.props.fab ? 80 : '5%',
                scrollbarWidth: 'none',
              }}
            >
              <div
                style={{ display: 'flex', flexDirection: 'column' }} className='noselect'>

                <DraggableList
                  key={this.state.reload}
                  itemKey="name"
                  template={ChampItem}
                  list={this.state.list}
                  onMoveEnd={(newList) => this._onListChange(newList)}
                  container={() =>
                    document.body
                  }
                />
                <Button style={{ fontFamily: 'Quicksand-Bold', alignSelf: 'flex-start' }} onClick={() => this.setState({ champLabel: '', champType: 'Texte', isDialogVisible: true })} variant="text">
                  Créer un nouveau champ
                </Button>

                <View
                  style={{
                    marginTop: 15,
                    alignSelf: 'center',
                  }}
                >
                  <CustomButton
                    title={"Créer"}
                    onPress={() => this._updateContact()}
                  />
                </View>
              </div>

            </div>
          </View>}
      </View>
    );
  }
}


const mapStateToProps = state => {
  return {
    data: state.setData.data,
    users: state.setData['users'],
    currentUser: state.setData['currentUser'],
    isConnectedToNetwork: state.setConnection.isConnectedToNetwork,
    settings: state.setAccount.settings,
    lists: state.setData.lists,
    accountAccessKey: state.setAccount.accountAccessKey

  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: action => {
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateContact);
