import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { floatingLabelTextFontsize, labelFontSize, textFontSize } from '../../styles/styles';

class DialogInput extends React.Component {
  render() {
    return (
      <Dialog
        onBackdropClick={() => this.props.onClose()}
        open={this.props.isDialogVisible}
        onClose={() => this.props.onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ fontFamily: 'Quicksand-Regular' }}

      >
        <DialogTitle
          style={{ fontFamily: 'Quicksand-Bold' }}
        >
          {this.props.title}
        </DialogTitle>
        <DialogContent style={{ fontFamily: 'Quicksand-Bold' }}>
          {this.props.extraContent ? this.props.extraContent : <></>}

          <TextField
            margin="dense"
            label={this.props.placeholder}
            size='small'
            variant="outlined"
            defaultValue={this.props.defaultValue}
            placeholder={this.props.placeholder}
            onChange={event => this.props.onChange(event.target.value)}
            InputLabelProps={{ style: { color: 'grey', fontFamily: 'Quicksand-Bold', fontSize: labelFontSize } }}
            inputProps={{ style: { color: 'black', fontFamily: 'Quicksand-Regular', fontSize: textFontSize }, maxLength: this.props.maxLength }}
            FormHelperTextProps={{ style: { color: 'red', fontFamily: 'Quicksand-Regular', fontSize: floatingLabelTextFontsize } }}
            helperText={this.props.helperText}
            error={this.props.helperText !== undefined && this.props.helperText !== ''}
            onSubmit={() => this.props.confirm()}
          />
          {this.props.placeholder2 ?
            <TextField

              margin="dense"
              label={this.props.placeholder2}
              size='small'
              variant="outlined"
              defaultValue={this.props.defaultValue2}
              placeholder={this.props.placeholder2}
              onChange={event => this.props.onChange2(event.target.value)}
              InputLabelProps={{ style: { color: 'grey', fontFamily: 'Quicksand-Bold', fontSize: labelFontSize } }}
              inputProps={{ style: { color: 'black', fontFamily: 'Quicksand-Regular', fontSize: textFontSize }, maxLength: this.props.maxLength }}
              FormHelperTextProps={{ style: { color: 'red', fontFamily: 'Quicksand-Regular', fontSize: floatingLabelTextFontsize } }}
              helperText={this.props.helperText2}
              error={this.props.helperText2 !== undefined && this.props.helperText2 !== ''}
              onSubmit={() => this.props.confirm()}

            /> : <></>}
        </DialogContent>
        <DialogActions>

          <Button
            style={{ fontFamily: 'Quicksand-Bold' }}
            onClick={() => this.props.cancel()}
            color="primary"

          >
            Annuler
          </Button>
          <Button
            style={{ fontFamily: 'Quicksand-Bold' }}
            onClick={() => this.props.confirm()}
            color="primary"
          >
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DialogInput;
