const initialState = { isConnectedToNetwork: true };

function setConnection(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case 'SET_CONNECTION':
      nextState = {
        ...state,
        isConnectedToNetwork: action.value,
      };
      return nextState || state;
    default:
      return state;
  }
}

export default setConnection;
