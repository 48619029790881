import React from 'react';
import {
    Text,
    View,
} from 'react-native';
import CustomText from '../../CustomComponents/CustomText';
import CustomButton from '../../CustomComponents/CustomButton';
import CustomTitle from '../../CustomComponents/CustomTitle';
import Header from '../Headers/Header';
import { connect } from 'react-redux';
import { displayLoading, handleBack, setSnackbar } from '../../../utilities/utils';
import { updateSendinblueApiKeyToDatabase } from '../../../db/db';
import { Fab } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { globalStyles, textFontSize } from '../../../styles/styles';
import NoNetworkNotification from '../../CustomComponents/NoNetworkNotification';
import { IconButton } from '@mui/material';
import { aesSecretKey, DEBUG } from '../../../api/constants';
import DialogInput from '../../CustomComponents/DialogInput';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

var CryptoJS = require("crypto-js");

class PersonnalisationSendinblue extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isLoading2: false,
            sendinblueApiKey: '',
            isDialogVisible: false
        };
    }

    componentDidMount() {
        this.unsubscribe = this.props.navigation.addListener('blur', () => {
            window.removeEventListener('popstate', this.listener)
        })

        this.unsubscribe2 = this.props.navigation.addListener('focus', () => {
            setTimeout(() => {
                window.addEventListener('popstate', this.listener = (event) => handleBack(event, this));
            }, 1)
        })
    }


    componentWillUnmount() {
        window.removeEventListener('popstate', this.listener)

        if (this.unsubscribe)
            this.unsubscribe()
        if (this.unsubscribe2)
            this.unsubscribe2()
    }

    closeDialog = () => {
        this.setState({ isDialogVisible: false })
    }


    _setSendinblueApiKey() {
        if (this.state.isLoading2) {
            return;
        }

        this.setState({ isLoading2: true, helperText: '' });

        if (this.state.sendinblueApiKey.toString().trim() === '') {
            this.setState({ helperText: "Paramètre obligatoire", isLoading2: false });
            return;
        }

        setTimeout(() => {
            let hash = CryptoJS.AES.encrypt(this.state.sendinblueApiKey, aesSecretKey).toString();

            updateSendinblueApiKeyToDatabase(hash, this.props).then(() => {
                setSnackbar('success', "Clé API Brevo (ex Sendinblue) enregistrée avec succès", this.props)
            }).catch(err => {
                if (DEBUG) console.warn(err)
                setSnackbar('error', err === "No network" ? "Erreur réseau" : 'Une erreur est survenue. Veuillez réessayer plus tard', this.props)
            }).finally(() => {
                this.setState({ isDialogVisible: false }, () => {
                    setTimeout(() => {
                        this.setState({ isLoading2: false })
                    }, 100)
                })
            })
        }, 100)
    }




    render() {
        return (
            <View style={{ flex: 1 }}>
                <Header
                    navigation={this.props.navigation}
                >
                </Header>
                <DialogInput
                    isDialogVisible={this.state.isDialogVisible}
                    onClose={() => this.closeDialog()}
                    title={"Ajouter une clé API Brevo (ex Sendinblue)"}
                    placeholder={"Clé API"}
                    defaultValue={''}
                    onChange={(value) => this.setState({ sendinblueApiKey: value })}
                    confirm={() => this._setSendinblueApiKey()}
                    cancel={() => this.closeDialog()}
                    helperText={this.state.helperText}
                    extraContent={<View style={{ paddingBottom: 12, fontSize: textFontSize - 3 }}>
                        <Text>
                            <CustomText style={{ fontSize: textFontSize - 3 }}>Vous trouverez cette information dans la section "SMTP & API" de votre profil Brevo ! </CustomText>
                            <IconButton
                                style={{ padding: 0 }}
                                title='Ouvrir le lien vers Brevo (ex Sendinblue)'
                                onClick={() => {
                                    window.open("https://app.brevo.com/settings/keys/api")
                                }}
                                size='small'
                            >
                                <OpenInNewIcon
                                    fontSize={'small'}
                                />
                            </IconButton>
                        </Text>
                    </View>}
                />

                {displayLoading(this.state.isLoading2)}
                {this.state.isLoading ? displayLoading(this.state.isLoading) :
                    <View
                        style={[{
                            paddingVertical: 20,
                            flex: 1,
                            opacity: this.state.isLoading2 ? 0.5 : 1,
                        }, globalStyles.list_container]}>
                        <div style={{
                            paddingLeft: '7%',
                            paddingRight: '7%',
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1
                        }}>
                            <View style={{ flex: 1 }}>

                                <View style={{ paddingBottom: 20, justifyContent: 'center', alignItems: 'center' }}>
                                    <CustomTitle style={{ fontSize: 25, textAlign: 'center' }}>
                                        Personnalisez votre Tankō
                                    </CustomTitle>
                                </View>
                                <View style={{ paddingVertical: 10, justifyContent: 'center' }}>
                                    <CustomText style={{}}>
                                        Ajoutez une clé API Brevo (ex Sendinblue) et envoyez des mails groupés à l'aide de vos templates personnalisés Brevo depuis Tankō
                                    </CustomText>
                                </View>
                                <View style={{ paddingVertical: 10, justifyContent: 'center' }}>

                                    <CustomText style={{}}>
                                        Vous pourrez toujours ajouter une clé API Brevo plus tard dans les paramètres.
                                    </CustomText>
                                </View>

                                <View
                                    style={{
                                        paddingTop: 30,
                                        alignSelf: 'center',
                                    }}
                                >
                                    <CustomButton
                                        onPress={() => this.setState({ sendinblueApiKey: '', isDialogVisible: true })}
                                        title={this.props.settings && this.props.settings.sendinblueApiKey ? "Modifier ma clé API Brevo" : "Ajouter une clé API Brevo"}
                                    />

                                </View>
                                <View style={{ position: 'absolute', right: 0, bottom: 20 }}>
                                    <Fab onClick={() => this.props.navigation.navigate("PersonnalisationImportContact")}
                                        color="primary"
                                        title="Suivant"
                                    >
                                        <ArrowForwardIcon style={{ fontSize: 30, color: 'white' }} />
                                    </Fab>
                                </View>
                            </View>
                            <View
                                style={{
                                    alignSelf: 'center',
                                }}
                            >
                                <CustomButton
                                    onPress={() => this.props.navigation.navigate("PersonnalisationImportContact")}
                                    title={"Ignorer cette étape pour l'instant"}
                                />
                            </View>
                        </div>
                    </View>}
                <NoNetworkNotification />

            </View >

        );
    }
}


const mapStateToProps = state => {
    return {
        language: state.setLanguage.language,
        users: state.setData.users,
        currentUser: state.setData.currentUser,
        isConnectedToNetwork: state.setConnection.isConnectedToNetwork,
        accountAccessKey: state.setAccount.accountAccessKey,
        settings: state.setAccount.settings,

    };
};


const mapDispatchToProps = dispatch => {
    return {
        dispatch: action => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonnalisationSendinblue);