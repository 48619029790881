const initialState = { accountAccessKey: '', id: '', abonnement: '', abonnementEotTime: '', deleted: false, settings: {} }

function setAccount(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case 'SET_ACCOUNT_ACCESS_KEY':
            nextState = {
                ...state,
                accountAccessKey: action.value,
            };
            return nextState || state;
        case 'SET_ACCOUNT_ABONNEMENT':
            nextState = {
                ...state,
                abonnement: action.value,
            };
            return nextState || state;
        case 'SET_ACCOUNT_ABONNEMENT_EOT_TIME':
            nextState = {
                ...state,
                abonnementEotTime: action.value,
            };
            return nextState || state;
        case 'SET_ACCOUNT_ID':
            nextState = {
                ...state,
                id: action.value,
            };
            return nextState || state;
        case 'SET_ACCOUNT_DELETED':
            nextState = {
                ...state,
                deleted: action.value,
            };
            return nextState || state;

        case 'SET_ACCOUNT_SETTINGS':
            nextState = {
                ...state,
                settings: action.value,
            };
            return nextState || state;

        default:
            return state;
    }
}

export default setAccount;
