import React from 'react';
import { Provider } from 'react-redux';
import Store from './redux/store/configureStore';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import AppContainer from './components/App/AppContainer';
import ErrorBoundary from './components/CustomComponents/ErrorBoundary';

export default class App extends React.Component {
    render() {
        let persistor = persistStore(Store);
        return (
            <ErrorBoundary>
                <Provider store={Store}>
                    <PersistGate persistor={persistor}>
                        <AppContainer />
                    </PersistGate>
                </Provider>
            </ErrorBoundary>
        );
    }
}
