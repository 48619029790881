import React from 'react';
import { connect } from 'react-redux';
import { TouchableOpacity, View } from 'react-native';
import Header from '../Headers/Header';
import CustomList from '../../CustomComponents/CustomList';
import { displayLoading, getColor, getList, handleBack, setSnackbar } from '../../../utilities/utils';
import CustomText from '../../CustomComponents/CustomText';
import { Checkbox, Fab, IconButton, Modal, Paper } from '@mui/material';
import DialogAlert from '../../CustomComponents/DialogAlert';
import GestionListeItem from './GestionListeItem';
import DeleteIcon from '@mui/icons-material/Delete';
import { globalStyles, textFontSize } from '../../../styles/styles';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { addElementToPreListDatabase, deleteMultipleElementPreListToDatabase, updateElementToPreListDatabase } from '../../../db/db';
import DialogInput from '../../CustomComponents/DialogInput';
import { DEBUG } from '../../../api/constants';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

class GestionListe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isLoading2: false,
            isDialogVisible: false,
            canCloseDialogVisible: true,
            isDialogVisible2: false,
            canCloseDialogVisible2: true,
            isDialogVisible3: false,
            canCloseDialogVisible3: true,
            isDialogVisible4: false,
            canCloseDialogVisible4: true,
            list: [],
            listModele: [],
            listHeight: 0,
            listWidth: 0,
            reload: "",
            reload2: "",
            checked: false,
            checkedItems: [],
            helperText: '',
            selectedItem: '',
            search: '',
            value: "",
        };
    }

    closeDialog = () => {
        this.setState({ isDialogVisible: false })
    }

    closeDialog2 = () => {
        this.setState({ isDialogVisible2: false })
    }

    closeDialog3 = () => {
        this.setState({ isDialogVisible3: false })
    }


    closeDialog4 = () => {
        this.setState({ isDialogVisible4: false })
    }

    componentDidMount() {
        this.unsubscribe = this.props.navigation.addListener('blur', () => {
            window.removeEventListener('popstate', this.listener)
        })

        this.unsubscribe2 = this.props.navigation.addListener('focus', () => {
            setTimeout(() => {
                window.addEventListener('popstate', this.listener = (event) => handleBack(event, this));
            }, 1)

        })
        this._loadList()
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.deleteMode && this.state.deleteMode) {
            this.setState({ checkedItems: [] })
        }
        if (prevProps.lists !== this.props.lists) {
            this._loadList()
            this._search(this.state.search);

        }
        if (prevState.checkedItems !== this.state.checkedItems) {
            if (this.state.checkedItems.length === this.state.listModele.length) {
                this.setState({ checked: true })
            } else {
                this.setState({ checked: false })
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.listener)
        if (this.unsubscribe)
            this.unsubscribe()
        if (this.unsubscribe2)
            this.unsubscribe2()
    }


    _loadList() {
        this.setState({ listModele: getList(this.props.route.params.listId, this.props), list: getList(this.props.route.params.listId, this.props), reload2: new Date().getTime(), isLoading: false }, () => {
            this.setState({})
        })
    }


    _renderItem = (item) => {
        return (
            <GestionListeItem
                deleteMode={this.state.deleteMode}
                checkItem={() => this._checkItem(item.id)}
                isChecked={() => this._isChecked(item.id)}
                listItem={item}
                openUpdateDialog={(item) => this.setState({ isDialogVisible3: true, selectedItem: item, value: item.label })}
            />
        );
    };

    _checkAllItems() {
        this.setState({ checkedItems: this.state.checked ? [] : this.state.listModele.map(c => c.id), checked: !this.state.checked, reload: new Date().getTime() })
    }

    _checkItem = (id) => {
        if (this.state.checkedItems.includes(id)) {
            this.setState({ checkedItems: this.state.checkedItems.filter(item => item !== id) })
        } else {
            this.setState({ checkedItems: this.state.checkedItems.concat(id) })
        }

    }

    _isChecked = (id) => {
        if (this.state.checkedItems.includes(id)) {
            return true

        } else {
            return false
        }
    }

    _add = () => {
        if (this.state.isLoading2) {
            return;
        }

        this.setState({ isLoading2: true, helperText: '' });

        if (this.state.value.toString().trim() === '') {
            this.setState({ helperText: "Paramètre obligatoire", isLoading2: false });
            return;
        }

        if (this.state.listModele.some(opt => opt.label === this.state.value.toString().trim())) {
            this.setState({ helperText: "Cet élément existe déjà", isLoading2: false });
            return;
        }



        addElementToPreListDatabase(this.state.value.toString().trim(), this.props.route.params.listId, this.props).then(() => {
            setSnackbar('success', 'Elément créé avec succès', this.props)
        }).catch(error => {
            setSnackbar('error', error === "No network" ? "Erreur réseau" : 'Une erreur est survenue. Veuillez réessayer plus tard', this.props)
            if (DEBUG) console.warn(error)
        }).finally(() => {
            this.setState({
                value: '',
                isDialogVisible2: false,
            }, () => setTimeout(() => this.setState({ isLoading2: false }), 100));
        });
    };

    _update = () => {
        if (this.state.isLoading2) {
            return;
        }
        this.setState({ isLoading2: true, helperText: '' });

        if (this.state.value.toString().trim() === '') {
            this.setState({ helperText: "Champ obligatoire", isLoading2: false });
            return;
        }

        if (getList(this.props.route.params.listId, this.props).some(opt => opt.label === this.state.value.toString().trim())) {
            this.setState({ helperText: "Cet élément existe déjà", isLoading2: false });
            return;
        }

        let element = getList(this.props.route.params.listId, this.props).find(e => e.id === this.state.selectedItem.id);
        if (!element) {
            this.setState({ value: '', isDialogVisible3: false, isLoading2: false });
            setSnackbar('error', "Cet élément n'existe plus", this.props)
            return;
        }

        updateElementToPreListDatabase(this.state.selectedItem.id, this.state.value.toString().trim(), this.props.route.params.listId, this.props).then(() => {
            setSnackbar('success', 'Elément modifié avec succès', this.props)
        }).catch(error => {
            setSnackbar('error', error === "No network" ? "Erreur réseau" : 'Une erreur est survenue. Veuillez réessayer plus tard', this.props)
            if (DEBUG) console.warn(error)
        }).finally(() => {
            this.setState({
                selectedItem: '',
                value: '',
                isDialogVisible3: false,
            }, () => setTimeout(() => this.setState({ isLoading2: false }), 100));
        });
    };

    _displayList() {
        if (this.state.list.length === 0) {
            return (
                <View
                    style={{
                        flex: 1,
                        alignItems: 'center',
                        paddingTop: '5%',
                        paddingHorizontal: '5%'
                    }}
                >
                    <CustomText>Aucun élement présent dans la liste.</CustomText>
                </View>
            );
        } else {
            return (
                <View key={this.state.reload2} onLayout={(event) => this.setState({ listHeight: event.nativeEvent.layout.height, listWidth: event.nativeEvent.layout.width })} style={{ flex: 1 }}>
                    {this.state.deleteMode ?
                        <Modal
                            onBackdropClick={() => this.setState({ deleteMode: false })}
                            disablePortal
                            sx={{ position: 'absolute', overflowY: 'scroll' }}
                            BackdropProps={{
                                style: {
                                    backgroundColor: "transparent",
                                    boxShadow: "none"
                                }
                            }}
                            open={true}
                        >
                            <View style={{ height: '100%', paddingTop: 4 }} key={this.state.reload}>
                                <Paper style={{ marginLeft: 15, marginRight: 15, borderRadius: 10, marginBottom: 3 }} elevation={3}>
                                    <TouchableOpacity onPress={() => this._checkAllItems()} style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <Checkbox
                                            checked={this.state.checked}
                                        />
                                        <CustomText style={{ fontFamily: 'Quicksand-Bold', color: getColor("color1", this.props), textTransform: 'uppercase', fontSize: textFontSize - 3 }} >Sélectionner tous les éléments</CustomText>
                                    </TouchableOpacity>
                                </Paper>
                                <CustomList
                                    listWidth={this.state.listWidth}
                                    listHeight={this.state.listHeight}
                                    rowHeight={60}
                                    datas={this.state.list}
                                    renderItem={
                                        this._renderItem
                                    }
                                    addButton
                                />
                            </View>
                        </Modal>
                        :
                        <CustomList
                            listWidth={this.state.listWidth}
                            listHeight={this.state.listHeight}
                            rowHeight={60}
                            datas={this.state.list}
                            renderItem={
                                this._renderItem
                            }
                            addButton
                        />
                    }
                </View>
            );
        }
    }

    _deleteConfirm() {
        if (this.state.isLoading2) {
            return
        }
        this.setState({ isLoading2: true })

        deleteMultipleElementPreListToDatabase(this.state.checkedItems, this.props.route.params.listId, this.props).then(() => {
            setSnackbar('success', 'Elément(s) supprimé(s) avec succès', this.props)
        }).catch((error) => {
            setSnackbar('error', error === "No network" ? "Erreur réseau" : 'Une erreur est survenue. Veuillez réessayer plus tard', this.props)
            if (DEBUG) console.warn(error)
        }).finally(() => {
            this.setState({ isDialogVisible4: false }, () => {
                setTimeout(() => {
                    this.setState({ checkedItems: [], deleteMode: false, isLoading2: false })
                }, 100)
            })
        })
    }

    _openAddDialog() {
        this.setState({ helperText: '', isDialogVisible2: true })
    }

    _openUpdateDialog() {
        this.setState({ helperText: '', isDialogVisible3: true })
    }

    _search = async text => {
        this.setState({ search: text });

        setTimeout(() => {
            if (text === this.state.search) {
                let newList = this.state.listModele.filter(item => {
                    let itemData = ''

                    itemData = item['label'].normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                    itemData = itemData.toUpperCase();

                    return itemData.includes(text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase());

                });
                this.setState({ list: (newList) });
            }
        }, 100);
    };

    render() {
        return (
            <View style={{ flex: 1 }}>
                <Header
                    searchOnClick={() => this._search('')}
                    searchOnChangeText={text => this._search(text)}
                    searchValue={this.state.search}
                    navigation={this.props.navigation}
                    icon={
                        <div>
                            <IconButton
                                color='secondary'
                                title="Supprimer"
                                onClick={() => this.setState(prevState => ({ deleteMode: !prevState.deleteMode }))}
                                size='medium'
                            >
                                <DeleteIcon
                                    fontSize={'medium'}
                                />
                            </IconButton>
                        </div>
                    }
                >
                    {"Gestion de la liste " + this.props.route.params.listName}
                </Header>

                <DialogAlert
                    isDialogVisible={this.state.isDialogVisible}
                    onClose={() => {
                        this.closeDialog()
                    }}
                    title={"Supprimer des éléments"}
                    text={'Etes-vous sûr de vouloir supprimer les élements sélectionnés ?'}
                    confirm={() => this.setState({ isDialogVisible: false, isDialogVisible4: true })}
                    cancel={() => {
                        this.closeDialog()
                    }}
                />

                <DialogAlert
                    isDialogVisible={this.state.isDialogVisible4}
                    onClose={() => {
                        this.closeDialog4()
                    }}
                    title={"Confirmation"}
                    confirm={() => this._deleteConfirm()}
                    cancel={() => {
                        this.closeDialog4()
                    }}
                    content={<View>
                        <WarningAmberIcon
                            color='warning'
                            fontSize={'large'}
                            style={{ marginBottom: 7, textAlign: 'center', alignSelf: 'center', justifyContent: 'center' }}
                        />
                        <CustomText
                            style={{ fontSize: textFontSize - 2 }}
                        >
                            Etes-vous certain de vouloir supprimer les éléments sélectionnés ? Il sera impossible de les récupérer.
                        </CustomText>
                    </View>}
                />


                <DialogInput
                    isDialogVisible={this.state.isDialogVisible2}
                    onClose={() => this.closeDialog2()}
                    title={"Ajouter un élément"}
                    placeholder={this.props.route.params.listName}
                    defaultValue={''}
                    onChange={(value) => this.setState({ value: value })}
                    confirm={() => this._add()}
                    cancel={() => this.closeDialog2()}
                    helperText={this.state.helperText}
                />

                <DialogInput
                    isDialogVisible={this.state.isDialogVisible3}
                    onClose={() => this.closeDialog3()}
                    title={"Modifier un élément"}
                    placeholder={this.props.route.params.listName}
                    defaultValue={this.state.selectedItem.label}
                    onChange={(value) => this.setState({ value: value })}
                    confirm={() => this._update()}
                    cancel={() => this.closeDialog3()}
                    helperText={this.state.helperText}
                />

                {displayLoading(this.state.isLoading2)}
                {this.state.isLoading ? displayLoading(this.state.isLoading) :
                    <View
                        style={[{
                            flex: 1,
                            opacity: this.state.isLoading2 ? 0.5 : 1,
                            paddingTop: 8
                        }, globalStyles.list_container]}
                    >
                        {this._displayList()}
                        {(!this.state.deleteMode) || (this.state.deleteMode && this.state.checkedItems.length > 0) ?
                            <View style={{ position: 'absolute', right: 15, bottom: 15, zIndex: 2 }}>
                                <Fab
                                    id={this.state.deleteMode ? "" : "gestionListeAddButton"}
                                    title={this.state.deleteMode ? "Supprimer les éléments sélectionnés" : "Créer un élément"}
                                    onClick={() => this.state.deleteMode ? this.setState({ isDialogVisible: true }) : this._openAddDialog()} color="primary">
                                    {this.state.deleteMode ? <DeleteIcon style={{ fontSize: 30, color: 'white' }} /> :
                                        <AddIcon style={{ fontSize: 30, color: 'white' }} />}
                                </Fab>
                            </View> : <></>}
                        {this.state.deleteMode ?
                            <View style={{ position: 'absolute', left: 15, bottom: 15, flexDirection: 'row', zIndex: 1 }}>
                                <Fab
                                    title="Annuler"
                                    onClick={() => {
                                        this.setState({ checkedItems: [], deleteMode: false })
                                    }}
                                    color="primary"
                                >
                                    <CloseIcon style={{ fontSize: 30, color: 'white' }} />
                                </Fab>
                                <Paper sx={{ border: 1 }} elevation={8} style={{ backgroundColor: 'white', alignSelf: 'center', borderColor: getColor('color1', this.props), borderWidth: 2, padding: 7, paddingLeft: 12, borderLeftWidth: 0, marginLeft: -7 }}>
                                    <CustomText>{this.state.checkedItems.length + ' élément(s) sélectionné(s)'}</CustomText>
                                </Paper>
                            </View> : <></>}
                    </View>}

            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.setData.data,
        settings: state.setAccount.settings,
        currentUser: state.setData.currentUser,
        lists: state.setData.lists,
        isConnectedToNetwork: state.setConnection.isConnectedToNetwork,
        accountAccessKey: state.setAccount.accountAccessKey

    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch: action => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GestionListe);
