import React from 'react';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { getColor } from '../../utilities/utils';

class CustomButton extends React.Component {

  render() {
    return (
      <Button
        aria-label={this.props.title}
        style={{
          fontFamily: 'Quicksand-Bold',
          backgroundColor: getColor("color1", this.props),
          width: '100%',
          color: 'white'
        }}
        disabled={this.props.disabled}
        onClick={this.props.onPress}
        variant="contained"
      >{this.props.title}
      </Button>

    );
  }
}

const mapStateToProps = state => {
  return {
    settings: state.setAccount.settings,
    currentUser: state.setData.currentUser

  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: action => {
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomButton);

