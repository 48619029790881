const initialState = { isVersionValid: true, version: '' };

function setVersionGuard(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case 'SET_VERSION_GUARD':
      nextState = {
        ...state,
        isVersionValid: action.value,
      };
      return nextState || state;
    case 'SET_VERSION':
      nextState = {
        ...state,
        version: action.value,
      };
      return nextState || state;
    default:
      return state;
  }
}

export default setVersionGuard;
