const initialState = { snackbar: '', fcmSnackbar: '' };

function setSnackbar(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case 'SET_SNACKBAR':
            nextState = {
                ...state,
                snackbar: action.value,
            };
            return nextState || state;
        case 'SET_FCM_SNACKBAR':
            nextState = {
                ...state,
                snackbar: action.value,
            };
            return nextState || state;
        default:
            return state;
    }
}

export default setSnackbar;
