import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import { db } from "./dbInit";
import moment from "moment";
import { promiseTimeout, roles } from "../utilities/utils";
import { DEBUG } from "../api/constants";

export const infoTableName = "DB_INFO";

export const bddTableName = "BDD";
export const dataTableName = "DATAS";
export const userTableName = "USERS";
export const setupTableName = "SETUP";
export const setingsTableName = "SETTINGS";

const timeout = 15000;
export const version = "1.1.3";

export function countVisit(props) {
  return promiseTimeout(
    timeout,
    "countVisit",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(infoTableName + "/visit")
        .set(firebase.database.ServerValue.increment(1))
        .then(() => {
          resolve();
        })
        .catch((err) => {
          if (DEBUG) console.warn(err);
          reject();
        });
    })
  );
}

export function checkIfDBExists(code, props) {
  return promiseTimeout(
    timeout,
    "checkIfDBExists",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(bddTableName + "/" + code + "/exist").on("value", (snapshot) => {
        if (snapshot.exists()) {
          resolve(true);
        } else {
          let action = { type: "SET_ACCOUNT_DELETED", value: true };
          props.dispatch(action);
          resolve(false);
        }
      });
    })
  );
}

export function checkIfDBExistsForCreate(code, props) {
  let firstExec = true;
  return promiseTimeout(
    timeout,
    "checkIfDBExistsForCreate",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(bddTableName + "/" + code + "/exist").on("value", (snapshot) => {
        if (snapshot.exists()) {
          resolve(true);
        } else {
          if (!firstExec) {
            let action = { type: "SET_ACCOUNT_DELETED", value: true };
            props.dispatch(action);
          } else {
            firstExec = false;
          }
          resolve(false);
        }
      });
    })
  );
}

export function createDB(email, name, code, props) {
  let newDB = {};
  newDB[code] = {};
  newDB[code]["exist"] = true;
  newDB[code][dataTableName] = {};
  newDB[code][setingsTableName] = {
    acceptedCG: false,
    lastConnection: new Date().getTime(),
    icone: false,
    modeleContact: {
      Adresse: {
        label: "Adresse",
        order: 16,
        type: "Texte",
        value: "",
        visible: true,
      },
      "Code postal": {
        label: "Code postal",
        order: 18,
        type: "Texte",
        value: "",
        visible: true,
      },
      "Date de relance": {
        label: "Date de relance",
        order: -3,
        type: "Date",
        value: "",
        visible: false,
      },
      "Date de création": {
        label: "Date de création",
        order: 13,
        type: "Date",
        value: "",
        visible: true,
        disabled: true,
      },
      Email: {
        label: "Email",
        order: -5,
        type: "Texte",
        value: "",
        visible: false,
      },
      Fonction: {
        label: "Fonction",
        order: -10,
        type: "Liste",
        value: "",
        visible: false,
      },
      Nom: {
        label: "Nom",
        order: -11,
        type: "Texte",
        value: "",
        visible: false,
      },
      Observations: {
        label: "Observations",
        order: -4,
        type: "Texte",
        value: "",
        visible: false,
      },
      Pays: {
        label: "Pays",
        order: 19,
        type: "Texte",
        value: "",
        visible: true,
      },
      Prénom: {
        label: "Prénom",
        order: -12,
        type: "Texte",
        value: "",
        visible: false,
      },
      Responsable: {
        label: "Responsable",
        order: -1,
        type: "Liste",
        value: "",
        visible: false,
      },
      "Saisi par": {
        label: "Saisi par",
        order: 14,
        type: "Texte",
        value: "",
        visible: true,
        disabled: true,
      },
      Secteur: {
        label: "Secteur",
        order: -8,
        type: "Liste",
        value: "",
        visible: false,
      },
      Société: {
        label: "Société",
        order: -9,
        type: "Liste",
        value: "",
        visible: false,
      },
      Statut: {
        label: "Statut",
        order: -2,
        type: "Liste",
        value: "",
        visible: false,
      },
      "Téléphone fixe": {
        label: "Téléphone fixe",
        order: -7,
        type: "Texte",
        value: "",
        visible: false,
      },
      Portable: {
        label: "Portable",
        order: -6,
        type: "Texte",
        value: "",
        visible: false,
      },
      Ville: {
        label: "Ville",
        order: 17,
        type: "Texte",
        value: "",
        visible: true,
      },
    },
  };
  newDB[code][userTableName] = {
    0: {
      deleted: false,
      identifiant: email,
      name: name,
      role: roles.gestionnaire.role,
      color: "green",
      type: "edit",
    },
  };
  newDB[code][setupTableName] = {
    Fonction: {
      0: "Consultant",
      1: "PDG",
      2: "Secrétaire",
      3: "Responsable de la communication",
      4: "Chef de projet",
      5: "Assistant de production",
    },
    Secteur: {
      0: "Média",
      1: "Administration",
      2: "Recherche",
      3: "Finance",
      4: "Logistique",
      5: "Restauration",
    },
    Société: {
      0: "Amazon",
      1: "Google",
      2: "Tesla",
    },
    Statut: {
      0: "Intéressé",
      1: "A contacter",
      2: "En attente",
    },
  };

  return promiseTimeout(
    timeout,
    "createDB",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(bddTableName)
        .update(newDB)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    })
  );
}

export function signOutDB(props) {
  db.ref(
    bddTableName + "/" + props.accountAccessKey + "/" + dataTableName
  ).off();
  db.ref(
    bddTableName + "/" + props.accountAccessKey + "/" + setingsTableName
  ).off();
  db.ref(
    bddTableName + "/" + props.accountAccessKey + "/" + setupTableName
  ).off();
  db.ref(
    bddTableName + "/" + props.accountAccessKey + "/" + userTableName
  ).off();
  db.ref(bddTableName + "/" + props.accountAccessKey).off();
}

export function signIn(props) {
  let promises = [];
  promises.push(loadDatasFromDatabase(props));

  // promises.push(loadDatasFromDatabase(props));
  promises.push(loadSettingsFromDatabase(props));
  promises.push(loadSetupFromDatabase(props));

  return Promise.all(promises);
}

export function getVersion(props) {
  db.ref("DB_INFO/version").off();

  return promiseTimeout(
    timeout,
    "getVersion",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref()
        .child("DB_INFO")
        .child("version")
        .on("value", (versionn) => {
          if (versionn.val() !== version) {
            let action = { type: "SET_VERSION_GUARD", value: false };
            props.dispatch(action);
            resolve(versionn.val());
          } else {
            let action = { type: "SET_VERSION_GUARD", value: true };
            props.dispatch(action);
            resolve(versionn.val());
          }
        });
    })
  );
}

export function loadUserByIdentifiantFromDatabase(identifant, props) {
  return promiseTimeout(
    timeout,
    "loadUserByIdFromDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(
        bddTableName + "/" + props.accountAccessKey + "/" + userTableName
      ).once("value", (snapshot) => {
        let res = snapshot.val();
        if (!res) {
          resolve();
        }
        Object.keys(res).forEach((key) => {
          res[key]["id"] = key;
        });
        let users = Object.values(res);

        resolve(
          users.find((user) => user.identifiant.toLowerCase() === identifant)
        );
      });
    })
  );
}

export function loadUsersFromDatabase(props) {
  return promiseTimeout(
    timeout,
    "loadUsersFromDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(
        bddTableName + "/" + props.accountAccessKey + "/" + userTableName
      ).once("value", (snapshot) => {
        let res = snapshot.val();
        if (!res) {
          resolve();
        }
        Object.keys(res).forEach((key) => {
          res[key]["id"] = key;
        });
        let users = Object.values(res);

        let action = { type: "SET_USERS", value: users };
        props.dispatch(action);
        resolve();
      });
    })
  );
}

export function loadUsersSignInFromDatabase(props) {
  db.ref(
    bddTableName + "/" + props.accountAccessKey + "/" + userTableName
  ).off();

  return promiseTimeout(
    timeout,
    "loadUsersSignInFromDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(
        bddTableName + "/" + props.accountAccessKey + "/" + userTableName
      ).on("value", (snapshot) => {
        if (!snapshot.exists()) {
          reject();
          return;
        }
        let res = snapshot.val();
        if (!res) {
          resolve();
        }
        Object.keys(res).forEach((key) => {
          res[key]["id"] = key;
        });
        let users = Object.values(res);

        let action = { type: "SET_USERS", value: users };
        props.dispatch(action);

        let user = users.find(
          (user) => user.identifiant === props.currentUser.identifiant
        );
        if (user !== undefined) {
          action = { type: "SET_CURRENT_USER", value: user };
          props.dispatch(action);
          resolve(user.deviceId);
        }

        resolve();
      });
    })
  );
}

export function loadSettingsFromDatabase(props) {
  db.ref(
    bddTableName + "/" + props.accountAccessKey + "/" + setingsTableName
  ).off();

  return promiseTimeout(
    timeout,
    "loadSettingsFromDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(
        bddTableName + "/" + props.accountAccessKey + "/" + setingsTableName
      ).on("value", (snapshot) => {
        let res = snapshot.val();
        if (!snapshot.exists()) {
          reject();
          return;
        }

        if (res.modeleContact) {
          Object.keys(res.modeleContact).forEach((key) => {
            res.modeleContact[key]["id"] = key;
          });

          res.modeleContact = Object.values(res.modeleContact);
        } else {
          res.modeleContact = [];
        }

        let action = { type: "SET_ACCOUNT_SETTINGS", value: res };
        props.dispatch(action);
        resolve();
      });
    })
  );
}

export function updateIconeToDatabase(icone, props) {
  return promiseTimeout(
    timeout,
    "updateIconeToDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(
        bddTableName + "/" + props.accountAccessKey + "/" + setingsTableName
      )
        .update({ icone: icone })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    })
  );
}

export function updateSendinblueApiKeyToDatabase(apiKey, props) {
  return promiseTimeout(
    timeout,
    "updateSendinblueApiKeyToDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(
        bddTableName + "/" + props.accountAccessKey + "/" + setingsTableName
      )
        .update({ sendinblueApiKey: apiKey })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    })
  );
}

export function updateLastConnectionToDatabase(props) {
  return promiseTimeout(
    timeout,
    "updateLastConnectionToDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(
        bddTableName + "/" + props.accountAccessKey + "/" + setingsTableName
      )
        .update({ lastConnection: new Date().getTime() })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    })
  );
}

export function loadSetupFromDatabase(props) {
  db.ref(
    bddTableName + "/" + props.accountAccessKey + "/" + setupTableName
  ).off();

  return promiseTimeout(
    timeout,
    "loadSetupFromDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(
        bddTableName + "/" + props.accountAccessKey + "/" + setupTableName
      ).on("value", (snapshot) => {
        if (!snapshot.exists()) {
          reject();
          return;
        }
        let datas = [];
        let obj = {};

        Object.entries(snapshot.val()).forEach((element) => {
          obj = {};
          obj["id"] = element[0];
          obj["list"] = [];
          Object.entries(element[1]).forEach((elem) => {
            if (elem[1] !== "") {
              obj["list"].push({ id: elem[0], label: elem[1] });
            }
          });
          obj["list"].sort(function (a, b) {
            if (
              a["label"]
                .toUpperCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "") >
              b["label"]
                .toUpperCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            ) {
              return 1;
            }

            if (
              a["label"]
                .toUpperCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "") <
              b["label"]
                .toUpperCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            ) {
              return -1;
            }
            return 0;
          });
          datas.push(obj);
        });

        let action = { type: "SET_LISTS", value: datas };
        props.dispatch(action);
        resolve();
      });
    })
  );
}

export function loadDatasFromDatabase(props) {
  /*db.ref(
    bddTableName + "/" + props.accountAccessKey + "/" + dataTableName
  ).off();*/

  return promiseTimeout(
    timeout,
    "loadDatasFromDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(
        bddTableName + "/" + props.accountAccessKey + "/" + dataTableName
      ).once("value", (snapshot) => {
        let datas = [];
        let obj = {};

        if (snapshot && snapshot.val()) {
          Object.entries(snapshot.val()).forEach((element) => {
            obj = element[1];
            obj["id"] = element[0];

            obj["Date de relance"] =
              obj["Date de relance"] !== ""
                ? moment(obj["Date de relance"], "DD/MM/YYYY HH:mm").toDate()
                : "";
            obj["Date de création"] =
              obj["Date de création"] !== ""
                ? moment(obj["Date de création"], "DD/MM/YYYY HH:mm").toDate()
                : "";

            datas.push(obj);
          });
        }
        let action = { type: "SET_DATA", value: datas };
        props.dispatch(action);

        resolve();
      });
    })
  );
}

export function offLoad(props) {
  db.ref(bddTableName + "/" + props.accountAccessKey + "/" + dataTableName).off(
    "child_changed"
  );
  /*db.ref(bddTableName + "/" + props.accountAccessKey + "/" + dataTableName).off(
    "child_added"
  );*/
  /* db.ref(bddTableName + "/" + props.accountAccessKey + "/" + dataTableName).off(
    "child_removed"
  );*/
}

export function loadDatasChangesFromDatabase(props) {
  db.ref(bddTableName + "/" + props.accountAccessKey + "/" + dataTableName).on(
    "child_changed",
    (snapshot) => {
      let datas = [];
      let obj = {};

      if (snapshot && snapshot.val()) {
        obj = snapshot.val();
        obj["id"] = snapshot.key;

        obj["Date de relance"] =
          obj["Date de relance"] !== ""
            ? moment(obj["Date de relance"], "DD/MM/YYYY HH:mm").toDate()
            : "";
        obj["Date de création"] =
          obj["Date de création"] !== ""
            ? moment(obj["Date de création"], "DD/MM/YYYY HH:mm").toDate()
            : "";
      }

      let cpyDatas = JSON.parse(JSON.stringify(props.data));
      var index = cpyDatas.findIndex((data) => data.id === obj["id"]);

      if (index !== -1) {
        cpyDatas[index] = obj;

        let action = { type: "SET_DATA", value: cpyDatas };
        props.dispatch(action);
      }
    }
  );
}

export function loadDatasAddFromDatabase(props) {
  let timeout;
  let dataToAdd = [];
  db.ref(bddTableName + "/" + props.accountAccessKey + "/" + dataTableName).on(
    "child_added",
    (snapshot) => {
      if (timeout) clearTimeout(timeout);

      let obj = {};

      if (snapshot && snapshot.val()) {
        obj = snapshot.val();
        obj["id"] = snapshot.key;

        obj["Date de relance"] =
          obj["Date de relance"] !== ""
            ? moment(obj["Date de relance"], "DD/MM/YYYY HH:mm").toDate()
            : "";
        obj["Date de création"] =
          obj["Date de création"] !== ""
            ? moment(obj["Date de création"], "DD/MM/YYYY HH:mm").toDate()
            : "";

        dataToAdd.push(obj);
      }

      timeout = setTimeout(() => {
        let action = { type: "SET_DATA_TO_ADD", value: dataToAdd };
        props.dispatch(action);
        dataToAdd = [];
      }, 500);
    }
  );
}

export function loadDatasRemoveFromDatabase(props) {
  let timeout;
  let dataToDelete = [];
  db.ref(bddTableName + "/" + props.accountAccessKey + "/" + dataTableName).on(
    "child_removed",
    (snapshot) => {
      if (timeout) clearTimeout(timeout);

      dataToDelete.push(snapshot.key);

      timeout = setTimeout(() => {
        let action = { type: "SET_DATA_TO_DELETE", value: dataToDelete };
        props.dispatch(action);
        dataToDelete = [];
      }, 500);
    }
  );
}

export function createDataToDatabase(data, props) {
  return promiseTimeout(
    timeout,
    "createDataToDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(bddTableName + "/" + props.accountAccessKey + "/" + dataTableName)
        .push(data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    })
  );
}

export function updateDataToDatabase(data, props) {
  let id = data.id;
  delete data.id;

  return promiseTimeout(
    timeout,
    "updateDataToDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(
        bddTableName +
          "/" +
          props.accountAccessKey +
          "/" +
          dataTableName +
          "/" +
          id
      )
        .update(data)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    })
  );
}

export function deleteDataToDatabase(id, props) {
  return promiseTimeout(
    timeout,
    "deleteDataToDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(
        bddTableName +
          "/" +
          props.accountAccessKey +
          "/" +
          dataTableName +
          "/" +
          id
      )
        .remove()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    })
  );
}

export function deleteMultipleDataToDatabase(ids, props) {
  var updates = {};
  ids.forEach((id) => {
    updates[
      bddTableName +
        "/" +
        props.accountAccessKey +
        "/" +
        dataTableName +
        "/" +
        id
    ] = null;
  });

  return promiseTimeout(
    timeout,
    "deleteMultipleDataToDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref()
        .update(updates)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    })
  );
}

export function addChampPersoToModeleContactToDatabase(champ, props) {
  return promiseTimeout(
    timeout,
    "addChampPersoToModeleContactToDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(
        bddTableName +
          "/" +
          props.accountAccessKey +
          "/" +
          setingsTableName +
          "/modeleContact"
      )
        .push(champ)
        .then((e) => {
          resolve(e.key);
        })
        .catch((error) => {
          reject(error);
        });
    })
  );
}

export function removeChampPersoToModeleContactToDatabase(champId, props) {
  return promiseTimeout(
    timeout,
    "removeChampPersoToModeleContactToDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(
        bddTableName +
          "/" +
          props.accountAccessKey +
          "/" +
          setingsTableName +
          "/modeleContact/" +
          champId
      )
        .remove()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    })
  );
}

export function removeChampPersoToAllDataToDatabase(champId, props) {
  var updates = {};
  props.data.forEach((data) => {
    updates[
      bddTableName +
        "/" +
        props.accountAccessKey +
        "/" +
        dataTableName +
        "/" +
        data.id +
        "/" +
        champId
    ] = null;
  });

  return promiseTimeout(
    timeout,
    "removeChampPersoToAllDataToDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref()
        .update(updates)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    })
  );
}

export function updateChampPersoToModeleContactToDatabase(
  key,
  value,
  champId,
  props
) {
  let obj = {};
  obj[key] = value;

  return promiseTimeout(
    timeout,
    "updateChampPersoToModeleContactToDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(
        bddTableName +
          "/" +
          props.accountAccessKey +
          "/" +
          setingsTableName +
          "/modeleContact/" +
          champId
      )
        .update(obj)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    })
  );
}

export function createUserToDatabase(user, props) {
  return promiseTimeout(
    timeout,
    "createUserToDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(bddTableName + "/" + props.accountAccessKey + "/" + userTableName)
        .push(user)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    })
  );
}

export function updateUserToDatabase(user, props) {
  let userU = { ...user };
  let id = userU.id;
  delete userU.id;
  return promiseTimeout(
    timeout,
    "updateUserToDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(
        bddTableName +
          "/" +
          props.accountAccessKey +
          "/" +
          userTableName +
          "/" +
          id
      )
        .update(userU)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    })
  );
}

export function addElementToPreListDatabase(element, champ, props) {
  return promiseTimeout(
    timeout,
    "addElementToPreListDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(
        bddTableName +
          "/" +
          props.accountAccessKey +
          "/" +
          setupTableName +
          "/" +
          champ
      )
        .push(element)
        .then((e) => {
          resolve(e.key);
        })
        .catch((error) => {
          reject(error);
        });
    })
  );
}

export function updateElementToPreListDatabase(id, val, champ, props) {
  let obj = {};
  obj[id] = val;

  return promiseTimeout(
    timeout,
    "updateElementToPreListDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(
        bddTableName +
          "/" +
          props.accountAccessKey +
          "/" +
          setupTableName +
          "/" +
          champ
      )
        .update(obj)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    })
  );
}

export function removeElementToPreListDatabase(elementId, champ, props) {
  return promiseTimeout(
    timeout,
    "removeElementToPreListDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(
        bddTableName +
          "/" +
          props.accountAccessKey +
          "/" +
          setupTableName +
          "/" +
          champ +
          "/" +
          elementId
      )
        .remove()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    })
  );
}

export function deleteMultipleElementPreListToDatabase(ids, champ, props) {
  var updates = {};
  ids.forEach((id) => {
    updates[
      bddTableName +
        "/" +
        props.accountAccessKey +
        "/" +
        setupTableName +
        "/" +
        champ +
        "/" +
        id
    ] = null;
  });

  return promiseTimeout(
    timeout,
    "deleteMultipleElementPreListToDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref()
        .update(updates)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    })
  );
}

export function removePreListDatabase(champ, props) {
  return promiseTimeout(
    timeout,
    "removePreListDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(
        bddTableName +
          "/" +
          props.accountAccessKey +
          "/" +
          setupTableName +
          "/" +
          champ
      )
        .remove()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    })
  );
}

export function deleteAccountDatabase(props) {
  return promiseTimeout(
    timeout,
    "deleteAccountDatabase",
    new Promise(function (resolve, reject) {
      if (!props.isConnectedToNetwork) {
        reject("No network");
      }
      db.ref(bddTableName + "/" + props.accountAccessKey)
        .remove()
        .then(() => {
          if (props.settings.icone) {
            firebase
              .storage()
              .ref(props.accountAccessKey + "/icone")
              .delete()
              .then(() => {
                if (firebase.auth().currentUser) {
                  firebase
                    .auth()
                    .currentUser.delete()
                    .then(() => {
                      resolve();
                    })
                    .catch((error2) => {
                      reject(error2);
                    });
                } else {
                  resolve();
                }
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            if (firebase.auth().currentUser) {
              firebase
                .auth()
                .currentUser.delete()
                .then(() => {
                  resolve();
                })
                .catch((error2) => {
                  reject(error2);
                });
            } else {
              resolve();
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  );
}
