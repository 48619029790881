import React from 'react';
import { View, Animated } from 'react-native';
import { connect } from 'react-redux';
import CustomText from './CustomText';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

class NoNetworkNotification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            animated: new Animated.Value(!this.props.isConnectedToNetwork ? 0 : 255),
            duration: 1000,
            visible: !this.props.isConnectedToNetwork,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isConnectedToNetwork && !this.props.isConnectedToNetwork && !this.state.visible) {
            this.setState({ visible: true })
            Animated.sequence([
                Animated.timing(this.state.animated, {
                    toValue: 255,
                    duration: this.state.duration,
                    useNativeDriver: true,
                }),
                Animated.timing(this.state.animated, {
                    toValue: 0,
                    duration: this.state.duration,
                    useNativeDriver: true,
                }),
            ]).start();
        }
        if (!prevProps.isConnectedToNetwork && this.props.isConnectedToNetwork && this.state.visible) {
            this.setState({ visible: false })
            Animated.sequence([
                Animated.timing(this.state.animated, {
                    toValue: 0,
                    duration: this.state.duration,
                    useNativeDriver: true,
                }),
                Animated.timing(this.state.animated, {
                    toValue: 255,
                    duration: this.state.duration,
                    useNativeDriver: true,
                }),
            ]).start();
        }
    }

    render() {
        return (
            <Animated.View
                style={{
                    position: 'absolute',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#f03a43',
                    paddingVertical: 12,
                    paddingHorizontal: 5,
                    bottom: 0,
                    width: '100%',
                    flexDirection: 'row',
                    transform: [{ translateY: this.state.animated }]
                }}>
                <View style={{ flex: 1 }} />

                <View
                    style={{ flex: 5, alignItems: 'center', justifyContent: 'center' }}
                >
                    <CustomText style={{ color: 'white' }}>{'Le réseau est indisponible'}</CustomText>
                </View>
                <View style={{ flex: 1, alignItems: 'flex-end' }}>
                    <IconButton
                        color='secondary'
                        title="Fermer"
                        onClick={() => {
                            if (this.state.visible) {
                                this.setState({ visible: false })
                                Animated.sequence([
                                    Animated.timing(this.state.animated, {
                                        toValue: 0,
                                        duration: this.state.duration,
                                        useNativeDriver: true,
                                    }),
                                    Animated.timing(this.state.animated, {
                                        toValue: 255,
                                        duration: this.state.duration,
                                        useNativeDriver: true,
                                    }),
                                ]).start();
                            }
                        }}
                        size='medium'
                    >
                        <CloseIcon
                            fontSize={'medium'}
                        />
                    </IconButton>
                </View>
            </Animated.View>


        );
    }
};


const mapStateToProps = state => {
    return {
        isConnectedToNetwork: state.setConnection.isConnectedToNetwork,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch: action => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoNetworkNotification);
