import React from 'react';
import { View } from 'react-native';
import CustomText from '../../CustomComponents/CustomText';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, TextField } from '@mui/material';
import CustomTitle from '../../CustomComponents/CustomTitle';
import { iconSize, labelFontSize, textFontSize, titleFontSize } from '../../../styles/styles';
import { MdSearch } from "react-icons/md";
import CloseIcon from '@mui/icons-material/Close';
import { getColor, isTablet } from '../../../utilities/utils';
import { connect } from 'react-redux';

class Header extends React.Component {

  _displayBackArrow() {
    if (this.props.navigation) {
      return (
        <IconButton
          color='secondary'
          title="Retour"
          onClick={() => this.props.navigation.goBack()}
          size="large"
        >
          <ArrowBackIcon fontSize='medium' />
        </IconButton>
      )
    }
  }

  render() {
    return <div className='noselect'>
      <AppBar
        id={this.props.headerId}
        elevation={2}
        style={{
          paddingTop: 5,
          backgroundColor: getColor('color3', this.props),
        }}
        position="static"
      >
        <Toolbar
          disableGutters
          sx={{ pl: 1, pr: 1 }}
        >
          <Box sx={{ width: '100%', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>

            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
              {this.props.iconLeft ?
                <img
                  alt='logo'
                  style={{ objectFit: 'contain', maxWidth: 75 }}
                  height={50}
                  src={this.props.iconLeft} /> : <></>}
              {this._displayBackArrow()}
            </Box>
            <Box id={this.props.titleId} sx={{ flexGrow: 1, overflow: 'hidden', textOverflow: "ellipsis", whiteSpace: 'nowrap', color: "white" }}>

              <CustomTitle
                numberOfLines={1}
                style={{ color: 'white', textAlign: 'center', fontSize: titleFontSize - 1 }}
              >
                {this.props.children}
              </CustomTitle>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
              {this.props.icon}
            </Box>
          </Box>
        </Toolbar>

        {this.props.searchOnClick ?
          <View style={{ backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', borderRadius: 30, height: 40, marginLeft: isTablet() ? '20%' : '10%', marginRight: isTablet() ? '20%' : '10%', marginBottom: '5%' }}>
            <View style={{ flex: 1, alignItems: 'center' }}>
              <MdSearch style={{ fontSize: iconSize, color: 'grey' }} />
            </View>
            <View style={{ flex: 4 }}>

              <TextField
                variant="standard"
                InputLabelProps={{ style: { color: 'grey', fontFamily: 'Quicksand-Bold', fontSize: labelFontSize } }}
                inputProps={{ enterKeyHint: 'search', style: { color: 'black', fontFamily: 'Quicksand-Regular', fontSize: textFontSize } }}
                InputProps={{
                  disableUnderline: true,
                }}
                placeholder='Recherche...'
                onChange={event => this.props.searchOnChangeText(event.target.value)}
                value={this.props.searchValue}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    document.activeElement.blur();
                  }
                }}
              />

            </View>
            <View style={{ flex: 1, alignItems: 'center' }}>
              <IconButton
                style={{ color: 'grey' }}
                title="Effacer"
                onClick={this.props.searchOnClick}
                size='medium'
              >
                <CloseIcon
                  fontSize={'medium'}
                />
              </IconButton>
            </View>
          </View> : <></>}
      </AppBar >
    </div>;
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.setData['currentUser'],
  };
};

export default connect(mapStateToProps)(Header);