import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Checkbox, IconButton, Paper } from '@mui/material';
import CustomText from '../../CustomComponents/CustomText';
import EditIcon from '@mui/icons-material/Edit';

class GestionListeItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    _checkItem = () => {
        this.props.checkItem()
        this.forceUpdate()
    }

    render() {
        return (
            <View style={{ paddingHorizontal: 15, paddingVertical: 3, height: 60 }}>
                <Paper elevation={3} style={{ borderRadius: 10, width: '100%', height: '100%', }}>
                    <TouchableOpacity
                        style={{ flex: 1, width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', paddingHorizontal: 12, paddingVertical: 12, flexDirection: 'row', paddingLeft: this.props.deleteMode ? 0 : 12 }}
                        onPress={this.props.deleteMode ? () => this._checkItem() : () => { }}
                    >
                        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', }}>
                            {this.props.deleteMode ?
                                <Checkbox
                                    checked={this.props.isChecked ? this.props.isChecked() : false}
                                /> : <></>}
                            <View style={{ flex: 4 }}>
                                <CustomText textType='bold' numberOfLines={2}>{this.props.listItem.label}</CustomText>
                            </View>
                        </View>
                        <View style={{ alignItems: 'flex-end', paddingLeft: 5 }}>
                            <IconButton
                                color='primary'
                                title="Modifier"
                                onClick={(event) => {
                                    this.props.openUpdateDialog(this.props.listItem)
                                    event.stopPropagation()
                                }}
                                size='large'
                            >
                                <EditIcon
                                    fontSize={'medium'}
                                />
                            </IconButton>

                        </View>
                    </TouchableOpacity>
                </Paper>
            </View>
        );
    }
}

export default GestionListeItem;
