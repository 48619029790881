import React from 'react';
import { View, ScrollView } from 'react-native';
import { globalStyles } from '../../../styles/styles';
import { handleBack } from '../../../utilities/utils';
import CustomText from '../../CustomComponents/CustomText';
import CustomTitle from '../../CustomComponents/CustomTitle';
import Header from '../Headers/Header';
import { styles } from './styles';

class PolitiqueConfidentialite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.unsubscribe = this.props.navigation.addListener('blur', () => {
            window.removeEventListener('popstate', this.listener)

        })

        this.unsubscribe2 = this.props.navigation.addListener('focus', () => {
            setTimeout(() => {
                window.addEventListener('popstate', this.listener = (event) => handleBack(event, this));

            }, 1)

        })
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.listener)

        if (this.unsubscribe)
            this.unsubscribe()
        if (this.unsubscribe2)
            this.unsubscribe2()
    }


    render() {
        return (
            <View style={globalStyles.main_container}>
                <Header navigation={this.props.navigation}>
                    Politique de confidentialité
                </Header>
                <ScrollView
                    contentContainerStyle={{
                        flexGrow: 1
                    }}
                >
                    <View style={{ paddingHorizontal: '5%' }}>
                        <View style={styles.cgu_paragraphe}>
                            <CustomTitle style={styles.cgu_title} >
                                1. A propos
                            </CustomTitle>
                            <CustomText style={styles.cgu_text} >
                                {`La présente politique de confidentialité explique quelles données nous collectons depuis l'application Tankō et les raisons de cette collecte, comment nous utilisons et traitons ces informations, à quels tiers nous pourrions éventuellement les divulguer et vos droits sur ces données collectées.

Cette politique de confidentialité s'applique à l'application web Tankō mise à disposition par PLANETE VERTE CONSULTING à l'adresse https://app.tanko.app.`}
                            </CustomText>


                        </View>

                        <View style={styles.cgu_paragraphe}>
                            <CustomTitle style={styles.cgu_title} >
                                2. Vos droits
                            </CustomTitle>
                            <CustomText style={styles.cgu_text} >
                                {`a. Description de vos droits : 
    Les lois relatives à la protection de la vie privée, y compris le Règlement Général sur la Protection des Données (RGPD), confèrent des droits aux individus sur leurs données personnelles :
        - droit d'accès : droit d'être informé(e) des données personnelles que nous traitons vous concernant et de demander l'accès à ces dernières.
        - droit de rectification : droit de nous demander la modification ou la mise à jour de vos données personnelles lorsqu'elles sont inexactes ou incomplètes.
        - droit de suppression : droit de nous demander de supprimer certaines de vos données personnelles.
        - droit de restriction : droit de nous demander l'arrêt temporaire ou définitif du traitement de tout ou partie de vos données personnelles.
        - droit d'opposition : droit de vous opposer au traitement de vos données personnelles à tout moment, pour des raisons liées à votre situation particulière, droit de vous opposer au traitement de vos données personnelles à des fins de marketing direct.
        - droit à la portabilité de vos données : droit de demander une copie de vos données personnelles dans un format lisible par une machine et de transmettre ces données personnelles pour une utilisation dans un service tiers.
        - droit de ne pas faire l'objet d'une prise de décision automatisée : droit de ne pas être soumis(e) à une décision basée uniquement sur la prise de décision automatisée (sans intervention humaine), y compris le profilage, dans le cas où la décision aurait un effet juridique sur vous ou produirait un effet significatif similaire.


b. Exercer vos droits :
    - droit d'accès : pour demander une copie de vos données personnelles, merci d'envoyer un message à l'adresse e-mail contact@tanko.app.
    - droit de rectification : pour modifier les données personnelles non modifiables sur l'application, merci d'envoyer un message à l'adresse e-mail contact@tanko.app.
    - droit de suppression : pour supprimer l'entièreté de vos données, merci d'envoyer un message à l'adresse e-mail contact@tanko.app.
    - droit de restriction : pour exercer ce droit, merci d'envoyer un message à l'adresse e-mail contact@tanko.app.
    - droit d'opposition : pour exercer ce droit, merci d'envoyer un message à l'adresse e-mail contact@tanko.app.
    - droit à la portabilité de vos données : pour exercer ce droit, merci d'envoyer un message à l'adresse e-mail contact@tanko.app.
    - droit de ne pas faire l'objet d'une prise de décision automatisée : pour exercer ce droit, merci d'envoyer un message à l'adresse e-mail contact@tanko.app.

		Vous avez également le droit de contacter votre autorité locale de protection des données en cas de question ou de préoccupation.`}
                            </CustomText>

                        </View>

                        <View style={styles.cgu_paragraphe}>
                            <CustomTitle style={styles.cgu_title} >
                                3. Données personnelles que nous recueillons
                            </CustomTitle>
                            <CustomText style={styles.cgu_text} >
                                {`Cette section présente les 2 groupes de données personnelles que nous sommes susceptibles de collecter et utiliser :
    - données utilisateur
    - données d'utilisation

a. Données personnelles collectées lorsque vous vous connectez à l'application Tankō. Les données collectées sont celles que vous avez fournies lors de votre inscription sur le site https://www.tanko.app/.
    i) Données utilisateur : il s'agit des données personnelles dont nous avons besoin pour vous permettre d'utiliser l'application :
            - adresse e-mail
            - nom et prénom


b. Données personnelles collectées lorsque vous utilisez l'application Tankō.
    i) Données d'utilisation : il s'agit des données personnelles collectées lorsque vous accédez / utilisez l'application Tankō.
        - Données que vous enregistrez dans l'application :
            > couleur du thème utilisé
            > logo importé
            > contacts créés
            > champs personnalisés
            > utilisateurs créés ou supprimés
            > clé API Brevo (ex Sendinblue)

        - Données techniques provenant de l'appareil que vous utilisez :
            > identifiants en ligne tel que les données de cookies (Voir Section 4)
            > type de connexion réseau (par exemple, Wi-Fi, 4G, LTE, Bluetooth)
            > type de navigateur
            > version du navigateur
            > adresse IP
            > nombre de visite de façon anonyme
            > date de votre dernière connexion
            > système d'exploitation
            > version de l'application Tankō`}
                            </CustomText>

                        </View>

                        <View style={styles.cgu_paragraphe}>

                            <CustomTitle style={styles.cgu_title} >
                                4. Politique de Tankō en matière de cookies
                            </CustomTitle>
                            <CustomText style={styles.cgu_text} >
                                {`a. Qu'est-ce qu'un cookie ?
    Les cookies sont des fichiers qui sont déposés sur votre appareil, par exemple, lorsque vous visitez un site web. Ils sont utiles car ils permettent à Tankō de reconnaître l'appareil d'un utilisateur et de favoriser la continuité de votre expérience. Vous pouvez trouver plus d'informations sur les cookies à l'adresse suivante : www.allaboutcookies.org ou www.youronlinechoices.eu.


b. Comment utilisons-nous les cookies ?
    Les cookies remplissent de nombreuses fonctions différentes, comme vous permettre de naviguer efficacement entre les pages, mémoriser vos préférences et améliorer votre expérience d'utilisateur.
    Il existe plusieurs catégories de cookies, notamment :

    i) Cookies strictement nécessaires
        Ces cookies sont déposés par Tankō, et sont essentiels pour vous permettre d'utiliser les fonctionnalités de base de l'application Tankō, telles que fournir techniquement un contenu, définir vos préférences en matière de confidentialité, vous connecter ou remplir des formulaires. Sans ces cookies, les services que vous avez demandés ne peuvent être fournis.

    ii) Cookies facultatifs
        Les cookies facultatifs ne sont pas essentiels au fonctionnement de l'application Tankō mais ils permettent une meilleure expérience d'utilisation.
        Pour le moment, aucun cookies facultatifs ne sont utilisés par l'application Tankō.

c. Options de gestion des cookies
    - Paramètres du navigateur :
        Vous pouvez utiliser les paramètres de votre navigateur web pour accepter, refuser et supprimer les cookies. Vous pouvez à tout moment retirer ou modifier votre consentement à notre utilisation de cookies, à l'exception des cookies strictement nécessaires (tel que décrit ci-dessus). Pour ce faire, suivez les instructions fournies par votre navigateur (généralement situées dans les paramètres "Aide", "Outils" ou "Modifier").
    - Paramètre de l'application Tankō :
        Vous pouvez choisir de refuser les cookies facultatifs décrits ci-dessus dans l'écran "Paramètres" de l'application Tankō.`}

                            </CustomText>
                        </View>

                        <View style={styles.cgu_paragraphe}>
                            <CustomTitle style={styles.cgu_title} >
                                5. Utilisation de vos données personnelles
                            </CustomTitle>
                            <CustomText style={styles.cgu_text} >
                                {`La présente section explique à quelle fin nous traitons vos données personnelles, nos justifications juridiques et les groupes de données personnelles que nous utilisons pour chaque finalité (les groupes de données sont décrits dans la Section 3 "Données personnelles que nous recueillons").

Pour fournir le service Tankō et vous permettre d'utiliser les fonctionnalités de l'application
    - données utilisateur

Pour respecter les obligations légales et les demandes d'application de la loi (Fondement juridique qui autorise la finalité : l'intérêt légitime et le respect des obligations légales)
    - données utilisateur

Pour comprendre, diagnostiquer, dépanner et résoudre les problèmes (Fondement juridique qui autorise la finalité : l'intérêt légitime)
    - données d'utilisation`}
                            </CustomText>

                        </View>

                        <View style={styles.cgu_paragraphe}>

                            <CustomTitle style={styles.cgu_title} >
                                6. Communication de vos données personnelles
                            </CustomTitle>
                            <CustomText style={styles.cgu_text} >
                                {`Nous ne communiquons en aucun cas vos données personnelles. Cependant, pour la gestion de la base de données, Tankō fait appel à la société ARN Software représentée par M. ARNOULT Christopher. Ce faisant, ARN Software a accès à vos données personnelles tout en respectant la présente Politique de confidentialité.`}
                            </CustomText>

                        </View>

                        <View style={styles.cgu_paragraphe}>

                            <CustomTitle style={styles.cgu_title} >
                                7. Conservation et suppression de vos données
                            </CustomTitle>
                            <CustomText style={styles.cgu_text} >
                                {`a. Conservation
	Nous ne conservons vos données personnelles que pendant toute la durée nécessaire pour vous fournir les services proposés par l'application Tankō et pour respecter nos obligations légales.

b. Suppression
	Si vous supprimez ou si vous demandez la suppression de votre compte, nous supprimerons ou anonymiserons vos données personnelles pour qu'elles ne vous identifient plus.`}
                            </CustomText>

                        </View>

                        <View style={styles.cgu_paragraphe}>
                            <CustomTitle style={styles.cgu_title} >
                                8. Protection de vos données personnelles
                            </CustomTitle>
                            <CustomText style={styles.cgu_text}>
                                {`Nous nous engageons à protéger les données personnelles de nos utilisateurs. Nous mettons en œuvre des mesures techniques et organisationnelles appropriées pour protéger la sécurité de vos données personnelles. Il faut toutefois garder à l'esprit qu'aucun système n'est complètement sécurisé.

Pour protéger votre compte utilisateur, nous vous invitons à :
    - utiliser un mot de passe fort unique à votre compte Tankō
    - ne jamais partager votre mot de passe
    - limiter l'accès à votre appareil
    - vous déconnecter à la fin de votre session d'utilisation de l'application Tankō sur un appareil partagé`}
                            </CustomText>
                        </View>

                        <View style={styles.cgu_paragraphe}>

                            <CustomTitle style={styles.cgu_title} >
                                9. Modifications de la présente Politique
                            </CustomTitle>
                            <CustomText style={styles.cgu_text} >
                                {`Nous pouvons à l'occasion apporter des modifications à la présente politique.

Si nous apportons des modifications substantielles à la présente politique, nous vous transmettrons un avis bien en vue selon ce qui convient à la situation, en vous envoyant un e-mail.`}
                            </CustomText>

                        </View>
                        <View style={styles.cgu_paragraphe}>

                            <CustomTitle style={styles.cgu_title} >
                                10. Comment nous joindre
                            </CustomTitle>
                            <CustomText style={styles.cgu_text} >
                                {`Si vous avez des questions, des remarques sur nos pratiques en matière de confidentialité, sur l’application ou si vous avez besoin d’aide, veuillez communiquer avec nous par courriel à l’adresse contact@tanko.app.`}
                            </CustomText>

                        </View>
                    </View>


                </ScrollView>
            </View>
        );
    }
}

export default PolitiqueConfidentialite;
