import React from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import { globalStyles, textFontSize } from "../../../styles/styles";
import ContactList from "./ContactList";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  abonnements,
  displayLoading,
  getAllContactsNotDeleted,
  getAllResponsables,
  getAllValueFromChamp,
  getCurrentToken,
  getElementPreListLabelById,
  getList,
  getNbContactsByAbonnement,
  getNbUsersByAbonnement,
  getSendinblueTemplatesId,
  sendMail,
  sendMailWithSendinblue,
  sendMessage,
  setSnackbar,
  signOut,
  superSignOut,
  userIsGestionnaire,
  validateEmail,
} from "../../../utilities/utils";
import LogoutIcon from "@mui/icons-material/Logout";
import DialogAlert from "../../CustomComponents/DialogAlert";
import ExportToExcel from "../Gestionnaire/ExportToExcel";
import CustomSelect from "../../CustomComponents/CustomSelect";
import CustomText from "../../CustomComponents/CustomText";
import { isAndroid, isBrowser, isIOS } from "react-device-detect";
import { DEBUG } from "../../../api/constants";
import {
  deleteAccountDatabase,
  loadDatasAddFromDatabase,
  loadDatasChangesFromDatabase,
  loadDatasRemoveFromDatabase,
  loadUsersSignInFromDatabase,
  offLoad,
  signIn,
} from "../../../db/db";
import { userGetAbonnement } from "../../../api/helpers";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoading2: false,
      deleteMode: false,
      tooManyContactsMode: false,
      isDialogVisible: false,
      canCloseDialogVisible: true,
      isDialogVisible2: false,
      canCloseDialogVisible2: true,
      isDialogVisible3: false,
      canCloseDialogVisible3: true,
      isDialogVisible4: false,
      canCloseDialogVisible4: true,
      isDialogVisible5: false,
      canCloseDialogVisible5: true,
      isDialogVisible6: false,
      canCloseDialogVisible6: true,
      isDialogVisible7: false,
      canCloseDialogVisible7: true,
      isDialogVisible8: false,
      canCloseDialogVisible8: true,
      exportToExcel: false,
      templateId: "",
      dests: [],
      champListByChamp: false,
      firstExec: true,
    };
  }

  async componentDidMount() {
    //this.props.navigation.navigate("Personnalisation")

    if (this.props.currentUser.identifiant !== undefined) {
      userGetAbonnement(this.props, await getCurrentToken());
      this.getAbonnementInterval = setInterval(async () => {
        userGetAbonnement(this.props, await getCurrentToken());
      }, 60000);
    }

    const champListByChamp = this.props.route?.params?.champId !== undefined;
    if (champListByChamp) {
      this._loadData();
    } else {
      signIn(this.props)
        .then(() => {
          loadDatasRemoveFromDatabase(this.props);
          loadDatasAddFromDatabase(this.props);

          this._refreshDatasListeners();

          this.setState({
            tooManyContactsMode:
              this.props.route?.params?.tooManyContacts !== undefined,
            isLoading: false,
          });
        })
        .catch((err) => {
          if (DEBUG) console.warn(err);
          setSnackbar(
            "error",
            err === "No network"
              ? "Erreur réseau"
              : "Une erreur est survenue. Veuillez réessayer plus tard",
            this.props
          );
          if (err !== "No network") signOut(this.props);
        });
    }
  }

  _refreshDatasListeners = () => {
    offLoad(this.props);
    loadDatasChangesFromDatabase(this.props);
  };

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.currentUser.identifiant !== undefined &&
      this.props.currentUser.identifiant === undefined
    ) {
      if (this.getAbonnementInterval) {
        clearInterval(this.getAbonnementInterval);
      }
    }

    if (
      prevProps.currentUser.identifiant === undefined &&
      this.props.currentUser.identifiant !== undefined
    ) {
      userGetAbonnement(this.props, await getCurrentToken());
      this.getAbonnementInterval = setInterval(async () => {
        userGetAbonnement(this.props, await getCurrentToken());
      }, 60000);
    }

    if (prevState.isLoading && this.state.isLoading === false) {
      setTimeout(() => {
        if (
          this.props?.route?.params?.tooManyContacts === undefined &&
          this.props.route?.params?.champId === undefined
        ) {
          if (this.props.deleted) {
            superSignOut(this.props);
            deleteAccountDatabase(this.props)
              .then(() => {})
              .catch((error) => {
                if (DEBUG) console.warn(error);
              });
            return;
          }

          loadUsersSignInFromDatabase(this.props)
            .then((deviceId) => {
              if (deviceId !== this.props.currentUser.deviceId) {
                signOut(this.props, "userUsed");
              }
              if (!this.props.isVersionValid) {
                superSignOut(this.props);
              }
              return;
            })
            .catch((err) => {
              if (DEBUG) console.warn(err);
            });

          if (
            userIsGestionnaire(this.props.currentUser) &&
            this.props.abonnementEotTime < 10 &&
            this.props.abonnementEotTime > 0
          ) {
            this.setState({ isDialogVisible3: true });
          }

          if (
            this.props.users.filter((us) => us.deleted === false).length >
            getNbUsersByAbonnement(this.props.abonnement)
          ) {
            if (userIsGestionnaire(this.props.currentUser)) {
              this.props.navigation.navigate("TooManyUsers", {
                tooManyUsers: true,
              });
            } else {
              this.setState({ isDialogVisible8: true });
            }
          } else if (
            this.props.abonnement ===
              abonnements.find((abo) => abo.label === "subscriber").label &&
            this.props.data.length >
              getNbContactsByAbonnement(this.props.abonnement)
          ) {
            this.props.navigation.navigate("TooManyContacts", {
              tooManyContacts: true,
            });
          }
        }
      }, 2000);
    }

    if (
      this.props?.route?.params?.tooManyContacts === undefined &&
      this.props.route?.params?.champId === undefined
    ) {
      if (!prevProps.deleted && this.props.deleted) {
        superSignOut(this.props);
        deleteAccountDatabase(this.props)
          .then(() => {})
          .catch((error) => {
            if (DEBUG) console.warn(error);
          });
        return;
      }

      if (prevProps.abonnement !== this.props.abonnement) {
        const nbUsers = getNbUsersByAbonnement(this.props.abonnement);
        if (
          this.props.users.filter((us) => us.deleted === false).length > nbUsers
        ) {
          if (userIsGestionnaire(this.props.currentUser))
            this.props.navigation.navigate("TooManyUsers", {
              tooManyUsers: true,
            });
          else this.setState({ isDialogVisible4: true });
          return;
        } else if (
          this.props.abonnement ===
            abonnements.find((abo) => abo.label === "subscriber").label &&
          this.props.data.length >
            getNbContactsByAbonnement(this.props.abonnement)
        ) {
          this.props.navigation.navigate("TooManyContacts", {
            tooManyContacts: true,
          });
        }
      }

      if (prevProps.isVersionValid && !this.props.isVersionValid) {
        superSignOut(this.props);
        return;
      }

      if (!prevProps.currentUser.deleted && this.props.currentUser.deleted) {
        signOut(this.props, "deleted");
        return;
      }

      if (prevProps.currentUser.deviceId !== this.props.currentUser.deviceId) {
        signOut(this.props, "userUsed");
        return;
      }
    }

    if (
      prevProps.data !== this.props.data ||
      prevProps.lists !== this.props.lists
    ) {
      this._refreshDatasListeners();
      if (this.state.champListByChamp) {
        this._loadData();
      } else {
        this.setState({ refresh: true });
      }
    }

    if (prevProps.dataToDelete !== this.props.dataToDelete) {
      if (this.props.dataToDelete.length === 0) {
        return;
      }
      let cpyDatas = JSON.parse(JSON.stringify(this.props.data));

      this.props.dataToDelete.forEach((dataToDel) => {
        var index = cpyDatas.findIndex((data) => data.id === dataToDel);
        if (index > -1) {
          // only splice array when item is found
          cpyDatas.splice(index, 1); // 2nd parameter means remove one item only
        }
      });

      let action = { type: "SET_DATA", value: cpyDatas };
      this.props.dispatch(action);
      action = { type: "SET_DATA_TO_DELETE", value: [] };
      this.props.dispatch(action);
    }

    if (prevProps.dataToAdd !== this.props.dataToAdd) {
      if (this.state.firstExec) {
        let action = { type: "SET_DATA_TO_ADD", value: [] };
        this.props.dispatch(action);
        this.setState({ firstExec: false });
        return;
      }
      if (this.props.dataToAdd.length === 0) {
        return;
      }
      let cpyDatas = JSON.parse(JSON.stringify(this.props.data));

      cpyDatas = cpyDatas.concat(this.props.dataToAdd);

      let action = { type: "SET_DATA", value: cpyDatas };
      this.props.dispatch(action);
      action = { type: "SET_DATA_TO_ADD", value: [] };
      this.props.dispatch(action);
    }
  }

  componentWillUnmount() {
    if (this.getAbonnementInterval) {
      clearInterval(this.getAbonnementInterval);
    }

    window.removeEventListener("popstate", this.listener);

    if (this.unsubscribe) this.unsubscribe();
    if (this.unsubscribe2) this.unsubscribe2();
  }

  _loadData() {
    this.setState({ isLoading2: true });

    let options =
      getList(this.props.route.params.champId, this.props, true) !== undefined
        ? getList(this.props.route.params.champId, this.props)
        : getAllValueFromChamp(
            this.props.route.params.champId,
            this.props.data
          );
    let champs = Object.values(
      JSON.parse(JSON.stringify(this.props.settings.modeleContact))
    );
    let champLabel = champs.find(
      (ch) => ch.id === this.props.route.params.champId
    ).label;
    const resp =
      this.props.route.params.champId === "Responsable"
        ? getAllResponsables(this.props.users).find(
            (resp) => resp.id === this.props.route.params.champValue
          )
        : {};
    let headerTitle =
      this.props.route.params.champId === "Responsable" && resp !== undefined
        ? resp.label
        : getElementPreListLabelById(
            options,
            this.props.route.params.champValue
          ) !== ""
        ? getElementPreListLabelById(
            options,
            this.props.route.params.champValue
          )
        : this.props.route.params.champValue;

    const list = getAllContactsNotDeleted(
      this.props.route.params.contactList,
      this.props.data
    );

    this.setState({
      champListByChamp: true,
      headerTitle: champLabel + " : " + headerTitle,
      options: options,
      list: list,
      isLoading: false,
      isLoading2: false,
    });
  }

  closeDialog = () => {
    this.setState({ isDialogVisible: false });
  };

  closeDialog2 = () => {
    this.setState({ isDialogVisible2: false });
  };

  closeDialog3 = () => {
    this.setState({ isDialogVisible3: false });
  };

  closeDialog4 = () => {
    this.setState({ isDialogVisible4: false });
  };

  closeDialog5 = () => {
    this.setState({ isDialogVisible5: false });
  };

  closeDialog6 = () => {
    this.setState({ isDialogVisible6: false });
  };

  closeDialog7 = () => {
    this.setState({ isDialogVisible7: false });
  };

  closeDialog8 = () => {
    this.setState({ isDialogVisible8: false });
  };

  _sendGroupedMail = (contactss, service, category) => {
    if (this.state.isLoading2) {
      return;
    }
    this.setState({ isLoading2: true });

    setTimeout(() => {
      let contacts = [];
      if (category) {
        contactss.forEach((cat) => {
          contacts = contacts.concat(cat.contacts);
        });
      } else {
        contacts = contactss;
      }

      let add = contacts
        .map((elem) => elem["Email"])
        .filter((add) => validateEmail(add));
      if (isBrowser && !isAndroid && !isIOS) {
        add = add.slice(0, 50);
      }
      this.setState({ dests: add });

      if (contacts.length === 0 || add.length === 0) {
        setSnackbar(
          "warning",
          add.length === 0
            ? "Aucun contact possédant une adresse valide"
            : "Aucun contact",
          this.props
        );
        this.setState({ isLoading2: false });
        return;
      }

      if (service === "sendinblue") {
        if (this.props.settings.sendinblueApiKey) {
          this.setState({ service: "Sendinblue" });

          getSendinblueTemplatesId(this.props.accountAccessKey, this.props)
            .then((res) => {
              if (res.length === 0) {
                // setSnackbar('warning', "Aucun template Sendinblue trouvé", this.props)
                this.setState({ isDialogVisible6: true });
              } else {
                this.setState({
                  templateIdOptions: res,
                  templateId: "",
                  helperText: "",
                  isDialogVisible2: true,
                });
              }
            })
            .catch((err) => {
              if (DEBUG) console.warn(err);
              setSnackbar(
                "error",
                err === "No network"
                  ? "Erreur réseau"
                  : err === "Key not found"
                  ? "Votre clé API Brevo (ex Sendinblue) semble incorrecte"
                  : "Une erreur est survenue. Veuillez réessayer plus tard",
                this.props
              );
            })
            .finally(() => {
              this.setState({ isLoading2: false });
            });
        } else {
          setSnackbar(
            "warning",
            "Vous n'avez pas configuré votre clé API Brevo (ex Sendinblue). Rendez-vous dans les paramètres !",
            this.props
          );
          this.setState({ isLoading2: false });
        }
      } else {
        this.setState({ isLoading2: false, isDialogVisible4: true });
      }
    }, 100);
  };

  _sendGroupedMailWithCustomMailing() {
    if (this.state.isLoading2) {
      return;
    }
    this.setState({ isLoading2: true });

    sendMail(this.state.dests.join(";"));
    this.setState({ isDialogVisible4: false, isLoading2: false });
  }

  _sendGroupedMailWithService() {
    if (this.state.isLoading2) {
      return;
    }
    this.setState({ isLoading2: true });

    if (this.state.templateId === "") {
      this.setState({ helperText: "Champ obligatoire", isLoading2: false });
      return;
    }

    this.setState({
      isDialogVisible2: false,
      isDialogVisible5: true,
      isLoading2: false,
    });
  }

  _validSendGroupedMailWithService() {
    if (this.state.isLoading2) {
      return;
    }
    this.setState({ isLoading2: true });

    if (this.state.service === "Sendinblue") {
      if (this.props.settings.sendinblueApiKey) {
        this.setState({ isDialogVisible5: false });

        sendMailWithSendinblue(
          this.state.dests,
          this.state.templateId,
          this.props.accountAccessKey,
          this.props
        )
          .then((number) => {
            setSnackbar(
              "success",
              "Commande envoyée avec succès vers Brevo (ex Sendinblue)",
              this.props
            );
          })
          .catch((err) => {
            if (DEBUG) console.warn(err);
            setSnackbar(
              "error",
              err === "No network"
                ? "Erreur réseau"
                : err.includes && err.includes("Key not found")
                ? "Votre clé API Brevo (ex Sendinblue) semble incorrecte"
                : "Une erreur est survenue. Veuillez réessayer plus tard",
              this.props
            );
          })
          .finally(() => {
            this.setState({ isLoading2: false });
          });
      } else {
        setSnackbar(
          "warning",
          "Vous n'avez pas configuré votre clé API Brevo (ex Sendinblue). Rendez-vous dans les paramètres !",
          this.props
        );
        this.setState({ isDialogVisible5: false, isLoading2: false });
      }
    }
  }

  _validSendGroupedSms = () => {
    if (this.state.isLoading2) {
      return;
    }
    this.setState({ isLoading2: true });
    setTimeout(() => {
      sendMessage(this.state.dests.join(";"));
      this.setState({ isLoading2: false, isDialogVisible7: false });
    }, 100);
  };

  _sendGroupedSms = (contactss, category) => {
    if (this.state.isLoading2) {
      return;
    }
    this.setState({ isLoading2: true, isDialogVisible7: false });

    setTimeout(() => {
      let contacts = [];
      if (category) {
        contactss.forEach((cat) => {
          contacts = contacts.concat(cat.contacts);
        });
      } else {
        contacts = contactss;
      }

      let add = contacts
        .map((elem) => elem["Portable"])
        .filter((add) => add && add !== "");
      if (isBrowser && !isAndroid && !isIOS) {
        add = add.slice(0, 50);
      }
      this.setState({ dests: add });

      if (contacts.length === 0 || add.length === 0) {
        setSnackbar(
          "warning",
          add.length === 0
            ? "Aucun contact possédant un numéro de télephone portable valide"
            : "Aucun contact",
          this.props
        );
        this.setState({ isLoading2: false });
        return;
      }

      this.setState({ isLoading2: false, isDialogVisible7: true });
    }, 100);
  };

  _exportContacts = (contactss, category) => {
    let contacts = [];
    if (category) {
      contactss.forEach((cat) => {
        contacts = contacts.concat(cat.contacts);
      });
    } else {
      contacts = contactss;
    }

    this.setState({ selectedContacts: contacts }, () => {
      this.setState({ exportToExcel: true });
    });
  };

  _disableExportContact = () => {
    this.setState({ exportToExcel: false });
  };

  render() {
    return (
      <View style={globalStyles.main_container}>
        <DialogAlert
          isDialogVisible={
            this.state.isDialogVisible === undefined
              ? false
              : this.state.isDialogVisible
          }
          onClose={() => {
            this.closeDialog();
          }}
          title={"Deconnexion"}
          text={"Etes-vous sûr de vouloir vous déconnecter ?"}
          confirm={() => signOut(this.props)}
          cancel={() => {
            this.closeDialog();
          }}
        />

        <DialogAlert
          isDialogVisible={this.state.isDialogVisible3}
          onClose={() => {
            this.closeDialog3();
          }}
          title={"Abonnement bientôt expiré"}
          text={
            "Votre abonnement arrive à échéance dans " +
            this.props.abonnementEotTime +
            " jour(s). Veuillez mettre à jour votre abonnement afin de continuer à utiliser toutes les fonctionnalités de l'application ou contactez votre Administrateur."
          }
          validText="Accéder au site"
          cancelText="Ok"
          confirm={() => {
            window.open("https://www.tanko.app");
            this.setState({ isDialogVisible3: false });
          }}
          cancel={() => {
            this.closeDialog3();
          }}
        />

        <DialogAlert
          isDialogVisible={this.state.isDialogVisible8}
          onClose={() => {}}
          title={"Nombre d'utilisateurs maximum dépassé"}
          text={
            "La limite du nombre d'utilisateur autorisé dans votre abonnement est dépassée. Veuillez mettre à jour votre abonnement afin de continuer à utiliser l'application ou contactez votre Administrateur."
          }
          validText="Accéder au site"
          cancelText={"Se déconnecter"}
          confirm={() => {
            window.open("https://www.tanko.app");
          }}
          cancel={() => {
            this.setState({ isDialogVisible8: false });
            signOut(this.props);
          }}
        />

        <DialogAlert
          isDialogVisible={this.state.isDialogVisible4}
          onClose={() => {
            this.closeDialog4();
          }}
          title={"Mail groupé"}
          content={
            <View>
              {isBrowser && !isAndroid && !isIOS ? (
                <CustomText
                  style={{ fontSize: textFontSize - 3, marginBottom: 12 }}
                >
                  Attention : sur ordinateur, les navigateurs limitent le nombre
                  de destinataires à 50 !
                </CustomText>
              ) : (
                <></>
              )}
              <CustomText style={{ fontSize: textFontSize - 2 }}>
                Voici la liste des {this.state.dests.length} destinataire(s)
                valide(s) qui sera utilisée pour l'envoi de ce mail :{" "}
                {this.state.dests.join(", ")}
              </CustomText>
            </View>
          }
          confirm={() => this._sendGroupedMailWithCustomMailing()}
          cancel={() => {
            this.closeDialog4();
          }}
        />

        <DialogAlert
          isDialogVisible={this.state.isDialogVisible7}
          onClose={() => {
            this.closeDialog7();
          }}
          title={"Sms groupé"}
          content={
            <View>
              {isBrowser && !isAndroid && !isIOS ? (
                <CustomText
                  style={{ fontSize: textFontSize - 3, marginBottom: 12 }}
                >
                  Attention : sur ordinateur, les navigateurs limitent le nombre
                  de destinataires à 50 !
                </CustomText>
              ) : (
                <></>
              )}
              <CustomText style={{ fontSize: textFontSize - 2 }}>
                Voici la liste des {this.state.dests.length} destinataire(s)
                valide(s) qui sera utilisée pour l'envoi de ce sms :{" "}
                {this.state.dests.join(", ")}
              </CustomText>
            </View>
          }
          confirm={() => this._validSendGroupedSms()}
          cancel={() => {
            this.closeDialog7();
          }}
        />

        <DialogAlert
          isDialogVisible={this.state.isDialogVisible5}
          onClose={() => {
            this.closeDialog5();
          }}
          title={"Mail groupé " + this.state.service}
          text={
            "Voici la liste des " +
            this.state.dests.length +
            " destinataire(s) valide(s) qui sera utilisée pour l'envoi de ce mail via " +
            this.state.service +
            " avec votre template '" +
            this.state.templateName +
            "' : " +
            this.state.dests.join(", ")
          }
          confirm={() => this._validSendGroupedMailWithService()}
          cancel={() => {
            this.closeDialog5();
          }}
        />

        <DialogAlert
          isDialogVisible={this.state.isDialogVisible6}
          onClose={() => {
            this.closeDialog6();
          }}
          title={"Aucun template " + this.state.service + " trouvé"}
          text={
            this.state.service === "Sendinblue"
              ? "Vous devez créer au moins un template personnalisé dans votre espace Brevo (ex Sendinblue)."
              : ""
          }
          validText={"Accéder"}
          confirm={() => {
            this.closeDialog6();
            window.open(
              this.state.service === "Sendinblue"
                ? "https://my.brevo.com/camp/lists/template"
                : ""
            );
          }}
          cancel={() => {
            this.closeDialog6();
          }}
        />

        <Dialog
          onBackdropClick={() => this.closeDialog2()}
          open={this.state.isDialogVisible2}
          onClose={() => this.closeDialog2()}
          style={{ fontFamily: "Quicksand-Regular" }}
        >
          <DialogTitle style={{ fontFamily: "Quicksand-Bold" }}>
            Sélectionner un template
          </DialogTitle>
          <DialogContent style={{ fontFamily: "Quicksand-Bold" }}>
            <View style={{ paddingBottom: 12, fontSize: textFontSize - 3 }}>
              <CustomText style={{ fontSize: textFontSize - 3 }}>
                Sélectionner le template {this.state.service} que vous souhaitez
                utiliser.
              </CustomText>
            </View>
            <CustomSelect
              label={"Template"}
              value={this.state.templateId}
              onChange={(value) =>
                this.setState({
                  templateId: value.id,
                  templateName: value.label,
                  templateHtml: value.html,
                })
              }
              options={this.state.templateIdOptions}
              helperText={this.state.helperText}
              isSearchable
            />
          </DialogContent>
          <DialogActions>
            <Button
              style={{ fontFamily: "Quicksand-Bold" }}
              onClick={() => this.closeDialog2()}
              color="primary"
            >
              Annuler
            </Button>
            <Button
              style={{ fontFamily: "Quicksand-Bold" }}
              onClick={() => this._sendGroupedMailWithService()}
              color="primary"
            >
              Valider
            </Button>
          </DialogActions>
        </Dialog>

        {this.state.exportToExcel ? (
          <ExportToExcel
            contacts={this.state.selectedContacts}
            disableExportContact={this._disableExportContact}
          />
        ) : (
          <></>
        )}
        {displayLoading(this.state.isLoading2)}
        {this.state.isLoading ? (
          displayLoading(this.state.isLoading)
        ) : (
          <View style={{ flex: 1, opacity: this.state.isLoading2 ? 0.5 : 1 }}>
            <ContactList
              tooManyContacts={this.props.route?.params?.tooManyContacts}
              closeDeleteMode={() => this.setState({ deleteMode: false })}
              deleteMode={this.state.deleteMode}
              addButton={
                this.state.champListByChamp
                  ? false
                  : !this.state.tooManyContactsMode ||
                    (this.state.tooManyContactsMode &&
                      this.props.data.length <=
                        getNbContactsByAbonnement(this.props.abonnement))
              }
              contacts={
                this.state.champListByChamp ? this.state.list : this.props.data
              }
              navigation={this.props.navigation}
              headerBack={this.state.champListByChamp}
              headerIconLeft={!this.state.champListByChamp}
              headerTitle={
                this.state.champListByChamp
                  ? this.state.headerTitle
                  : "contacts_title"
              }
              exportContacts={this._exportContacts}
              sendGroupedMail={this._sendGroupedMail}
              sendGroupedSms={this._sendGroupedSms}
              headerIcon={
                <div style={{ display: "flex" }}>
                  {!this.state.tooManyContactsMode ? (
                    <IconButton
                      color="secondary"
                      title="Paramètres"
                      onClick={() => this.props.navigation.navigate("Settings")}
                      size="medium"
                    >
                      <SettingsIcon fontSize={"medium"} />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                  {this.props.currentUser.type === "read" ? (
                    <></>
                  ) : (
                    <IconButton
                      color="secondary"
                      title="Supprimer"
                      onClick={() =>
                        this.setState((prevState) => ({
                          deleteMode: !prevState.deleteMode,
                        }))
                      }
                      size="medium"
                    >
                      <DeleteIcon fontSize={"medium"} />
                    </IconButton>
                  )}
                  {this.state.tooManyContactsMode ? (
                    <IconButton
                      color="secondary"
                      title="Déconnexion"
                      onClick={() => {
                        this.setState({ isDialogVisible: true });
                      }}
                      size="medium"
                    >
                      <LogoutIcon fontSize={"medium"} />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </div>
              }
            />
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.setData.data,
    currentUser: state.setData["currentUser"],
    abonnement: state.setAccount.abonnement,
    accountAccessKey: state.setAccount.accountAccessKey,
    settings: state.setAccount.settings,
    isConnectedToNetwork: state.setConnection.isConnectedToNetwork,
    lists: state.setData.lists,
    users: state.setData["users"],
    deleted: state.setAccount.deleted,
    isVersionValid: state.setVersionGuard.isVersionValid,
    abonnementEotTime: state.setAccount.abonnementEotTime,
    id: state.setAccount.id,
    dataToDelete: state.setData.dataToDelete,
    dataToAdd: state.setData.dataToAdd,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
